import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel } from '@mui/material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateStatusTypeForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    course: [],
    applicationForm: '',
    operationsPending: false,
    accountsPending: false,
  });

  useEffect(() => {
    const fetchStatusTypeData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/status-type/get', {
            params: {
              id: id,
            },
          });
          const data = response?.data;
          
          setSelectedCourse(data?.course);
          setFormData(data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchStatusTypeData();
  }, [id]);

  useEffect(() => {
    getCourseData();
  }, []);

  const getCourseData = async () => {
    try {
      const response = await apiService.get('/course/list', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      setCourses(response?.data?.course);
    } catch (error) {
      console.error(error);
    }
  };

  const [errors, setErrors] = useState({
    name: '',
    course: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      course: '',
    };

    if(!formData.name){
      newErrors.name = 'Invalid name';
      isValid = false;
    }

    if(!formData?.course?.length){
      newErrors.course = 'Invalid course';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const apiUrl = id ? `/status-type/update?id=${id}` : '/status-type/create';
      try {
        const response = await apiService.post(apiUrl, { ...formData });
        navigate(-1);
        
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  const handleChangeCourse = (value) => {
    setSelectedCourse(value);
    
    const courseIds = value?.map(course => course?._id);
    
    setFormData((prevData) => ({
      ...prevData,
      course: courseIds,
    }));
  };

  const handlePendingOptionsChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  }

  return (
    <div>
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
        <BackButton />
        Status Type
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Status Type
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
          size='small'
        />
        <Autocomplete
          multiple
          disablePortal
          id="course-box"
          name="courses"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={selectedCourse}
          onChange={(e, value) => handleChangeCourse(value)} 
          renderInput={(params) => <TextField {...params} error={Boolean(errors.course)} helperText={errors.course} size='small' label="Select Course *" />}
          size='small'
        />
        
        <Typography sx={{ marginTop: '20px', fontWeight: 'bold' }} gutterBottom>Required Process</Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} sx={{ marginTop: '6px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6} sx={{ marginTop: '6px' }}>
                <FormControlLabel control={<Checkbox checked={formData?.operationsPending} name='operationsPending' onChange={(e) => handlePendingOptionsChange(e)} />} label="Operations Pending" />
              </Grid>
              <Grid item xs={6} sx={{ marginTop: '6px' }}>
                <FormControlLabel control={<Checkbox checked={formData?.accountsPending} name='accountsPending' onChange={(e) => handlePendingOptionsChange(e)} />} label="Accounts Pending" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ marginTop: '6px' }}></Grid>
        </Grid>

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update' : 'Create'}
        </Button>
      </form>
    </div>
  );
};

export default CreateStatusTypeForm;
