import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Rating,
  Button,
  Box,
  Tab,
  TextField,
  CircularProgress,
  TextareaAutosize as BaseTextareaAutosize,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import studentApiService from '../../../utlis/apiStudent';
import { styled } from '@mui/system';
import Exams from './exams';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};


const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 400px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);


const AcademicTopics = () => {
  const navigate = useNavigate();
  const [academics, setAcademics] = useState([]);
  const [data, setData] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [tabValue, setTabValue] = useState('schedule');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
  }); 
  const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];

  useEffect(() => {
    getAcadamics();
  }, []);

  const getAcadamics = async () => {
    try{
      const userId = localStorage.getItem('user_id');

      const response = await studentApiService.get(`/user/acadamics?id=${userId}`);
      setAcademics(response.data.acadamics);
    } catch (error){
      console.error('Error fetching academic data:', error);
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const handleRatingChange = async (scheduleId, newValue) => {
    const userId = localStorage.getItem('user_id');
    const newAcademics = [...academics];
    const scheduleIndex = newAcademics.findIndex(schedule => schedule.schedule._id === scheduleId);
    const studentIndex = newAcademics[scheduleIndex].schedule?.rating ? newAcademics[scheduleIndex].schedule.rating.findIndex(rating => rating.student === userId) : -1;
    
    if(studentIndex === -1) {
      const rating = {
        student: userId,
        rating: newValue
      }

      if(newAcademics[scheduleIndex].schedule.rating) {
        newAcademics[scheduleIndex].schedule.rating.push(rating);
      }
      else {
        newAcademics[scheduleIndex].schedule.rating = [rating]
      }
    }
    else {
      newAcademics[scheduleIndex].schedule.rating[studentIndex].rating = newValue;
    }

    const ratingResponse = await handleRatingSubmit(scheduleId, newValue);

    if(ratingResponse) {
      setAcademics(newAcademics);
    }
    else {
      alert('Something went wrong. Please try again');
    }
  };

  const handleRatingSubmit = async (scheduleId, rating) => {
    try{
      const data = {
        scheduleId,
        rating,
      }

      const response = await studentApiService.post(`/user/post-rating`, data, {
        headers: {
          'Content-Type': 'application/json',
        } 
      });

      if(response?.data?.status === 'success') {
        return true;
      }
      else {
        return false;
      }
      
    } catch (error){
      console.error('Error fetching academic data:', error);
      return false;
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  useEffect(() => {
    getData();
  }, [searchTerm, paginationModel]);

  const getData = async () => {
    setLoading(true); // Start loading
    try {
        const response = await studentApiService.get('/user/batch', {
            params: { searchTerm, page: paginationModel.page, pageSize: paginationModel.pageSize },
        });

        const { status, batchs, totalResults } = response?.data || {};
        setData(batchs || []);
        setTotalResults(totalResults || 0);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        setLoading(false); // Stop loading, whether success or error
    }
  }

  const enrollToBatch = async (id) => {
    try {

      const response = await studentApiService.post('/user/batch/enroll', { batchId: id }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      alert(error?.response?.data?.message);
      console.error(error);
    }
  }

  const studentEnrolledCheck = (batch) => {
    const studentId = localStorage.getItem('user_id');
    let status = '';
    if(batch.status === 'Open For Enrollment') {
      if(batch.student.includes(studentId))
        status =  'Enrolled';
      else
        return false;
    }
    else {
      status = batch.status;
    }

    return status;
    
  }

  const studentId = localStorage.getItem('user_id');

  const hanldReviewChange = (e, scheduleId) => {
    const { value } = e.target;

    const userId = localStorage.getItem('user_id');
    const newAcademics = [...academics];
    const scheduleIndex = newAcademics.findIndex(schedule => schedule.schedule._id === scheduleId);
    const studentIndex = newAcademics[scheduleIndex].schedule?.rating ? newAcademics[scheduleIndex].schedule.rating.findIndex(rating => rating.student === userId) : -1;
    
    if(studentIndex === -1) {
      const rating = {
        student: userId,
        review: value
      }

      if(newAcademics[scheduleIndex].schedule.rating) {
        newAcademics[scheduleIndex].schedule.rating.push(rating);
      }
      else {
        newAcademics[scheduleIndex].schedule.rating = [rating]
      }
    }
    else {
      newAcademics[scheduleIndex].schedule.rating[studentIndex].review = value;
    }

    setAcademics(newAcademics);
  }

  const hanldReviewSave = async (scheduleId, review) => {
    try{
      const data = {
        scheduleId,
        review,
      }

      const response = await studentApiService.post(`/user/post-review`, data, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if(response?.data?.status === 'success') {
        alert('Your review has been posted. Thank you!');
      }
      else {
        alert('Something went wrong. Please try again.');
      }
      
    } catch (error){
      console.error('Error fetching academic data:', error);
      return false;
    }
  }

  return (
    <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            ACADEMIC
          </Typography>
        </Toolbar>
      </AppBar>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Acadamics tab">
            <Tab label="Schedule" value="schedule" />
            <Tab label="Batchs" value="batchs" />
            <Tab label="Exams" value="exams" />
          </TabList>
        </Box>
        <TabPanel value="schedule">
          <List>
            {academics.map((academic, index) => {
              const student = academic?.schedule?.rating?.find(rating => rating.student === studentId);
              return <div key={index}>
                <ListItem>
                  <ListItemText primaryTypographyProps={{ style: { fontWeight: 'bold' } }} primary={`Schedule : ${academic?.schedule ? academic?.schedule?.batch?.name : ''} - ${academic?.schedule ? formatDate(academic?.schedule?.date) : ''}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`Date: ${formatDate(academic?.date)}`} />
                </ListItem>
                
                {academic?.schedule?.status === 'completed' ? 
                  <>
                    <ListItem>
                      <Rating
                        name={`rating-${academic?.schedule?._id}`}
                        value={student?.rating || 0}
                        precision={0.5}
                        sx={{marginTop:2}}
                        onChange={(event, newValue) => handleRatingChange(academic?.schedule?._id, newValue)}
                      />
                    </ListItem>
                    <ListItem>
                      <TextareaAutosize 
                        label="Review"
                        value={student?.review}
                        onChange={(e) => hanldReviewChange(e, academic?.schedule?._id)} 
                        minRows={2}
                      />
                    </ListItem>
                    {student?.review ? 
                      <ListItem>
                        <Button
                          type="button"
                          variant="contained"
                          sx={{ mb: 2, }}
                          onClick={() => hanldReviewSave(academic?.schedule?._id, student?.review)}
                        >
                          Save
                        </Button>
                      </ListItem>
                    : ''}
                  </>
                : ''}
                <Divider />
              </div>
            })}
          </List>
        </TabPanel>
        <TabPanel value="batchs">
          <TextField
            label="Search"
            variant="outlined"
            margin="normal"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loading ? (
            <div style={{ textAlign: 'center' }}>
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            </div>
          ) : (
            <div style={{ height: 350, width: '100%' }}>
              <DataGridPro
                  rows={data}
                  getRowId={(row) => row._id}
                  columns={[
                      { field: 'name', headerName: 'Name', width: 500 },
                      { field: 'faculty', headerName: 'Faculty', width: 300,valueGetter: (params) => params.row?.faculty?.name },
                      { headerName: 'Remaining Seats', width: 200, valueGetter: (params) => params.row?.limit - params.row?.student?.length },
                      { field: 'startingDate', headerName: 'Starting Date', width: 200,valueGetter: (params) => new Date(params.row?.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                      {
                        field: 'actions',
                        headerName: 'Action',
                        width: 150,
                        renderCell: (params) => (
                            <>
                              {params.row?.limit === params.row?.student?.length ? 'Batch Full' : studentEnrolledCheck(params.row) ? studentEnrolledCheck(params.row) : <Button variant="outlined" onClick={() => enrollToBatch(params.row?._id)}>Enroll To</Button>}
                            </>
                        ),
                    },
                  ]}
                  pagination
                  paginationModel={paginationModel}
                  rowCount={totalResults}
                  paginationMode="server"
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={pageOption}
              />
            </div>
          )}
        </TabPanel>
        <TabPanel value="exams">
          <Exams />
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default AcademicTopics;
