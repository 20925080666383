import { createContext, useContext, useState,useEffect } from 'react';
import apiService from '../utlis/apiService';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);
  const [userId,setUserid] = useState(null)
  const [role,setRole] = useState(null)

  const logout = () => {
    setPermissions(null);
  }

  useEffect(()=>{
    const getUser = async () => {
        try {
            const response = await apiService.get('/admin/me');
            console.log(response?.data?.role?.permissions);
            setPermissions(response?.data?.role?.permissions);
            setUserid(response?.data?._id)
            setRole(response?.data?.role?.name)
        } catch (error) {
          console.error(error);
        }
      };
      localStorage.getItem('token') && getUser()
  },[])

  return (
    <UserContext.Provider value={{ permissions, logout,userId, userRole: role }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
