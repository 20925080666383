import React, { useState } from 'react';
import { Button, Container, Typography, Box, Card, CardContent } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useNavigate } from 'react-router-dom';

const UploadForm = () => {
  const navigate = useNavigate();
  const [aadharFile, setAadharFile] = useState(null);
  const [idCardFile, setIdCardFile] = useState(null);
  const [panCardFile, setPanCardFile] = useState(null);

  const handleAadharChange = (event) => {
    setAadharFile(event.target.files[0]);
  };

  const handleIdCardChange = (event) => {
    setIdCardFile(event.target.files[0]);
  };

  const handlePanCardChange = (event) => {
    setPanCardFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    navigate('/student/home')
  };

  return (
    <div style={{display:'flex',backgroundColor:'#dbf0ef',height:'100vh',justifyContent:'center'}}>
        
    <Container maxWidth="sm" style={{ backgroundColor: '#fff',  borderRadius: '10px' ,marginTop:15,marginBottom:15,paddingLeft:0,paddingRight:0}}>
    <div style={{height:10,backgroundColor:'#008673'}}>

    </div>
      <Typography variant="h4" align="center" gutterBottom style={{ marginTop: 30 }}>
        UPLOAD YOUR DOCUMENTS
      </Typography>

      <Box mt={3} style={{ marginTop: 50,paddingLeft:20,paddingRight:20 }}>
        <div style={{ borderRadius: 5, backgroundColor: 'white'}}>
        <CardContent style={{ border: '1px solid #e0e0e0', borderRadius: 5 }}>
            <Typography variant="h6">AADHAR CARD </Typography>
            <Button variant="outlined" component="label" style={{ marginTop: 10 }}>
                <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
                AADHAR
                <input
                type="file"
                accept="image/*, application/pdf"
                style={{ display: 'none' }}
                onChange={handleAadharChange}
                />
            </Button>
            {aadharFile && (
                <Box mt={2}>
                <Typography variant="body1">{aadharFile.name}</Typography>
                </Box>
            )}
        </CardContent>
        </div>
      </Box>

      <Box mt={3}>
        <div style={{ borderRadius: 10, backgroundColor: 'white',paddingLeft:20,paddingRight:20 }}>
          <CardContent style={{ border: '1px solid #e0e0e0', borderRadius: 5 }}>
            <Typography variant="h6">ID CARD </Typography>
            <Button variant="outlined" component="label" style={{ marginTop: 10 }}>
            <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
              ID CARD
              <input
                type="file"
                accept="image/*, application/pdf"
                style={{ display: 'none' }}
                onChange={handleIdCardChange}
              />
            </Button>
            {idCardFile && (
              <Box mt={2}>
                <Typography variant="body1">{idCardFile.name}</Typography>
              </Box>
            )}
          </CardContent>
        </div>
      </Box>

      <Box mt={3}>
        <div style={{ borderRadius: 10, backgroundColor: 'white',paddingLeft:20,paddingRight:20  }}>
          <CardContent style={{ border: '1px solid #e0e0e0', borderRadius: 5 }}>
            <Typography variant="h6">PAN CARD </Typography>
            <Button variant="outlined" component="label" style={{ marginTop: 10 }}>
            <CloudUploadOutlinedIcon style={{ marginRight: 5 }} />
              PAN CARD
              <input
                type="file"
                accept="image/*, application/pdf"
                style={{ display: 'none' }}
                onChange={handlePanCardChange}
              />
            </Button>
            {panCardFile && (
              <Box mt={2}>
                <Typography variant="body1">{panCardFile.name}</Typography>
              </Box>
            )}
          </CardContent>
        </div>
      </Box>

      <Box mt={3} style={{ marginTop: 50 }} textAlign={'center'}>
        <Button
          variant="contained"
          color="primary"
          sx={{backgroundColor:'#008673',
        //   height:50,
          paddingLeft:10,
          paddingRight:10,
          height:60,
          '&:hover': {
            backgroundColor: '#006a5b', // Change this to your desired hover color
          },
        }}
          onClick={handleSubmit}
          disabled={!aadharFile || !idCardFile || !panCardFile}
        >
          Upload
        </Button>
      </Box>
    </Container>
    </div>
  );
};

export default UploadForm;
