import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Tab, TextField, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const RegistrationTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [selectdApprovalId, setSelectdApprovalId] = useState('');
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const [filterData, setFilterData] = useState({
        dateFrom: null,
        dateTo: null,
    });
    const [registrationTabValue, setRegistrationTabValue] = useState('pendingApplication');

    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel, filterData]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get(`/student/registration/list`, {
                params: {
                    ...filterData,
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.students);
            setTotalResults(response?.data?.totalResults);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleApprovalSubmit = async () => {
        try {
            const data = {
                studentId : selectdApprovalId,
            }
            
            const response = await apiService.post(`/student/registration/submit` , data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response?.data?.status === 'success'){
                setApproveModal(false);
                alert('Approval submited!');
                getData();
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleFilterChange = (name, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>REGISTRATIONS</Typography>
            </div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        margin="normal"
                        size='small'
                        style={{ marginBottom: 10 }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                label="Date From"
                                value={filterData.dateFrom}
                                onChange={(date) => handleFilterChange('dateFrom', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                label="Date To"
                                value={filterData.dateTo}
                                onChange={(date) => handleFilterChange('dateTo', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
            </Grid>

            <TabContext value={registrationTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, newValue) => setRegistrationTabValue(newValue)} aria-label="Registration tab">
                        <Tab label="Pending Application" value="pendingApplication" />
                        <Tab label="Operation Pending" value="operationPending" />
                        <Tab label="Accounts Pending" value="accountsPending" />
                        <Tab label="Completed" value="completed" />
                    </TabList>
                </Box>
                <TabPanel value="pendingApplication">
                    {loading ? (
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ margin: 'auto' }} />
                        </div>
                    ) : (
                        <div style={{ height: '70vh', width: '100%' }}>
                            <DataGridPro
                                rows={data}
                                getRowId={(row) => row._id}
                                columns={[
                                { field: 'createdAt', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                                { field: 'name', headerName: 'Name', flex: 1, valueGetter: (params) => params.row?.firstName + ' ' + params.row?.lastName },
                                { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, valueGetter: (params) => params.row?.phoneNumber },
                                {
                                    field: 'actions',
                                    headerName: 'Actions',
                                    flex: 1,
                                    renderCell: (params) => (
                                        <div>
                                            {(permissions.includes('approveRegistration') && params.row?.status === 'Pending') && <Button variant="outlined" onClick={() => { setApproveModal(true); setSelectdApprovalId(params.row?._id); }} >Approve</Button>}
                                        </div>
                                    ),
                                },
                                ]}
                                pagination
                                paginationModel={paginationModel}
                                rowCount={totalResults}
                                paginationMode="server"
                                onPaginationModelChange={setPaginationModel}
                                pageSizeOptions={pageOption} 
                            />
                        </div>
                    )}
                </TabPanel>
                <TabPanel value="operationPending"></TabPanel>
                <TabPanel value="accountsPending"></TabPanel>
                <TabPanel value="completed"></TabPanel>
            </TabContext>

            <Modal
                open={approveModal}
                onClose={() => setApproveModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <p>Do you really want to approve this?</p>
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => setApproveModal(false)}>Cancel</Button>
                            <Button variant="contained" onClick={handleApprovalSubmit}>Approve</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </div>
    );
};

export default RegistrationTable;