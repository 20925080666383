import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Paper, Typography, TextField, Modal, Box, Grid, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'; // Import DataGridPro components
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';
import CreateStudentForm from '../schedule/create';
import { generateYearRange, getSessions } from '../../../utlis/commonFunction';

const MyTable = () => {
    const { permissions, userRole } = useUser();
    const [data, setData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [activeBatch, setActiveBatch] = useState('');
    const [myBatch, setMyBatch] = useState(true);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const [faculties, setFaculties] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState('');
    const [selectedCoordinator, setSelectedCoordinator] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedSession, setSelectedSession] = useState('');
    const [coordinator, setCoordinator] = useState([]);

    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];
    
    const years = generateYearRange();

    const sessions = getSessions();

    let navigate = useNavigate();

    useEffect(() => {
        getBatchData();
    }, [searchTerm, paginationModel, myBatch, selectedFaculty, selectedCoordinator, selectedYear, selectedSession]);

    useEffect(() => {
        getData();
    }, []);

    const handleChangeFaculty = (e, value) => {
        setSelectedFaculty(value?._id);
    };

    const handleChangeCoordinator = (e, value) => {
        setSelectedCoordinator(value?._id);
    };

    const handleChangeYear = (e, value) => {
        setSelectedYear(value);
    };

    const handleChangeSession = (e, value) => {
        setSelectedSession(value);
    };

    const getBatchData = async () => {
        setLoading(true); // Start loading
        try {
            const response = await apiService.get('/batch/list', {
                params: {
                    searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                    myBatch: myBatch,
                    coordinator: selectedCoordinator,
                    faculty: selectedFaculty,
                    year: selectedYear,
                    session: selectedSession,
                },
            });

            const facultyResponse = await apiService.get('/faculty/list');

            const coordinatorResponse = await apiService.get('/coordinator/list');

            const { batchs, totalResults } = response?.data || {};
            setData(batchs || []);
            setTotalResults(totalResults || 0);

            setFaculties(facultyResponse?.data?.faculty);
            setCoordinator(coordinatorResponse?.data?.coordinator);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const getData = async () => {
        setLoading(true); // Start loading
        try {
            const facultyResponse = await apiService.get('/faculty/list');
            const coordinatorResponse = await apiService.get('/coordinator/list');

            setFaculties(facultyResponse?.data?.faculty);
            setCoordinator(coordinatorResponse?.data?.coordinator);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    }

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                setLoading(true); // Start loading
                await apiService.post('/batch/delete', null, {
                    params: {
                        id: id,
                    },
                });
                getData();
            } catch (error) {
                alert(error?.response?.data?.error);
                console.error(error);
            } finally {
                setLoading(false); // Stop loading, whether success or error
            }
        }
    };

    function clearLocalStorageKeysWithSubstring(substring) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    }

    const createPress = () => {
        clearLocalStorageKeysWithSubstring("FormData");
        navigate('/dashboard/batch/add');
    };

    const handleCloseModal = (value) => {
        setScheduleModal(value);
    }

    const onShowAllClick = () => {
        setMyBatch(!myBatch);
    }
    
    const CustomToolbar = (props) => {
        const { ...otherProps } = props;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <GridToolbar {...otherProps} />
                <Button onClick={onShowAllClick} variant="outlined">
                    {myBatch ? 'Show All Batches' : 'Show Assigned Batches' }
                </Button>
            </div>
        );
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        overflow: 'auto',
        maxHeight: '80%',
    };
    
    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>BATCHES</Typography>
                {permissions.includes('createBatch') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        margin="normal"
                        size='small'
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-faculty"
                        name="faculty"
                        options={faculties}
                        style={{ marginTop: 10 }}
                        getOptionLabel={(option) => option.name}
                        value={faculties.find((option) => option._id === selectedFaculty) || null}
                        onChange={(e, value) => handleChangeFaculty(e, value)}
                        renderInput={(params) => <TextField {...params} size='small' label="Faculty" />}
                        size='small'
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-coordinator"
                        name="coordinator"
                        options={coordinator}
                        style={{ marginTop: 10 }}
                        getOptionLabel={(option) => option.name}
                        value={coordinator.find((option) => option._id === selectedCoordinator) || null}
                        onChange={(e, value) => handleChangeCoordinator(e, value)}
                        renderInput={(params) => <TextField {...params} size='small' label="Coordinator" />}
                        size='small'
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={'center'} sx={{ marginBottom: 5 }}>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id="yearBox"
                        name="year"
                        required
                        options={years}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option}  
                        value={selectedYear || null}
                        onChange={(e, value) => handleChangeYear(e, value)}
                        renderInput={(params) => <TextField {...params} size='small' label="Academic Year" />}
                        size='small'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id="sessionBox"
                        name="session"
                        required
                        options={sessions}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option}  
                        value={selectedSession || null}
                        onChange={(e, value) => handleChangeSession(e, value)} 
                        renderInput={(params) => <TextField {...params} size='small' label="Session" />}
                        size='small'
                    />
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                </div>
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', flex: 1 },
                            { field: 'branch', headerName: 'Branch', flex: 1, valueGetter: (params) => params.row?.branch?.name },
                            { field: 'faculty', headerName: 'Faculty', flex: 1, valueGetter: (params) => params.row?.faculty?.name },
                            { field: 'coordinator', headerName: 'Coordinator', flex: 1, valueGetter: (params) => params.row?.coordinator?.name },
                            { field: 'student', headerName: 'Enrolled', flex: 1, valueGetter: (params) => params.row?.student?.length  },
                            { field: 'status', headerName: 'Status', flex: 1},
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 350,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('readBatch') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/batch/view?id=${params.row._id}`)} >View</Button>
                                        )}
                                        {permissions.includes('updateBatch') && (
                                            <Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => navigate(`/dashboard/batch/add?id=${params.row._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteBatch') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                        {permissions.includes('createSchedule') && (
                                            <Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => {setScheduleModal(true); setActiveBatch(params.row)}}>Schedule</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: userRole === 'coordinator' ? CustomToolbar : GridToolbar }} // Add GridToolbar for filtering
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}

            <Modal
                open={scheduleModal}
                onClose={() => setScheduleModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <CreateStudentForm from="batch" batchId={activeBatch._id} subjectId={activeBatch.paper} handleCloseModal={handleCloseModal} />
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default MyTable;
