import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
  const { permissions } = useUser();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  // const [page, setPage] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  // const [totalResults, setTotalResults] = useState(0);
  // const [searchTerm,setSearchTerm] = useState('');

  useEffect(() => {
    getData();
  }, []); // Run once on component mount

  let navigate = useNavigate();

  // const handlePageChange = (newPage) => {
  //   setPage(newPage);
  // };

  const getData = async () => {
    try {
      const response = await apiService.get(`/admin/roles`);
      setData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const deleteItem = async (id) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this item?');
    if (shouldDelete) {
      try {
        await apiService.post(`/admin/deleteRole?id=${id}`);
        getData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const createPress = () => {
    navigate('/dashboard/roles/add');
  };

  return (
    <div>
      <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
        <Typography variant="h4" gutterBottom>
          ROLES LIST
        </Typography>
        {permissions.includes('createRole') && (
          <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
            + Create
          </Button>
        )}
      </div>
      {loading ? ( // Render loader if loading is true
        <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Name
                  </Typography>
                </TableCell>
                {permissions.some(permission => ['updateRole', 'deleteRole'].includes(permission)) && (
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight="bold">
                      ACTION
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row?.name !== 'superadmin' && (
                      <>
                        {permissions.includes('updateRole') && (
                          <Button variant="outlined" onClick={() => navigate(`/dashboard/roles/add?id=${row._id}`)}>
                            Edit
                          </Button>
                        )}
                        {permissions.includes('deleteRole') && (
                          <Button
                            variant="outlined"
                            color="error"
                            style={{ marginLeft: 5 }}
                            onClick={() => deleteItem(row._id)}
                          >
                            Delete
                          </Button>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default MyTable;
