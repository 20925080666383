import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const ProtectedFroute = ({ element }) => {
  const isTokenExists = localStorage.getItem('ftoken'); 

  return isTokenExists ? element : <Navigate to="/faculty-login" />;
};

export default ProtectedFroute;
