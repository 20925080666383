import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import apiService from '../../../utlis/apiService';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Download as DownloadIcon } from '@mui/icons-material';
import { apiUrl } from '../../../config';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '60vw',
    maxHeight: '90vh', 
    overflowY: 'scroll',
};

const ViewAttendance = ({ studentId }) => {
    const [attendanceModal, setAttendanceModal] = useState(false);
    const [attendance, setAttendance] = useState([]);

    const fetchAttendanceLogs = async () => {
        try {
            setAttendanceModal(true);
            const response = await apiService.get(`/student/get/attendance?studentId=${studentId}`);
            setAttendance(response?.data?.attendanceBatch);
        } catch (error) {
            console.error(error);
        }
    }

    const presentCount = (schedules) => {
        let present = 0;
        let absent = 0;
        schedules.map(schedule => {
            if(schedule.attendance?.students.length && schedule?.status === 'completed') {
                const student = schedule.attendance?.students.filter(student => student.student === studentId);

                if(student[0]?.status === 'present'){
                    present++;
                }
                else {
                    absent++;
                }
            }
        });

        return `P: ${present}, A: ${absent}`;
    }

    const downloadAttendance = async (batch) => {
        try {
            const response = await apiService.get('/export/report/single/attendance', {
                params: {
                    batchId: batch,
                    studentId: studentId
                },
            });

            if(response?.data?.status === 'success') {
                
                window.open(`${apiUrl}${response?.data?.path}`, '_blank');
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div>
            <Button
                sx={{ float: 'right' }}
                variant="contained"
                color="primary"
                onClick={fetchAttendanceLogs}
            >
                Attendance
            </Button>
            <Modal
                open={attendanceModal}
                onClose={() => setAttendanceModal(false)}
                aria-labelledby="modal-title"
            >
                <Box sx={{ ...style }}>
                    {attendance?.map((batch, index) => (
                        <Accordion defaultExpanded ={false} sx={{ marginBottom: 2 }} key={index}>
                            <AccordionSummary
                                expandIcon={<ArrowDownwardIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography>{batch.name}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }}>
                                    <Grid item xs={3}>
                                        <Typography>Date</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>Status ({presentCount(batch.schedule)})</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Typography>Remark</Typography>
                                            <Button type='button' onClick={() => downloadAttendance(batch._id)}><DownloadIcon /></Button>
                                        </div>
                                    </Grid>
                                </Grid>
                                {batch.schedule?.map((schedule, scheduleIndex) => {
                                    if(schedule?.attendance?.students?.length && schedule?.status === 'completed') {
                                        const student = schedule?.attendance?.students.filter(student => student.student === studentId);
                                        return <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }} key={scheduleIndex}>
                                            <Grid item xs={3}>
                                                <Typography>{new Date(schedule?.attendance?.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography sx={{ textTransform: 'capitalize' }}>{student[0]?.status}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography>{student[0]?.remark ?? '-'}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                })}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Button onClick={() => { setAttendanceModal(false); setAttendance([])}} sx={{ marginTop: 2}} variant="outlined">Close</Button>
                </Box> 
            </Modal>
        </div>
    );
};

export default ViewAttendance;
