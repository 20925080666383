import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { Autocomplete, Button, FormControl, FormLabel, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import apiService from "../../../utlis/apiService";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/en'; 
import { generateYearRange, getSessions } from "../../../utlis/commonFunction";

dayjs.extend(AdvancedFormat);

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateExamForm = () => {
    let query = useQuery();
    let id = query.get("id")
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        examType: '',
        totalMark: '',
        passMark: '',
        batch: '',
        session: '',
        status: '',
    });
    const [subjects, setSubjects] = useState([]);
    const [batches, setBatches] = useState([]);
    const [searchSubject, setSearchSubject] = useState('');
    const [searchBatch, setSearchBatch] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({
        name: '',
        subject: '',
        date: null,
        examType: '',
        totalMark: '',
        passMark: '',
        batch: '',
        session: '',
    });

    const sessions = getSessions();
    const years = generateYearRange();

    const fetchExamData = async () => {
        try {
            if (id) {
                const response = await apiService.get('/exam/get', {
                    params: {
                        id: id,
                    },
                });
                setFormData(response?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSubject = async (query) => {
        setLoading(true);
        try {
            const response = await apiService.get('/paper/list', {
                params: {
                    searchTerm: query,
                },
            });
            setSubjects(response?.data?.papers ?? []);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBatch = async (query) => {
        try {
            const response = await apiService.get('/batch/list', {
                params: {
                    searchTerm: query,
                },
            });
            setBatches(response?.data?.batchs ?? []);

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchExamData();
    }, [id]);

    useEffect(() => {
        fetchSubject(searchSubject);
    }, [searchSubject]);

    useEffect(() => {
        fetchBatch(searchBatch);
    }, [searchBatch]);

    return (
        <div>
            <h1 style={{cursor:'pointer'}} onClick={() => navigate(-1)}>
                <BackButton />
                {`Exams`}
            </h1>
            <form>
                <Typography variant="h4" gutterBottom>
                    View Exam
                </Typography>
                <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    disabled={true}
                    fullWidth
                    margin="dense"
                />
                <FormControl fullWidth sx={{ marginTop: '10px' }}>
                    <InputLabel id="Exam Type">Exam Type *</InputLabel>
                    <Select
                        label="Exam Type"
                        value={formData.examType}
                        disabled={true}
                        fullWidth
                        name="examType"
                    >
                        <MenuItem value="Main">Main</MenuItem>
                        <MenuItem value="Mock">Mock</MenuItem>
                    </Select>
                </FormControl>
                {formData.examType === 'Main' &&
                    <>
                        <FormControl fullWidth sx={{ marginTop: '10px' }}>
                            <InputLabel id="Session">Session *</InputLabel>
                            <Select
                                label="Session"
                                value={formData.session}
                                disabled={true}
                                fullWidth
                                name="session"
                            >
                                {sessions?.map((session, index) => (
                                    <MenuItem key={index} value={session}>{session}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Autocomplete
                            id="combo-box-year"
                            required
                            options={years}
                            disabled={true}
                            style={{ marginTop: '10px' }}
                            getOptionLabel={(option) => option}
                            value={years.find((option) => option === formData.year) || null}
                            renderInput={(params) => <TextField {...params} required label="Academic Year" />}
                        />
                    </>
                }
                {formData.examType === 'Mock' &&
                    <Autocomplete
                        id="combo-box-batch"
                        loading={loading}
                        options={batches}
                        style={{ marginTop: '10px' }}
                        getOptionLabel={(option) => option.name}
                        value={batches.find((option) => option._id === formData.batch) || null}
                        disabled={true}
                        renderInput={(params) => <TextField {...params} label="Batch *" />}
                    />
                }
                <Autocomplete
                    disablePortal
                    id="combo-box-subject"
                    required
                    options={subjects}
                    style={{ marginTop: '10px' }}
                    getOptionLabel={(option) => option.name}
                    value={subjects.find((option) => option._id === formData.subject) || null}
                    disabled={true}
                    renderInput={(params) => <TextField {...params} label="Subject *" />}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                    <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
                    <FormLabel htmlFor='activity-time' className='text-sm' required>
                        Select Date
                    </FormLabel>
                    <MobileDatePicker
                        value={formData.date ? dayjs(formData.date) : null}
                        disabled={true}
                        sx={{ marginTop: 1 }}
                        format="DD/MM/YYYY"
                    />
                    </FormControl>
                </LocalizationProvider>
                <TextField
                    label="Total Mark"
                    name="totalMark"
                    value={formData.totalMark}
                    disabled={true}
                    fullWidth
                    required
                    error={Boolean(errors.totalMark)}
                    helperText={errors.totalMark}
                    margin="dense"
                />
                <TextField
                    label="Pass Mark"
                    name="passMark"
                    value={formData.passMark}
                    disabled={true}
                    fullWidth
                    required
                    error={Boolean(errors.passMark)}
                    helperText={errors.passMark}
                    margin="dense"
                />
                <FormControl fullWidth style={{ marginTop: '20px'}}>
                    <InputLabel id="Status">Status</InputLabel>
                    <Select
                        label="Status"
                        name="status"
                        value={formData.status}
                        disabled={true}
                        fullWidth
                        margin="dense"
                    >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Inactive">Inactive</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                    </Select>
                </FormControl>
            </form>
        </div>
    );
}

export default CreateExamForm;