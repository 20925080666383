import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './loginForm'
import HomePage from './home'
import Leaves from './pages/leaves';


const StudentApp = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/leaves" element={<Leaves />} />
      </Routes>
    </div>
  );
};

export default StudentApp;