import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import config from '../config';
import { FormHelperText, Modal } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '80%',
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://elancelearning.com/">
        Elance Learning
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const defaultTheme = createTheme();

export default function SignInSide() {
  const [forgotModal, setForgotModal] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    phone: '',
    otp: '',
    password: '',
    repassword: '',
  });
  const [error, setError] = useState({
    phone: '',
    otp: '',
    password: '',
    repassword: '',
  });

  const navigate = useNavigate();
  const handleSubmit = async(event) => {
    event.preventDefault();
    try {
      const formData = new FormData(event.currentTarget);
      const phone = formData.get('phone');
      const password = formData.get('password');

      const response = await axios.post(`${config.apiUrl}/admin/faculty/login`, {
        phone: phone,
        password: password,
      });

      console.log(response?.data?.token);
      localStorage.setItem('ftoken',response?.data?.token)
      navigate('/faculty/home');
    } catch (error) {
      console.error('Error during login:', error.message);
    }
  };

  const phoneNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: e.target.value,
    }));
    setError((preError) => ({
      ...preError,
      phone: '',
    }));
  }

  const otpHandleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      otp: e.target.value,
    }));
    setError((preError) => ({
      ...preError,
      otp: '',
    }));
  }

  const passwordHandleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setError((preError) => ({
      ...preError,
      [e.target.name]: '',
    }));
  }

  const submitPhoneNumber = async() => {
    try {
      if(formData.phone) {
        const response = await axios.post(`${config.apiUrl}/admin/faculty/send/otp`, {
          phone: formData.phone,
        });

        if(response?.data?.status === 'success') {
          alert(response?.data?.message);
          setStep(2);
        }
      }
      else {
        setError((preError) => ({
          ...preError,
          phone: 'Phone Number is required',
        }));
      }
    } catch (error) {
      
      if(error?.response?.status === 401) {
        setError((prevData) => ({
          ...prevData,
          phone: error?.response?.data?.message,
        }))
      }
      console.error('Error during login:', error.message);
    }
  }

  const submitVerifyOtp = async () => {
    try {
      if(formData.otp) {
        const response = await axios.post(`${config.apiUrl}/admin/faculty/verify/otp`, {
          phone: formData.phone,
          otp: formData.otp,
        });

        if(response?.data?.status === 'success') {
          alert(response?.data?.message);
          setStep(3);
        }
      }
      else {
        setError((preError) => ({
          ...preError,
          otp: 'OTP is required',
        }));
      }
    } catch (error) {
      if(error?.response?.status === 401 || error?.response?.status === 400) {
        setError((prevData) => ({
          ...prevData,
          otp: error?.response?.data?.message,
        }))
      }
      console.error('Error during login:', error.message);
    }
  }

  const submitPassword = async () => {
    try {
      if(formData.password && formData.repassword) {
        const response = await axios.post(`${config.apiUrl}/admin/faculty/update/password`, {
          phone: formData.phone,
          password: formData.password,
        });

        if(response?.data?.status === 'success') {
          alert(response?.data?.message);
          setStep(1);
          setForgotModal(false);
          setFormData({
            phone: '',
            otp: '',
            password: '',
            repassword: '',
          });
          setError({
            phone: '',
            otp: '',
            password: '',
            repassword: '',
          });
        }
      }
      else {
        if(!formData.password) {
          setError((preError) => ({
            ...preError,
            password: 'Password is required',
          }));
        }

        if(!formData.repassword) {
          setError((preError) => ({
            ...preError,
            repassword: 'Confirm Password is required',
          }));
        }
      }
    } catch (error) {
      if(error?.response?.status === 401 || error?.response?.status === 400) {
        setError((prevData) => ({
          ...prevData,
          otp: error?.response?.data?.message,
        }))
      }
      console.error('Error during login:', error.message);
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh',display:'flex',justifyContent:'center',alignItems:'center' }}>
        <CssBaseline />
        <Grid item xs={11} sm={6} md={3} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius:8
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container center>
                <Grid item>
                  <a href="" onClick={(e) => {e.preventDefault(); setForgotModal(true);}} variant="body2">
                    Forgot password?
                  </a>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Modal
          open={forgotModal}
          onClose={() => setForgotModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography component="h1" variant="h5">Forgot your password?</Typography>
          {step === 1 ? 
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phone"
                autoComplete="phone"
                autoFocus
                value={formData.phone}
                onChange={phoneNumberChange}
                helperText={error.phone}
                error={Boolean(error.phone)}
              />
              <Button
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={submitPhoneNumber}
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2, ml: 1 }}
                onClick={() => setForgotModal(false)}
              >
                Close
              </Button>
            </>
          : step === 2 ? 
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="Otp"
                label="OTP"
                name="otp"
                autoComplete="otp"
                autoFocus
                value={formData.otp}
                onChange={otpHandleChange}
                helperText={error.otp}
                error={Boolean(error.otp)}
              />
              <Button
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={submitVerifyOtp}
              >
                Verify OTP
              </Button>
            </>
          : 
            <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="password"
                  id="NewPassword"
                  label="New Password"
                  name="password"
                  autoComplete="password"
                  autoFocus
                  value={formData.password}
                  onChange={passwordHandleChange}
                  helperText={error.password}
                  error={Boolean(error.password)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="password"
                  id="ConfirmPassword"
                  label="Confirm Password"
                  name="repassword"
                  autoComplete="repassword"
                  autoFocus
                  value={formData.repassword}
                  onChange={passwordHandleChange}
                  helperText={error.repassword}
                  error={Boolean(error.repassword)}
                />
                <Button
                  type="button"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={submitPassword}
                >
                  Save
                </Button>
              </>
          }
        </Box>
      </Modal>
    </ThemeProvider>
  );
}