import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import config  from '../config';
import axios from 'axios'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://elancelearning.com/">
        Elance Learning
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const defaultTheme = createTheme();

export default function SignInSide() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    common: '',
  });


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email');
        const password = formData.get('password');

        if(email === '') {
          setErrors({...errors, email: 'Email field is required'});
        }
        else if(password === '') {
          setErrors({...errors, password: 'Password field is required'});
        }
        else{
          if(/^[\w%\+\-]+(\.[\w%\+\-]+)*@[\w%\+\-]+(\.[\w%\+\-]+)+$/.test(email)){
            const response = await axios.post(`${config.apiUrl}/admin/login`, {
              email: email,
              password: password,
            });

            console.log(response?.data?.token);
            localStorage.setItem('token',response?.data?.token)
            navigate('/dashboard');
            window.location.reload();
          }
          else {
            setErrors({...errors, email: 'Must be an email'});
          }
        }
      } catch (error) {
        if(error?.response?.data?.status === 'error'){
          setErrors({...errors, common: error?.response?.data?.message});
        }
        console.error('Error during login:', error.message);
      }
  };

  const handleChange = (e) => {
    if(e.target.value !== ''){
      setErrors({...errors, [e.target.name]: ''});
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh',display:'flex',justifyContent:'center',alignItems:'center' }}>
        <CssBaseline />
        <Grid item xs={11} sm={6} md={3} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius:8
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={errors.email !== '' ? true : false}
                helperText={errors.email}
                onChange={(e) => handleChange(e)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={errors.password !== '' ? true : false}
                helperText={errors.password}
                onChange={(e) => handleChange(e)}
              />

              {errors.common ? (
                <Grid item sx={{color: 'red'}}>
                  {errors.common}
                </Grid>
              ) : ''}
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}