import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { Button, Card, CardContent, Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@mui/material';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { useUser } from '../../../context/userContext';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MarkAttendance = () => {
    const navigate = useNavigate();
    let query = useQuery();
    let id = query.get('id');
    const [formData, setFormData] = useState({
        // name: '',
        year: '',
        batch: '',
        date: null,
        start: null,
        end: null,
        topics:[],
        status: '',
    });
    const [students, setStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredStudents = students.filter((student) =>
        student?.student?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || student?.student?.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        if (id) {
          const fetchScheduleData = async () => {
            try {
              const response = await apiService.get(`/schedule/get?id=${id}`);
      
              setFormData(response.data);
              setStudents(response?.data?.attendance?.students);
            } catch (error) {
              console.error(error);
            }
          };
      
          fetchScheduleData();
        }
    }, [id]);

    const handleRemarksChange = (id, value) => {
        setStudents((prevStudents) => {
          const updatedStudents = prevStudents.map(student => {
            if (student._id === id) {
              return {
                ...student,
                remarks: value
              };
            }
            return student;
          });
          return updatedStudents;
        });
    };

    const handleCheckboxChange = (id) => {
        setStudents((prevStudents) => {
          const updatedStudents = prevStudents.map(student => {
            if (student._id === id) {
              return {
                ...student,
                status: student.status === 'present' ? 'absent' : 'present'
              };
            }
            return student;
          });
          return updatedStudents;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        let murl = `/schedule/attendance/update?id=${id}`;
    
        try {
            const response = await apiService.post(murl, { date: formData.date, students: students }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            alert('Attendance recorded!');
        } catch (error) {
            alert(error?.response?.data?.error);
            console.error(error);
        }
    };

    const getDueDate = (student) => {
        const admissions = student?.admission;
        const today = new Date();

        let earliestDate = null;

        admissions?.forEach(admission => {
            admission?.category?.forEach(category => {
                // Check OTS (One Time settlement) fees
                if (category?.ots?.amount && new Date(category?.ots?.dueDate) < today && !category?.ots?.received) {
                    const dueDate = new Date(category?.ots?.dueDate);
                    
                    if (!earliestDate || dueDate < earliestDate) {
                        earliestDate = dueDate;
                    }
                }
                
                // Check Fee Split
                if (category?.feeSplit?.length) {
                    category?.feeSplit.forEach(fee => {
                        if (!fee.received && new Date(fee.dueDate) < today) {
                            const dueDate = new Date(fee.dueDate);
                            if (!earliestDate || dueDate < earliestDate) {
                                earliestDate = dueDate;
                            }
                        }
                    });
                }
            });
        });

        if (earliestDate) {
        return new Date(earliestDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
        } else {
            return 'No Fee Due';
        }
    }

    return (
        <div>
            <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
                <BackButton />
                Batch
            </h1>
            <form onSubmit={handleSubmit}>
                <div style={{marginTop:20}}>
                    <Typography variant="h5" gutterBottom>
                        Student List  | Total : {students?.length} | Absent : {students.filter(student => student.status === 'absent')?.length} | Present {students.filter(student => student.status === 'present')?.length}
                    </Typography>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <List style={{marginTop:10,marginBottom:20}}>
                        {filteredStudents.map((student, index) => (
                            <Card key={student?._id} sx={{ marginBottom: 2,padding:0,paddingBottom:0  }} >
                                <CardContent>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        textDecoration: student.status == 'present'? 'none' : 'line-through',
                                                        color: student.status== 'present' ? 'inherit' : 'red',
                                                    }}
                                                >
                                                    {`${student?.student?.firstName} ${student?.student?.lastName}`}
                                                </Typography>
                                            }
                                        />
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    {getDueDate(student?.student)}
                                                </Typography>
                                            } 
                                        />
                                        <ListItemSecondaryAction>
                                            {student?.status == 'absent' && (
                                                <TextField
                                                    label="Remarks"
                                                    style={{ padding: 0 }}
                                                    value={student?.remarks}
                                                    onChange={(e) => handleRemarksChange(student?._id, e.target.value)}
                                                />
                                            )}
                                            <Checkbox
                                                checked={student?.status == 'present'}
                                                onChange={() => handleCheckboxChange(student?._id)}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </CardContent>
                            </Card>
                        ))}
                    </List>

                    <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                        Submit Attendance
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default MarkAttendance;