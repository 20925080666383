import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'; // Import DataGridPro components
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const AcademicHolidayList = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [loading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];
    
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, [paginationModel]);


    const getData = async () => {
        try {
            setLoading(true); // Start loading
            const response = await apiService.get('/academic-holiday/list', {
                params: {
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize
                },
            });
            setData(response?.data?.academicHoliday);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                setLoading(true); // Start loading
                await apiService.post(`/academic-holiday/delete?id=${id}`);
                getData();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Stop loading, whether success or error
            }
        }
    };

    const clearLocalStorageKeysWithSubstring = (substring) => {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    };

    const createPress = () => {
        clearLocalStorageKeysWithSubstring('FormData');
        navigate('/dashboard/academic-holiday/add');
    };

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>Academic Holiday</Typography>
                {permissions.includes('createAcademicHoliday') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'academicYear', headerName: 'Academic Year', width: 200 },
                            { field: 'date', headerName: 'Date', width: 200, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                            { field: 'description', headerName: 'Description', width: 150},
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 150,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateAcademicHoliday') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/academic-holiday/add?id=${params.row._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteAcademicHoliday') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }} // Add GridToolbar for filtering
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}
        </div>
    );
};

export default AcademicHolidayList;
