import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { generateYears, getSessions } from "../../../utlis/commonFunction";
import studentApiService from "../../../utlis/apiStudent";

const Exams = () => {
    const [admissionTabValue, setAdmissionTabValue] = useState('admission0');
    const [expanded, setExpanded] = useState('Accordion0');
    const [admissions, setAdmissions] = useState([]);
    const [activeExam, setActiveExam] = useState({
        admission: '',
        category: '',
    });
    const [exam, setExam] = useState({
        admissionId: '',
        categoryId: '',
        subject: '',
        month: '',
        year: '',
        mark: '',
        status: '',
    })

    const months = getSessions();
    const years = generateYears();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleAdmissionTabChange = (e, value) => {
        setAdmissionTabValue(value);
    }

    const showExamForm = (admission, category) => {
        setActiveExam({
            admission: admission,
            category: category,
        });

        setExam({
            admissionId: admission,
            categoryId: category,
            subject: '',
            month: '',
            year: '',
            mark: '',
            status: '',
        });
    }

    const handleChangeOption = (e, name, value) => {
        setExam((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const saveExam = async () => {
        try {
            if (window.confirm("Are you sure you want to submit this? You won't have the chance to edit it again!")) {
                const response = await studentApiService.post(`/user/post/exams`, exam, {
                    headers: {
                    'Content-Type': 'application/json',
                    }
                });
                setAdmissions(response?.data?.admission);
                setActiveExam({
                    admission: '',
                    category: '',
                });
            }
        } catch (error){
            console.error('Error fetching academic data:', error);
        }
    }

    const getData = async () => {
        try {
            const response = await studentApiService.get(`/user/get/exams`);
            setAdmissions(response?.data?.admission);
        } catch (error){
            console.error('Error fetching exam data:', error);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
            <TabContext value={admissionTabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleAdmissionTabChange} aria-label="admissions tab">
                        {admissions?.map((admission, index) => (
                            <Tab key={index} label={admission?.course?.name} value={`admission${index}`} />
                        ))}
                    </TabList>
                </Box>
                {admissions?.map((admission, admissionIndex) => (
                    <TabPanel key={admissionIndex} value={`admission${admissionIndex}`}>
                        <div>
                            {admission?.category?.map((category, categoryIndex) => {
                                const paperInExam = category?.exam?.map(exam => { 
                                    if(exam.status === 'Pass') return exam.subjectId;
                                    else return null;
                                }).filter(exam => exam !== null);
                                
                                return <Accordion key={admissionIndex + '' + categoryIndex} expanded={expanded === `Accordion${categoryIndex}`} onChange={handleChange(`Accordion${categoryIndex}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${admissionIndex + '' + categoryIndex}-content`}
                                        id={`panel${admissionIndex + '' + categoryIndex}-header`}
                                    >
                                        {category?.category?.name}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
                                            <Button variant="outlined" color="primary" sx={{float: 'right', marginBottom: 1}} size="small" onClick={() => showExamForm(admission?._id, category?._id)}>Add Exam</Button>
                                            {category?.exam.length ?
                                                <TableContainer component={Paper} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Subject</TableCell>
                                                                <TableCell>Month</TableCell>
                                                                <TableCell>Year</TableCell>
                                                                <TableCell>Mark</TableCell>
                                                                <TableCell>Status</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {category?.exam?.map((exam, examIndex) => (
                                                                <TableRow key={examIndex}>
                                                                    <TableCell>{exam?.subject}</TableCell>
                                                                    <TableCell>{exam?.session}</TableCell>
                                                                    <TableCell>{exam?.year}</TableCell>
                                                                    <TableCell>{exam?.mark}</TableCell>
                                                                    <TableCell>{exam?.status}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            : ''}
                                            {activeExam.admission === admission?._id && activeExam.category === category?._id ? 
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item md={2} xs={12}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-subject"
                                                            name="subject"
                                                            options={category?.papers.filter(paper => !paperInExam.includes(paper?._id))}
                                                            style={{ marginTop: 15 }}
                                                            getOptionLabel={(option) => option?.name}
                                                            value={category?.papers.find((option) => option?._id === exam.subject) || null}
                                                            onChange={(e, value) => handleChangeOption(e, 'subject', value?._id)}
                                                            renderInput={(params) => <TextField {...params} label="Subject" required size="small" />}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-month"
                                                            name="month"
                                                            options={months}
                                                            style={{ marginTop: 15 }}
                                                            getOptionLabel={(option) => option}  
                                                            value={exam.month || null}
                                                            onChange={(e, value) => handleChangeOption(e, 'month', value)} 
                                                            renderInput={(params) => <TextField {...params} label="Month" required size="small" />}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Autocomplete
                                                            disablePortal
                                                            id="combo-box-year"
                                                            name="year"
                                                            options={years}
                                                            style={{ marginTop: 15 }}
                                                            getOptionLabel={(option) => option}  
                                                            value={exam.year || null}
                                                            onChange={(e, value) => handleChangeOption(e, 'year', value)} 
                                                            renderInput={(params) => <TextField {...params} label="Academic Year" required size="small" />}
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <TextField
                                                            label="Mark"
                                                            name="mark"
                                                            value={exam.mark}
                                                            onChange={(e) => handleChangeOption(e, 'mark', e.target.value)}
                                                            fullWidth
                                                            required
                                                            type="number"
                                                            style={{ marginTop: 15 }}
                                                            size="small"
                                                        />
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <FormControl fullWidth style={{ marginTop: 15 }} size="small">
                                                            <InputLabel id="Status" required>Status</InputLabel>
                                                            <Select
                                                                label="Status"
                                                                name="status"
                                                                value={exam.status}
                                                                onChange={(e) => handleChangeOption(e, 'status', e.target.value)}
                                                                fullWidth
                                                                margin="dense"
                                                                size="small"
                                                            >
                                                                <MenuItem value="Pass">Pass</MenuItem>
                                                                <MenuItem value="Fail">Fail</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item md={2} xs={12}>
                                                        <Button variant="contained" onClick={saveExam} style={{ marginTop: 15 }} color="primary" size="small">Submit</Button>
                                                    </Grid>
                                                </Grid>
                                            :''}
                                        </Container>
                                    </AccordionDetails>
                                </Accordion>
                            })}
                        </div>
                    </TabPanel>
                ))}
            </TabContext>
        </Container>
    );
}

export default Exams;