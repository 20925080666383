import React, { useState, useEffect } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Typography, Autocomplete, Button, Box,TextField, Grid, FormControl, Card, LinearProgress, CircularProgress} from '@mui/material';
import apiService from '../../../utlis/apiService';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DownloadIcon from '@mui/icons-material/Download';
import { apiUrl } from '../../../config';

export default function ExportDefaultToolbar() {
    const [data, setData] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [filterData, setFilterData] = useState({
        batch: '',
        dateFrom: null,
        dateTo: null,
    });
    const [studentsCount, setStudentsCount] = useState(0);
    const [goodCount, setGoodCount] = useState([]);
    const [averageCount, setAverageCount] = useState([]);
    const [poorCount, setPoorCount] = useState([]);
    const [loading, setLoading] = useState(false);
    const [batchSearchQuery, setBatchSearchQuery] = useState('');
    const [faculties, setFaculties] = useState([]);
    const [coordinator, setCoordinator] = useState([]);
    
    useEffect(() => {
        getBatchData(batchSearchQuery);
    }, [batchSearchQuery]);

    useEffect(() => {
        getFilterData();
    }, [filterData]);

    // useEffect(() => {
    //     getData();
    // }, []);
    
    const getData = async (query) => {
        try {
            const facultyResponse = await apiService.get('/faculty/list');
            const coordinatorResponse = await apiService.get('/coordinator/list');

            setFaculties(facultyResponse?.data?.faculty);
            setCoordinator(coordinatorResponse?.data?.coordinator);
        } catch (error) {
            console.error(error);
        }
    };

    const getBatchData = async (query) => {
        try {
            const batchResponse = await apiService.get('/batch/list', {
                params: {
                    searchTerm: query,
                },
            });
            setBatches(batchResponse?.data?.batchs);
        } catch (error) {
            console.error(error);
        }
    };

    const handleBatchSearchOptionChange = (event, newQuery, reason) => {
        if (reason === 'reset') {
            return;
        }
        setBatchSearchQuery(newQuery);
    }

    const handleBatchChange = (batchValue) => {
        setSelectedBatch(batchValue);
        setFilterData((prevData) => ({
            ...prevData,
            batch: batchValue?._id,
        }));
    }

    const handleChange = (name, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const getFilterData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/report/attendance', {
                params: {
                    ...filterData,
                },
            });

            setData(response?.data?.report);
            setStudentsCount(response?.data?.students);
            setGoodCount(response?.data?.good);
            setAverageCount(response?.data?.average);
            setPoorCount(response?.data?.poor);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const exportReport = async () => {
        try {
            const response = await apiService.get('/export/report/attendance', {
                params: {
                    ...filterData,
                },
            });

            if(response?.data?.status === 'success') {
                
                window.open(`${apiUrl}${response?.data?.path}`, '_blank');
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const LinearProgressWithLabel = (props) => {
        const values = Math.round(props.value,);
        
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={values} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                        {`${values}%`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                ATTENDANCE REPORT
            </Typography>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 5 }}>
                <Grid item xs={4}>
                    <Autocomplete
                        value={selectedBatch}
                        options={batches}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => handleBatchChange(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Batch" size='small' />}
                        onInputChange={handleBatchSearchOptionChange}
                        size='small'
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-mentor"
                        name="faculty"
                        options={faculties}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={faculties.find((option) => option._id === filterData.faculty) || null}
                        onChange={(e, value) => handleChange(e, value?._id, 'faculty')}
                        renderInput={(params) => <TextField {...params} label="Faculty" />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-coordinator"
                        name="coordinator"
                        options={coordinator}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={coordinator.find((option) => option._id === filterData.coordinator) || null}
                        onChange={(e, value) => handleChange(e, value?._id, 'coordinator')}
                        renderInput={(params) => <TextField {...params} label="Coordinator" />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 5 }}> */}
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                label="Date From"
                                value={filterData.paymentDateFrom}
                                onChange={(date) => handleChange('dateFrom', date)}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                label="Date To"
                                value={filterData.dateTo}
                                onChange={(date) => handleChange('dateTo', date)}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                        actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Button variant="outlined" sx={{ float: 'right' }} title="Export" onClick={exportReport}><DownloadIcon /></Button>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item xs={3}>
                    <Card sx={{ padding: 2, backgroundColor: '#dde6ee' }}>
                        <Typography variant='h6'>
                            Students:
                            <br />
                            {studentsCount}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ padding: 2, backgroundColor: '#ddeee0' }}>
                        <Typography variant='h6'>
                            Good:
                            <br />
                            {goodCount?.length}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ padding: 2, backgroundColor: '#eeeddd' }}>
                        <Typography variant='h6'>
                            Average:
                            <br />
                            {averageCount?.length}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ padding: 2, backgroundColor: '#eedddd' }}>
                        <Typography variant='h6'>
                            Poor:
                            <br />
                            {poorCount?.length}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>

            ) : (
                <DataGridPro
                    getRowId={(row) => row._id}
                    rows={data}
                    disableDensitySelector
                    columns={[
                        { field: 'name', headerName: 'Student Name', width: 150 },
                        { field: 'total', headerName: 'Total', width: 90 },
                        { field: 'present', headerName: 'Present', width: 90 },
                        { field: 'absent', headerName: 'Absent', width: 90 },
                        {
                            field: 'percentage',
                            headerName: 'Percentage',
                            width: 500,
                            renderCell: (params) => <LinearProgressWithLabel value={((params?.row?.present / params?.row?.total) * 100)} />
                        }
                    ]}
                />
            )}
        </div>
    );
}
