import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { DemoContainer,DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { 
  FormControl,
  FormLabel,
  List,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Checkbox,
  InputLabel
} from '@mui/material';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // Import the advancedFormat plugin
import 'dayjs/locale/en'; 
import { useUser } from '../../../context/userContext';

dayjs.extend(AdvancedFormat);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateStudentForm = (props) => {
  let query = useQuery();
  const { permissions } = useUser();
  let id = query.get('id');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    classRoom: '',
    batch: props?.batchId ?? '',
    date: null,
    start: null,
    end: null,
    topics:[],
    status: '',
    classBy: '',
    remark: '',
  });

  const [batches, setBatches] = useState([]);
  const [topics,setTopics] = useState([])
  const [value, setValue] = React.useState(dayjs('2022-04-17T15:30'));
  const [students, setStudents] = useState([]);


  const [selectedTopics,setSelectedTopics] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [classes,setClasses] = useState([]);
  const [subject, setSubject] = useState(props?.subjectId ?? '');
  const [batchSearchQuery, setBatchSearchQuery] = useState('');

  const filteredStudents = students.filter((student) =>
    (student?.student?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || 
    student?.student?.lastName.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const handleCheckboxChange = (id) => {
    setStudents((prevStudents) => {
      const updatedStudents = prevStudents.map(student => {
        if (student._id === id) {
          return {
            ...student,
            remarks: (formData.status === 'completed' && student.status === 'absent') ? 'Late comer' : '',
            status: student.status === 'present' ? 'absent' : 'present',
            currentUpdate: true,
          };
        }
        return student;
      });
      return updatedStudents;
    });
  };

  const handleRemarksChange = (id, value) => {
    setStudents((prevStudents) => {
      const updatedStudents = prevStudents.map(student => {
        if (student._id === id) {
          return {
            ...student,
            remarks: value
          };
        }
        return student;
      });
      return updatedStudents;
    });
  };

  useEffect(() => {
    if (id) {
      const fetchScheduleData = async () => {
        try {
          const response = await apiService.get(`/schedule/get?id=${id}`);
  
          setFormData(response.data);
          setSelectedTopics(response?.data?.topics);
          setStudents(response?.data?.attendance?.students);
          setSubject(response?.data?.batch?.paper);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchScheduleData();
    }
  }, [id]);


  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('paperFormData'));
    const storedTimestamp = localStorage.getItem('paperFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);

  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - timestamp < 50000;
  };

  
useEffect(() => {
  const fetchData = async () => {
    try {
      // const batchesResponse = await apiService.get('/batch/list');
      // const topicsResponse = await apiService.get('/topic/list');
      const classResponse = await apiService.get('/class/list');

      // const batchList = batchesResponse?.data?.batchs;
      // const activeBatchList = batchList.filter(batch => batch);
      const classs = classResponse?.data?.classRoom;
      // setBatches(activeBatchList);
      // setTopics(topicsResponse?.data?.topics);
      setClasses(classs);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, []);

useEffect(() => {
  getBatchData(batchSearchQuery);
}, [batchSearchQuery]);

const getBatchData = async (query) => {
  try {
      const batchResponse = await apiService.get('/batch/list', {
          params: {
              searchTerm: query,
              id: props?.batchId,
          },
      });
      setBatches(batchResponse?.data?.batchs);
  } catch (error) {
      console.error(error);
  }
};

const handleBatchSearchOptionChange = (event, newQuery, reason) => {
  if (reason === 'reset') {
      return;
  }
  setBatchSearchQuery(newQuery);
}

useEffect(() => {
  if(subject)
    getTopics();
}, [subject]);

const getTopics = async () => {
  const topicsResponse = await apiService.get(`/topic/listBySubject?subjectId=${subject}`);
  setTopics(topicsResponse?.data?.topics);
}

const [errors, setErrors] = useState({
  name: '',
});

const handleChange = (nName, value) => {
  setFormData((prevData) => ({
    ...prevData,
    [nName]: value,
  }));
};

const validateForm = () => {
  let isValid = true;
  const newErrors = {
    name: '',
  };

  // if (!formData.name) {
  //   newErrors.name = 'Invalid name';
  //   isValid = false;
  // }

  setErrors(newErrors);
  return isValid;
};

const handleSubmit = async (e) => {
  e.preventDefault();

  if (validateForm()) {
    let murl = id
      ? `/schedule/update?id=${id}`
      : `/schedule/create`;

    try {
      const response = await apiService.post(murl, { ...formData, topics: selectedTopics, attendance: null }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      props && props.from === 'batch' ? props.handleCloseModal(false) : navigate(-1);
    } catch (error) {
      alert(error?.response?.data?.error);
      console.error(error);
    }
  }
};

const updateAttendance = async (e, type) => {
  e.preventDefault();
  let murl = `/schedule/attendance/update?id=${id}`;
  try {
    const response = await apiService.post(murl, { date: formData.date, students: students, type: type }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    alert('Attendance recorded!');
  } catch (error) {
    alert(error?.response?.data?.error);
    console.error(error);
  }
}

const handleChangeSubmit = (e, value, name) => {
  setFormData((prevData) => ({
    ...prevData,
    [name]: value?._id,
  }));
  if(name === 'batch'){
    setSubject(value?.paper);
  }
};

const handleChangeTopics=(newValue)=>{
  setSelectedTopics(newValue);
}

const createOnClick = () => {
  localStorage.setItem('paperFormData', JSON.stringify(formData));
  localStorage.setItem('paperFormDataTimestamp', new Date().getTime());
  navigate('/course/add');
};

  return (
    <div>
      {props && props.from === 'batch' ?'':
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Schedules`}
      </h1>}

      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Schedule
        </Typography>

      {!id &&
        <>

          <Autocomplete
            disablePortal
            id="combo-box-batch"
            name="batch"
            options={batches}
            style={{ marginTop: 15 }}
            getOptionLabel={(option) => option.name}
            value={batches.find((option) => option._id === formData.batch) || null}
            onChange={(e, value) => handleChangeSubmit(e, value, 'batch')}
            renderInput={(params) => <TextField {...params} label="Batch" />}
            onInputChange={handleBatchSearchOptionChange}
          />
      

          <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
            <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
              <FormLabel htmlFor='activity-time' className='text-sm' required>
                Select Date
              </FormLabel>
              <MobileDatePicker
                value={formData.date}
                onChange={(date) => handleChange('date',date)}
                sx={{ marginTop: 1 }}
                format="DD/MM/YYYY"
              />
            </FormControl>
          </LocalizationProvider>
        </>
      }
      {id && 
        <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
          <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
            <FormLabel htmlFor='activity-time' className='text-sm' required>
              Select Date
            </FormLabel>
            <MobileDatePicker
              value={formData?.date ? dayjs(formData?.date) : null}
              onChange={(date) => handleChange('date',date)}
              sx={{ marginTop: 1 }}
              disabled={!permissions.includes('updateDate')}
              format="DD/MM/YYYY"
            />
          </FormControl>
        </LocalizationProvider>
      }
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
      <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
        <FormLabel htmlFor='activity-time' className='text-sm' required>
          Start Time
        </FormLabel>
        <MobileTimePicker
          value={formData?.start ? dayjs(formData?.start) : null} 
          onChange={(time) => handleChange('start', time)}
          ampm={true}
          sx={{ marginTop: 1 }}
          format='HH:mm'
        />
      </FormControl>
    </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
          <FormLabel htmlFor='activity-time' className='text-sm' required>
            End Time
          </FormLabel>
          <MobileTimePicker
            value={formData?.end ? dayjs(formData?.end) : null} 
            onChange={(time) => handleChange('end',time)}
            format='HH:mm'
            ampm={true}
            sx={{ marginTop: 1 }}
          />
        </FormControl>
      </LocalizationProvider>

      <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="classRoom"
          options={classes}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={classes.find((option) => option._id === formData.classRoom) || null}
          onChange={(e, value) => handleChangeSubmit(e, value, 'classRoom')} 
          renderInput={(params) => <TextField {...params} label="Add Class Room" />}
        />

      <FormControl fullWidth sx={{ marginTop: '15px' }}>
        <InputLabel id="Select Status">Select Status</InputLabel>
        <Select
          label="Select Status"
          value={formData.status}
          onChange={(e) => handleChange('status', e.target.value)}
          fullWidth
          disabled={formData.status === 'completed'}
          name="status"
          required
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="canceled">Canceled</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: '15px' }}>
        <InputLabel id="Class Taken By">Class Taken By</InputLabel>
        <Select
          label="Class Taken By"
          value={formData.classBy}
          onChange={(e) => handleChange('classBy', e.target.value)}
          fullWidth
          name="classBy"
        >
          <MenuItem value="Faculty">Faculty</MenuItem>
          <MenuItem value="Mentor">Mentor</MenuItem>
        </Select>
      </FormControl>

      <Autocomplete
        multiple
        id="topic-autocomplete"
        options={topics}
        getOptionLabel={(option) => `Name : ${option.name} | Hours: ${option.hours} | Paper : ${option?.paper?.name} | Course : ${option?.paper?.course?.name}` }
        value={selectedTopics}
        onChange={(event, newValue) => handleChangeTopics(newValue)}
        renderInput={(params) => <TextField {...params} label={'Topics'} />}
        sx={{ marginTop: '15px' }}
      />

      <TextField
        label="Remark"
        name="remark"
        value={formData.remark}
        onChange={(e) => handleChange('remark', e.target.value)}
        fullWidth
        margin="normal"
      />
    
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
        {id ? 'Submit Schedule' : 'Create Schedule'}
      </Button>
      
        {id &&
          <div style={{marginTop:20}}>
            <Typography variant="h5" gutterBottom>
              Student List  | Total : {students?.length} | Absent : {students.filter(student => student.status === 'absent')?.length} | Present {students.filter(student => student.status === 'present')?.length}
            </Typography>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <List style={{marginTop:10,marginBottom:20}}>
     
              {filteredStudents.map((student, index) => (
                <Card key={student?._id} sx={{ marginBottom: 2,padding:0,paddingBottom:0  }} >
                  <CardContent >
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              textDecoration: student.status == 'present'? 'none' : 'line-through',
                              color: student.status== 'present' ? 'inherit' : 'red',
                            }}
                          >
                            {`${student?.student?.firstName} ${student?.student?.lastName}`}
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        {student?.status == 'absent' || (permissions.includes('updateAttendance') && formData.status === 'completed')? (
                          <TextField
                            label="Remarks"
                            style={{ padding: 0 }}
                            value={student?.remarks}
                            onChange={(e) => handleRemarksChange(student?._id, e.target.value)}
                          />
                        ) : student?.remarks !== '' ? 
                          student?.remarks
                        :''}
                        <Checkbox
                          checked={student?.status == 'present'}
                          onChange={() => handleCheckboxChange(student?._id)}
                          disabled={(!permissions.includes('updateAttendance') && formData.status === 'completed' && student?.status == 'present' && !student.currentUpdate)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </CardContent>
                </Card>
              ))}
              {permissions.includes('updateAttendance') || formData.status !== 'completed' ? 
                <Button type="button" onClick={(e) => updateAttendance(e, '')} variant="outlined" color="primary" style={{ marginTop: '20px' }}>
                  Update Attendance
                </Button>
              : ''}
              {!permissions.includes('updateAttendance') && formData.status === 'completed' ? 
                <Button type="button" onClick={(e) => updateAttendance(e, 'latecomer')} variant="outlined" color="primary" style={{ marginTop: '20px' }}>
                  Update Late Comer Attendance
                </Button>
              : ''}
            </List>
          </div>
        }

        {props && props.from === 'batch' ? 
          <Button type="button" onClick={() => props.handleCloseModal(false)} variant="outlined" color="primary" style={{ marginTop: '20px', marginLeft: '10px' }}>
            Cancel
          </Button>
          : ''}
      </form>
    </div>
  );
};

export default CreateStudentForm;
