import axios from 'axios';

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

const getAuthToken = (utype) => {
    if(utype == 'faculty'){
        return localStorage.getItem('ftoken');
    } else if(utype == 'student'){
      return localStorage.getItem('stoken');
    }
    else{
        return localStorage.getItem('token');
    }
 
};

export { setAuthToken, getAuthToken };
