import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { FormControl, Grid, InputLabel } from '@mui/material';
import { generateYearRange, getStatusType } from '../../../utlis/commonFunction';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ViewLevelForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();
  const [papers, setPapers] = useState([]);
  const [courses, setCourses] = useState([]);
  const [emiScheme, setEmiScheme] = useState([]);
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [selectedEmiScheme, setSelectedEmiScheme] = useState([]);
  const [statusType, setStatusType] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    year: '',
    course: '',
    statusType: '',
    admissionFee: '',
    fee: [
      {
        mode: '',
        value: '',
        revisitCount: '',
        admissionFee: '',
        selectedEmiScheme: []
      },
    ],
  });

  const years = generateYearRange();

  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/level/get', {
            params: {
              id: id,
            },
          });

          const data = response?.data;
          const feeData = data.fee.map(fee => {
            const emiSchemes = fee.emiSchemes.map(emi => emi._id);
            const selectedEmiScheme = fee.emiSchemes;
            return {
              ...fee,
              emiSchemes: emiSchemes,
              selectedEmiScheme: selectedEmiScheme
            }
          });
          data.fee = feeData;
          
          setFormData(data);
          setSelectedPapers(response?.data?.papers);
          setSelectedEmiScheme(response?.data?.emiSchemes);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchLevelData();
  }, [id]);

  useEffect(() => {
    getCourseData();
    getEmiSchemeData();
    fetchStatusTypeData();
  }, []);

  const fetchStatusTypeData = async () => {
    const statusTypeData = await getStatusType();
    setStatusType(statusTypeData);
  }

  const getCourseData = async () => {
    try {
      const response = await apiService.get('/course/list', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      setCourses(response?.data?.course);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmiSchemeData = async () => {
    try {
      const response = await apiService.get('/emi-scheme/list', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      setEmiScheme(response?.data?.emiScheme);
    } catch (error) {
      console.error(error);
    }
  };

  const getPapersByCourse=async(cid)=>{
    try {
      const response = await apiService.get(`/paper/paperbycourse?id=${cid}`);
      console.log(response.data);
      setPapers(response.data)
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
      getPapersByCourse(formData.course);
  },[formData.course]);


  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Categories`}
      </h1>
      <form >
        <Typography variant="h4" gutterBottom>
          View Category
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          disabled={true}
          fullWidth
          margin="normal"
        />
        <Autocomplete
          disablePortal
          id="academicYear-box"
          name="year"
          options={years}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.year || null}
          disabled={true}
          renderInput={(params) => <TextField {...params} label="Academic Year" />}
        />

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="courses"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={courses.find((option) => option._id === formData.course) || null}
          disabled={true}
          renderInput={(params) => <TextField {...params} label="Select Course" />}
        />

        <FormControl fullWidth>
          <TextField
            label="Status Type"
            select
            value={formData.statusType}
            fullWidth
            name="statusType"
            margin="normal"
            disabled
          >
            <MenuItem value="">Select Status Type</MenuItem>
            {statusType?.map((type, index) => (
              <MenuItem key={index} value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </FormControl>

        <Autocomplete
          multiple
          id="paper-autocomplete"
          options={papers}
          getOptionLabel={(option) => option.name}
          value={selectedPapers}
          disabled={true}
          renderInput={(params) => <TextField {...params} label="Subjects" />}
          sx={{ marginTop: '15px' }}
        />
        
        {formData.fee && formData.fee.map((row, index) => (
          <div key={index}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={2} sx={{ marginTop: '6px' }}>
                <FormControl fullWidth>
                  <InputLabel id="Mode">Mode</InputLabel>
                  <Select
                    label="Mode"
                    value={row.mode}
                    disabled={true}
                    fullWidth
                    name="mode"
                  >
                    <MenuItem value="Offline">Offline</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    <MenuItem value="Hybrid">Hybrid</MenuItem>
                    <MenuItem value="Recordings">Recordings</MenuItem>
                    <MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Amount"
                  type='number'
                  margin="normal"
                  name="value"
                  value={row.value}
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
              <TextField
                  label="Admission Fee"
                  name="admissionFee"
                  value={row.admissionFee}
                  disabled={true}
                  fullWidth
                  required
                  margin="normal"
                />
              </Grid>
              <Grid item xs={2}>
              <Autocomplete
                multiple
                id="emi-scheme-autocomplete"
                options={emiScheme}
                getOptionLabel={(option) => option?.name}
                value={row.selectedEmiScheme}
                disabled={true}
                renderInput={(params) => <TextField {...params} label="EMI Scheme" />}
                sx={{ marginTop: '15px' }}
              />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Resit Count"
                  type='number'
                  margin="normal"
                  name="revisitCount"
                  value={row.revisitCount}
                  disabled={true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} sx={{ marginTop: '6px' }}>
                
              </Grid>
            </Grid>
          </div>
        ))}
      </form>
    </div>
  );
};

export default ViewLevelForm;
