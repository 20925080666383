import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  TextField,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import apiFaculty from '../../utlis/apiFaculty';

const Leaves = () => {
  const navigate = useNavigate();
  const [leaves, setLeaves] = useState([]);
  const [date, setDate] = useState(null);

  useEffect(() => {
    getLeaveData();
  }, []);

  const getLeaveData = async () => {
    try {
      const response = await apiFaculty.get(`${config.apiUrl}/admin/faculty/get/leaves`);

      if(response?.data?.status === 'success') {
        setLeaves(response?.data?.leaves);
      }

    } catch(error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeLeaveDate = (event) => {
    setDate(event.target.value);
  };

  const saveLeave = async () => {
    try {
      if(!date) {
        alert('Please select a date');
      }
      else {
        const response = await apiFaculty.post(`${config.apiUrl}/admin/faculty/mark/leave`, {date: date});

        if(response?.data?.status === 'success') {
          alert('Leave marked');
          getLeaveData();
        }
      }
    } catch(error) {
      console.error('Error fetching data:', error);
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };


  return (
    <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Leaves
          </Typography>
        </Toolbar>
      </AppBar>

      <Box display="flex" justifyContent="right" alignItems='center' mb={3} style={{ marginTop: 30 }}>
        <TextField
          label="Leave Date"
          type="date"
          value={date}
          onChange={handleChangeLeaveDate}
          style={{ marginRight: 20 }}
          InputLabelProps={{ shrink: true }}
        />
        <Button type='button' variant="contained" sx={{ marginRight: 5 }} onClick={saveLeave}>
          Save
        </Button>
      </Box>

      <List>
        {leaves.map((leave, index) => (
          <div key={index}>
            
            <ListItem>
              <ListItemText primary={`Date: ${formatDate(leave)}`} />
            </ListItem>
            
            <Divider />
          </div>
        ))}
      </List>

    </Container>
  );
};

export default Leaves;
