import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Avatar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import studentApiService from '../../utlis/apiStudent';
import apiService from '../../utlis/apiService';
import { apiUrl } from '../../config';

const Profile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    profileImage: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    qualification: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pinCode: '',
  });

  const imageRef = useRef(null);

  const handleBack = () => {
    navigate(-1);
  };

  const getDetails = async () => {
    try {
        const response = await studentApiService.get('/user/get/me');
        
        if(response?.data?.status === 'success'){
          setFormData({
            profileImage: response?.data?.student?.profileImage,
            firstName: response?.data?.student?.firstName,
            lastName: response?.data?.student?.lastName,
            email: response?.data?.student?.email,
            gender: response?.data?.student?.gender,
            qualification: response?.data?.student?.qualification,
            address: response?.data?.student?.address,
            country: response?.data?.student?.country,
            state: response?.data?.student?.state,
            city: response?.data?.student?.city,
            pinCode: response?.data?.student?.pinCode,
          });
        }

    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

useEffect(() => {
    getDetails();
}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const imageUploadClick = () => {
    if(imageRef.current){
      imageRef.current.click();
    }
  }

  const handleFileChange = async (e) => {
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
  
      const response = await apiService.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        setFormData((prevData) => ({
          ...prevData,
          profileImage: response?.data?.fileUrl,
        }));
      } else {
        console.error('Failed to upload file on the server.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await studentApiService.post('/user/update/me', formData, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if(response?.data?.status === 'success'){
        setFormData(response?.data?.student);
      }
    } catch (error) {
      alert(error?.response?.data?.error);
      console.error(error);
    }
  }

  return (
    <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Profile
          </Typography>
        </Toolbar>
      </AppBar>

      <Container maxWidth={'100%'} sx={{ paddingLeft: {xs: 2, md: 30}, paddingRight: {sx: 2, md: 30} }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sx={{ marginTop: 1 }}>
              <Box component={'section'} sx={{ display:'flex',justifyContent:"center" }}>
                <Avatar alt={formData.firstName} onClick={imageUploadClick} sx={{ width: '150px', height: '150px',  }} src={`${apiUrl}/${formData.profileImage}`} />
              </Box>
              <input type='file' onChange={(e) => handleFileChange(e)} ref={imageRef} style={{display: 'none'}} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ marginTop: 5 }}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginTop: 5 }}>
                  <TextField
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ marginTop: 5 }}>
                    <TextField
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      fullWidth
                      required
                      type="email"
                      margin="normal"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ marginTop: 5 }}>
                  <FormControl fullWidth style={{ marginTop: '20px'}}>
                    <InputLabel id="Gender">Gender</InputLabel>
                    <Select
                      label="Gender"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                label="Qualification"
                name="qualification"
                value={formData.qualification}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="State"
                name="state"
                value={formData.state}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Pin code"
                name="pinCode"
                value={formData.pinCode}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                Update
            </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      
    </Container>
  );
};

export default Profile;
