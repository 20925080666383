import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import Students from './getStudents'
import Materials from './uploadMaterial'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormLabel, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // Import the advancedFormat plugin
import 'dayjs/locale/en';
import Schdule from './manageSchedule';
import ManageExam from './manageExam';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ActivityLogs from './log'
import { useUser } from '../../../context/userContext';
import { generateYearRange, getSessions } from '../../../utlis/commonFunction';

dayjs.extend(AdvancedFormat);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreatebatchForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    nameTail: '',
    branch:'',
    course:'',
    mentor:'',
    faculty:'',
    coordinator: '',
    year:'',
    paper:'',
    limit: '',
    date: null,
    endDate: null,
    status: '',
    mode: '',
    session: '',
    hours: '',
    consecutiveHours: '',
  });
  const [papers,setPapers]=useState([])
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [exams, setExams] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');

  const [checklist, setChecklist] = useState([
    { id: 'Board', checked: false },
    { id: 'Projector', checked: false },
    { id: 'TV', checked: false },
  ]);

  const nameExceptionSubjects = [
    'Business and Technology (BT)',
    'Management Accounting (MA)',
    'Financial Accounting (FA)',
    'Corporate and Business Law (LW)',
    'Financial Reporting (FR)',
    'Financial Management (FM)',
    'Taxation (TX)',
  ]

  const { permissions } = useUser();
  
  const handleChecklistChange = (index) => {
    setChecklist((prevChecklist) =>
      prevChecklist.map((item, i) => (i === index ? { ...item, checked: !item.checked } : item))
    );
  };

  const fetchbatchData = async () => {
    try {
      const response = await apiService.get(`/batch/get`, {
        params: {
          id: id,
        },
      });
  
      setFormData({ ...response?.data, student: [], exam: []});
      setSelectedStudents(response?.data?.student || []);
      setMaterials(response?.data?.materials);
      setSchedule(response?.data?.schedule);
      setExams(response?.data?.exam);
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    if (id) {
      
      fetchbatchData();
    }
  }, [id]);

  const [errors, setErrors] = useState({
    name: '',
    consecutiveHours: '',
  });

  

  const [branchs, setBranchs]=useState([]);

  const [courses, setCourses]=useState([]);

  const [mentors, setMentors]=useState([]);

  const [faculties, setFaculties]=useState([]);

  // const [classes,setClasses] = useState([])

  const [coordinator, setCoordinator] = useState([]);

  const years = generateYearRange();

  const sessions = getSessions();

  useEffect(()=>{
    getAllData()
  },[])

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('batchFormData'));
    const storedTimestamp = localStorage.getItem('batchFormDataTimestamp');

    if (storedFormData && isRecent(storedTimestamp)) {
      setFormData(storedFormData);
    }
  }, []);


  useEffect(()=>{
    if (formData.course){
      getPapersByCourse(formData.course)
    }
  },[formData.course]);

  const isRecent = (timestamp) => {
    const currentTimestamp = new Date().getTime();
    return currentTimestamp - timestamp < 50000; 
  };

  useEffect(() => {
    if(papers.length > 0) {
      const paperListItem = papers.filter(paper => paper._id === formData.paper);
      setSelectedSubject(paperListItem[0]?.name);
    }
  }, [papers]);

  const getAllData = async () => {
    try {
      const [branchResponse, courseResponse, mentorResponse, facultyResponse, coordinatorResponse] = await Promise.all([
        apiService.get('/branch/list'),
        apiService.get('/course/list'),
        apiService.get('/mentor/list'),
        apiService.get('/faculty/list'),
        // apiService.get('/class/list'),
        apiService.get('/coordinator/list'),
      ]);
  
      const branch = branchResponse?.data?.branchs;
      const course = courseResponse?.data?.course;
      const mentor = mentorResponse?.data?.mentor;
      const faculty = facultyResponse?.data?.faculty;
      // const classs = classResponse?.data?.classRoom;
      const coordinator = coordinatorResponse?.data?.coordinator;
  
      setBranchs(branch);
      setCourses(course);
      setMentors(mentor);
      setFaculties(faculty);
      // setClasses(classs);
      setCoordinator(coordinator);
    } catch (error) {
      console.error('Error during requests:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (nName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [nName]: value,
    }));
  }

  const handleChangeSubmit = (e, value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value?._id,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      location: '',
      consecutiveHours: '',
    };

    if(selectedStudents.length > formData.limit){
      alert('The selected student exceeds the capacity limit.');
      isValid = false;
    }

    if(!formData.hours) {
      alert('Total required hours is required.');
      isValid = false;
    }

    if(!formData.date) {
      alert('Schedule start after is required.');
      isValid = false;
    }

    if(!formData.endDate) {
      alert('Schedule end before is required.');
      isValid = false;
    }

    if(!formData.consecutiveHours) {
      alert('Consecutive Hours before is required.');
      isValid = false;
      newErrors.consecutiveHours = 'Consecutive Hours before is required';
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const apiUrl = id ? `/batch/update?id=${id}` : '/batch/create';
  
      try {
        const response = await apiService.post(apiUrl, { ...formData, materials });
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  
  };

  const onChangeStudent=(newValue)=>{
    if(newValue.length > formData.limit)
      alert('Maximum batch capacity reached!');
    else
      setSelectedStudents(newValue);
  }

  const handleChangeYear=(name, value)=>{
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleChangeList = (name, value) => {
    // alert(value?._id)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value?._id,
    }));

    if(name === 'paper') {
      setSelectedSubject(value?.name);
    }
  };

  const getPapersByCourse=async(cid)=>{
    try {
      const response = await apiService.get(`/paper/paperbycourse?id=${cid}`);
      setPapers(response.data)
    } catch (error) {
      // alert(error?.response?.data?.error);
      console.error(error);
    }
  }

  const createOnClick=(loc)=>{
      localStorage.setItem('batchFormData', JSON.stringify(formData));
      localStorage.setItem('batchFormDataTimestamp', new Date().getTime());
      navigate(`/${loc}/add`)
  }

  return (
    <div>
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
        <BackButton/>
        {`Batches`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Batch
        </Typography>
        {nameExceptionSubjects.includes(selectedSubject) && permissions.includes('updateNameTail') ? 
          <TextField
            label="Name Tail"
            name="nameTail"
            value={formData.nameTail}
            onChange={handleChange}
            fullWidth
            sx={{marginTop:'40px'}}
            margin="normal"
          />  
        : ''
        }
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="year"
          required
          options={years}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.year || null}
          onChange={(e, value) => handleChangeYear('year', value)} 
          renderInput={(params) => <TextField {...params} label="Academic Year" />}
        />
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="session"
          required
          options={sessions}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.session || null}
          onChange={(e, value) => handleChangeYear('session', value)} 
          renderInput={(params) => <TextField {...params} label="Session" />}
        />
        <div style={{textAlign:'right'}}>
        <Typography  gutterBottom style={{margin: '10px 0', fontSize:13, color:'#1876d2',cursor:'pointer', width: 'fit-content', float: 'right'}} onClick={()=>createOnClick('dashboard/branch')}>
          + create branch
        </Typography>
        </div>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="branch"
          options={branchs}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={branchs.find((option) => option._id === formData.branch) || null}
          onChange={(e, value) => handleChangeList('branch', value)} 
          renderInput={(params) => <TextField {...params} label="Branch" />}
        />

        <div style={{textAlign:'right',marginTop:10}}>
        <Typography  gutterBottom style={{margin: '10px 0', fontSize:13,color:'#1876d2',cursor:'pointer', width: 'fit-content', float: 'right'}} onClick={()=>createOnClick('dashboard/class')}>
          + create class room
        </Typography>
        </div>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="courses"
          options={courses}
          style={{marginTop:15}}
          getOptionLabel={(option) => option.name}
          value={courses.find((option) => option._id === formData.course) || null}
          onChange={(e, value) => handleChangeList('course', value)} 
          renderInput={(params) => <TextField {...params} label="Add Course" />}
        />

      {formData.course && (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="paper"
          required
          options={papers}
          style={{ marginTop: 30 }}
          getOptionLabel={(option) => option.name} 
          value={papers.find((option) => option._id === formData.paper) || null}
          onChange={(e, value) => handleChangeList('paper',value)} 
          renderInput={(params) => <TextField {...params} label="Subject" />}
        />
      )}

      <TextField
        label="Capacity"
        name="limit"
        value={formData.limit}
        onChange={handleChange}
        fullWidth
        required
        sx={{marginTop:'30px'}}
        margin="normal"
      />

      <TextField
        label="Total Required Hours"
        name="hours"
        value={formData.hours}
        onChange={handleChange}
        fullWidth
        required
        margin="normal"
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 15 }}>
          <FormLabel htmlFor='activity-time' className='text-sm' required>
            Schedule Start After
          </FormLabel>
          <MobileDatePicker
            value={dayjs(formData.date)}
            onChange={(date) => handleDateChange('date', date)}
            sx={{ marginTop: 1 }}
          />
        </FormControl>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 15 }}>
          <FormLabel htmlFor='activity-time' className='text-sm' required>
            Schedule End Before
          </FormLabel>
          <MobileDatePicker
            value={formData.endDate ? dayjs(formData.endDate) : null}
            onChange={(date) => handleDateChange('endDate', date)}
            sx={{ marginTop: 1 }}
          />
        </FormControl>
      </LocalizationProvider>

      <FormControl fullWidth sx={{ marginTop: '40px' }}>
        <InputLabel id="Select Status">Select Status</InputLabel>
        <Select
          label="Select Status"
          value={formData.status}
          onChange={handleChange}
          fullWidth
          name="status"
        >
          <MenuItem value="Upcoming">Upcoming</MenuItem>
          <MenuItem value="Open For Enrollment">Open For Enrollment</MenuItem>
          <MenuItem value="Running">Running</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginTop: '40px' }}>
        <InputLabel id="Select Mode">Select Mode</InputLabel>
        <Select
          label="Select Mode"
          value={formData.mode}
          onChange={handleChange}
          fullWidth
          name="mode"
        >
          <MenuItem value="Offline">Offline</MenuItem>
          <MenuItem value="Online">Online</MenuItem>
          <MenuItem value="Hybrid">Hybrid</MenuItem>
          <MenuItem value="Recordings">Recordings</MenuItem>
          <MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
          <MenuItem value="Cohort">Cohort</MenuItem>
        </Select>
      </FormControl>
      
      <Autocomplete
        disablePortal
        id="combo-box-faculty"
        name="faculty"
        options={faculties}
        style={{ marginTop: '40px' }}
        getOptionLabel={(option) => option.name}
        value={faculties.find((option) => option._id === formData.faculty) || null}
        onChange={(e, value) => handleChangeSubmit(e, value,'faculty')}
        renderInput={(params) => <TextField {...params} label="Faculty" />}
      />

      <TextField
        label="Consecutive Hours"
        name="consecutiveHours"
        value={formData.consecutiveHours}
        onChange={handleChange}
        fullWidth
        required
        style={{ marginTop: '40px' }}
        error={Boolean(errors.consecutiveHours)}
        helperText={errors.consecutiveHours}
        margin="dense"
      />

      <Autocomplete
        disablePortal
        id="combo-box-mentor"
        name="mentor"
        options={mentors}
        style={{ marginTop: '40px' }}
        getOptionLabel={(option) => option.name}
        value={mentors.find((option) => option._id === formData.mentor) || null}
        onChange={(e, value) => handleChangeSubmit(e, value,'mentor')}
        renderInput={(params) => <TextField {...params} label="Mentor" />}
      />

      <Autocomplete
        disablePortal
        id="combo-box-coordinator"
        name="coordinator"
        options={coordinator}
        style={{ marginTop: '40px' }}
        getOptionLabel={(option) => option.name}
        value={coordinator.find((option) => option._id === formData.coordinator) || null}
        onChange={(e, value) => handleChangeSubmit(e, value,'coordinator')}
        renderInput={(params) => <TextField {...params} label="Coordinator" />}
      />

      {(formData.paper && formData.mode && id) && <Students selectedStudents={selectedStudents} setSelectedStudents={onChangeStudent} paper={formData.paper} mode={formData.mode} batchId={id} /> }

        <Materials materials={materials} setMaterials={setMaterials}/>

        {id && <Schdule schedule={schedule} getBatch={fetchbatchData} /> }

        {/* <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
          Checklist
        </Typography>
        <List>
          {checklist.map((item, index) => (
            <Card key={item.id} variant="outlined" style={{ marginBottom: 8 }}>
            <ListItem key={item.id} disablePadding>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={item.checked}
                  tabIndex={-1}
                  disableRipple
                  onChange={() => handleChecklistChange(index)}
                  style={{marginLeft:5}}
                />
              </ListItemIcon>
              <ListItemText primary={item.id} />
            </ListItem>
            </Card>
          ))}
        </List> */}

        {(permissions.includes('readExam') && id) && (
          <ManageExam exams={exams} students={selectedStudents} batchReload={fetchbatchData} batchId={id} subject={formData.paper} />
        )}
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update batch' : 'Create batch'}
        </Button>
      </form>

      {(id && permissions.includes('readBatchActivityLogs')) && 
        <div style={{ marginTop: '2rem' }}>
          <Accordion defaultExpanded ={!id}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="log-content"
              id="log-header"
            >
              <Typography>Activity Logs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ActivityLogs id={id} />
            </AccordionDetails>
          </Accordion>
        </div>
      }

    </div>
  );
};

export default CreatebatchForm;
