import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Autocomplete, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';
import DownloadIcon from '@mui/icons-material/Download';
import { apiUrl } from '../../../config';
import { generateYearRange, getQualification } from '../../../utlis/commonFunction';
import { useUser } from '../../../context/userContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';

export default function ExportDefaultToolbar() {
    const { permissions } = useUser();
    const [data, setData] = React.useState([]);
    const [filterData, setFilterData] = React.useState({
        dateFrom: '',
        dateTo: '',
        year: '',
        course: '',
        courseStatus: '',
        category: '',
        status: '',
        qualification: '',
        city: '',
        ir: '',
        gender: '',
        progress: 'percentage',
        feeAllocation: '',
    });

    const years = generateYearRange();

    const qualifications = getQualification();

    const [courses, setCourse] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [totalResults, setTotalResults] = React.useState(0);

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const [loading, setLoading] = React.useState(false);

    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];

    React.useEffect(()=> {
        getStudentsData();
    },[filterData, paginationModel]);

    React.useEffect(()=> {
        getData();
    },[]);

    React.useEffect(()=> {
        if(filterData.course)
            getCategory();
    },[filterData.course]);

    const getData = async () => {
        try {
            const courseResponse = await apiService.get('/course/list');

            setCourse(courseResponse?.data?.course);
        } catch (error) {
            console.error(error);
        }
    }

    const getCategory = async () => {
        try {
            const categoryResponse = await apiService.post(`/level/list/categoryByCourse`, {courseId: filterData.course});
            setCategories(categoryResponse?.data?.categories);
        } catch (error) {
            console.error(error);
        }
    }

    const getStudentsData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get('/report/admission', {
                params: {
                    ...filterData,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                }
            });

            setData(response?.data?.report);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeSubmit = (e, value, name) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleChange = (name, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const exportReport = async () => {
        try {
            const response = await apiService.get('/export/report/admission', {
                params: {
                    ...filterData,
                },
            });

            if(response?.data?.status === 'success') {
                
                window.open(`${apiUrl}${response?.data?.path}`, '_blank');
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const getColumn = () => {
        const columns = [
            { field: 'date', headerName: 'Date', width: 100, valueFormatter: (params) => params.value ? new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-'},
            { field: 'year', headerName: 'Academic Year', width: 100},
            { field: 'course', headerName: 'Course', width: 150},
            { field: 'statusType', headerName: 'Course Status', width: 150},
            { field: 'category', headerName: 'Category', width: 150},
            { field: 'status', headerName: 'Admission Status', width: 150},
            { field: 'subjectCount', headerName: 'Subject Count', width: 150},
            { field: 'name', headerName: 'Name', width: 150},
            { field: 'phone', headerName: 'Phone', width: 150},
            { field: 'qualification', headerName: 'Qualification', width: 150},
            { field: 'city', headerName: 'District', width: 150},
            { field: 'ir', headerName: 'IR', width: 150},
            { field: 'gender', headerName: 'Gender', width: 150 },
            { field: 'completedBatch', headerName: 'Completed Batch', width: 150},
            { field: 'ongoingBatch', headerName: 'Ongoing Batch',width: 150},
            { field: 'academicProgress', headerName: 'Academic Progress', width: 150},
            { field: 'examProgress', headerName: 'Exam Progress', width: 150},
        ];

        if(permissions.includes('readCourseFee')) {
            columns.push({ field: 'totalFee', headerName: 'Course Fee', width: 150});
        }
        if(permissions.includes('readAdditionalFee')) {
            columns.push({ field: 'additionalFee', headerName: 'Additional Fee', width: 150});
        }
        if(permissions.includes('readAdmissionFee')) {
            columns.push({ field: 'admissionFee', headerName: 'Admission Fee', width: 150});
        }
        if(permissions.includes('readFeeCollected')) {
            columns.push({ field: 'collectedFee', headerName: 'Fee Collected', width: 150});
        }
        if(permissions.includes('readDueNotCollected')) {
            columns.push({ field: 'dueNotCollected', headerName: 'Due Not Collected', width: 150, });
        }
        if(permissions.includes('readOutstanding')) {
            columns.push({ field: 'outstanding', headerName: 'Outstanding Fee', width: 150});
        }
        if(permissions.includes('readDiscount')) {
            columns.push({ field: 'discount', headerName: 'Discount', width: 150});
        }
        if(permissions.includes('readRefund')) {
            columns.push({ field: 'refund', headerName: 'Refund', width: 150});
        }
        // if(permissions.includes('readUnallocatedFee')) {
        //     columns.push({ field: 'unallocatedFee', headerName: 'Unallocated Fee', width: 150});
        // }
        if(permissions.includes('readUnallocatedFee')) {
            columns.push({ field: 'unallocatedFee', headerName: 'Fee Allocation', width: 150});
        }

        return columns
    }

    const columns = getColumn();

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <Typography variant="h4" gutterBottom>
                ADMISSION REPORT
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-year"
                        name="year"
                        options={years}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option}
                        value={filterData.year || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'year')}
                        renderInput={(params) => <TextField {...params} label="Academic Year" size='small' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-course"
                        name="course"
                        options={courses}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={courses?.find((option) => option._id === filterData.course) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value?._id, 'course')}
                        renderInput={(params) => <TextField {...params} label="Course" size='small' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
                        <InputLabel id="CourseStatus">Course Status</InputLabel>
                        <Select
                            label="Course Status"
                            value={filterData.courseStatus}
                            onChange={(e) => handleChange('courseStatus', e.target.value)}
                            fullWidth
                            name="courseStatus"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Prime">Prime</MenuItem>
                            <MenuItem value="Prime Plus">Prime Plus</MenuItem>
                            <MenuItem value="Level">Level</MenuItem>
                            <MenuItem value="Paper">Paper</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-category"
                        name="category"
                        options={categories}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option.name}
                        value={categories.find((option) => option._id === filterData.category) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value?._id, 'category')}
                        renderInput={(params) => <TextField {...params} label="Category" size='small' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
                        <InputLabel id="AdmissionStatus">Admission Status</InputLabel>
                        <Select
                            label="Admission Status"
                            value={filterData.status}
                            onChange={(e) => handleChange('status', e.target.value)}
                            fullWidth
                            name="status"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Drop">Drop</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-qualification"
                        name="qualification"
                        options={qualifications}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option}
                        value={qualifications.find((option) => option === filterData.qualification) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'qualification')}
                        renderInput={(params) => <TextField {...params} label="Qualification" size='small' />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 2}}>
                <Grid item xs={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                value={filterData.dateFrom ? dayjs(filterData.dateFrom) : null}
                                onChange={(date) => handleChange('dateFrom', date)}
                                sx={{ marginTop: 2 }}
                                format="DD/MM/YYYY"
                                label="Date From"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth size='small'>
                            <MobileDatePicker
                                value={filterData.dateTo ? dayjs(filterData.dateTo) : null}
                                onChange={(date) => handleChange('dateTo', date)}
                                sx={{ marginTop: 2 }}
                                format="DD/MM/YYYY"
                                label="Date To"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                    textField: { size: 'small' }
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                    <Autocomplete
                        disablePortal
                        id="combo-box-district"
                        name="district"
                        options={qualifications}
                        style={{ marginTop: 15 }}
                        getOptionLabel={(option) => option}
                        value={qualifications.find((option) => option === filterData.branch) || null}
                        onChange={(e, value) => handleChangeSubmit(e, value, 'district')}
                        renderInput={(params) => <TextField {...params} label="District" size='small' />}
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
                        <InputLabel id="IR">IR</InputLabel>
                        <Select
                            label="IR"
                            value={filterData.ir}
                            onChange={(e) => handleChange('ir', e.target.value)}
                            fullWidth
                            name="ir"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
                        <InputLabel id="Gender">Gender</InputLabel>
                        <Select
                            label="gender"
                            value={filterData.gender}
                            onChange={(e) => handleChange('gender', e.target.value)}
                            fullWidth
                            name="gender"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
                        <InputLabel id="progress">Progress</InputLabel>
                        <Select
                            label="progress"
                            value={filterData.progress}
                            onChange={(e) => handleChange('progress', e.target.value)}
                            fullWidth
                            name="progress"
                            size='small'
                        >
                            <MenuItem value="percentage">Percentage</MenuItem>
                            <MenuItem value="value">Value</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth sx={{ marginTop: '15px' }} size="small">
                        <InputLabel id="FeeAllocation">Fee Allocation</InputLabel>
                        <Select
                            label="Fee Allocation"
                            value={filterData.feeAllocation}
                            onChange={(e) => handleChange('feeAllocation', e.target.value)}
                            fullWidth
                            name="feeAllocation"
                            size='small'
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="Completed">Completed</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={8}>
                    {permissions.includes('exportAdmissionReport') && (
                        <Button variant="outlined" size='small' sx={{ float: 'right' }} title="Export" onClick={exportReport}><DownloadIcon /></Button>
                    )}
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>

            ) : (
                <DataGridPro
                    getRowId={(row) => row._id}
                    rows={data}
                    disableDensitySelector
                    columns={columns}
                    pagination
                    paginationModel={paginationModel}
                    rowCount={totalResults}
                    paginationMode="server"
                    onPaginationModelChange={setPaginationModel}
                    rowsPerPageOptions={pageOption}
                />
            )}
        </div>
    );
}
