const numberToWords = (num) => {
    if (num === 0) return 'zero';
    const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const scales = ['', 'thousand', 'lakh', 'crore'];
    const numToWordsChunk = (num) => {
        if (num === 0) return '';
        let words = '';
        if (num >= 100) {
            words += ones[Math.floor(num / 100)] + ' hundred ';
            num %= 100;
        }
        if (num >= 10 && num <= 19) {
            words += teens[num - 10] + ' ';
        } else if (num >= 20) {
            words += tens[Math.floor(num / 10)] + ' ';
            num %= 10;
        } else if (num === 10) {
            words += 'ten ';
        }
        if (num > 0 && num < 10) {
            words += ones[num] + ' ';
        }
        return words.trim();
    };
    const toWords = (number) => {
        let words = '';
        let scaleIndex = 0;
        while (number > 0) {
            let chunk;
            if (scaleIndex === 0) {
                chunk = number % 1000;
                number = Math.floor(number / 1000);
            } else if (scaleIndex === 1) {
                chunk = number % 100;
                number = Math.floor(number / 100);
            } else {
                chunk = number % 100;
                number = Math.floor(number / 100);
            }
            if (chunk !== 0) {
                words = numToWordsChunk(chunk) + (scales[scaleIndex] ? ' ' + scales[scaleIndex] : '') + ' ' + words;
            }
            scaleIndex++;
        }
        return words.trim();
    };
    return toWords(num);
};
 
export default numberToWords;