import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import {
	ArrowBackIos as BackButton,
	Remove as RemoveIcon
} from '@mui/icons-material';
import {
	TextField,
	Button,
	Typography,
	Select,
	MenuItem,
	Autocomplete,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Box,
	Tab,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Grid,
	FormControl,
	InputLabel,
	Container,
	Input,
	Modal,
	FormHelperText,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MuiTelInput } from 'mui-tel-input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ActivityLogs from './log'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useUser } from '../../../context/userContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/en';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ViewAttendance from './viewAttendance';
import Receipt from '../../../utlis/receipt';
import { generateYearRange, getPaymentMethod, getQualification, getStatusType } from '../../../utlis/commonFunction';

dayjs.extend(AdvancedFormat);

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '60vw',
    maxHeight: '90vh', 
    overflowY: 'scroll',
};

const CreateStudentForm = () => {
	const { permissions } = useUser();
	let query = useQuery();
	let id = query.get('id');
	const navigate = useNavigate();
	const [batchs, setBatchs] = useState([]);
	const [courses, setCourses] = useState([]);
	const [clickBatch, setClickBatch] = useState('');
	const [batchShifitSelectedPapers, setBatchShifitSelectedPapers] = useState([]);
	const [batchShifitSelectedBatch, setBatchShifitSelectedBatch] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [selectedBatchId, setSelectedBatchId] = useState('');
	const [accountOperationTabValue, setAccountOperationTabValue] = useState(permissions.includes('manageOperations') ? 'operations00' : permissions.includes('manageAccounts') ? 'accounts00' : false);
	const [admissionTabValue, setAdmissionTabValue] = useState('admission0');
	const [categoryTabValue, setCategoryTabValue] = useState('category00');
	const [receiptModal, setReceiptModal] = useState(false);
	const [receiptData, setReceiptData] = useState({});

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '+91',
		parentNumber: '+91',
		email: '',
		whatsappNumber: '+91',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
		status: 'Active',
		gender: 'male',
		batch: [],
		mode: '',
		statusType: '',
		totalFee: 0,
		course: [],
		category: [],
		papers:[],
		admissionFeeCollected: false,
		discount: [],
		totalFeeSplit: [],
		operationStatus: false,
		accountStatus: false,
		admissionFee: '',
		admissionFeePaymentMethod: '',
		settlementDate: null,
		settlementStatus: false,
		settlementPaymentMethod: '',
		settlementDueDate: null,
		initialRegistration: false,
		accountRemark: '',
		operationRemark: '',
		registrationNumber: '',
		documents: [],
	});

	const [admission, setAdmission] = useState([
		{
			course: '',
			selectedCourse: null,
			statusType: '',
			categoryList: [], // List category based on course
			category: [],
		}
	]);

	const [selectedCategory, setSelectedCategory] = useState({});
	const [updateCategory, setUpdateCategory] = useState({});
	const [statusType, setStatusType] = useState([]);
	const [selectedStatusType, setSelectedStatusType] = useState();
	const [selectedYear, setSelectedYear] = useState('');

	const qualifications = getQualification();
	const paymentMethods = getPaymentMethod();
	const years = generateYearRange();

	const openModalHandler = () => {
		setOpenModal(true);
	};
  
	const closeModalHandler = () => {
		setOpenModal(false);
	};

  	useEffect(() => {
		if (id) {
			fetchStudentData();
		}
  	}, [id]);

	const fetchStudentData = async () => {
		try {
			const response = await apiService.get(`/student/get?id=${id}`);
			
			const admissionData = await Promise.all(
				response?.data?.admission?.map(async adm => ({
					...adm,
					selectedCourse: adm?.course,
					categoryList: await getCategories(adm?.course?._id, adm?.statusType, adm?.year),
					course: adm?.course?._id,
					category: adm?.category?.map(cat => {
						const category = {
							...cat.category,
							...cat,
						}
						
						let unallocatedFee = 0;

						if(category.feeSplit.length && category.discount.length) {
							const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
							const totalFeeSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
							const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.amount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.deductionAmount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							if(cat?.ots?.amount > 0 && cat?.ots?.received) {
								unallocatedFee = category.totalFee - cat?.ots?.amount - totalFeeSplit - totalDiscount + totalRefund + totalDeduction;
							}
							else {
								unallocatedFee = category.totalFee - totalFeeSplit - totalDiscount + totalRefund + totalDeduction;
							}
						}
						else if(category.feeSplit.length) {
							const totalFeeSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
							const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.amount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.deductionAmount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							if(cat?.ots?.amount > 0 && cat?.ots?.received) {
								unallocatedFee = category.totalFee - cat?.ots?.amount - totalFeeSplit + totalRefund + totalDeduction;
							}
							else {
								unallocatedFee = category.totalFee - totalFeeSplit + totalRefund + totalDeduction;
							}
						}
						else if(category.discount.length) {
							const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
							const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.amount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.deductionAmount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							if(cat?.ots?.amount > 0 && cat?.ots?.received) {
								unallocatedFee = category.totalFee - cat?.ots?.amount - totalDiscount + totalRefund + totalDeduction;
							}
							else {
								unallocatedFee = category.totalFee - totalDiscount + totalRefund + totalDeduction;
							}
						}
						else if(cat?.ots?.amount > 0 && cat?.ots?.received) {
							const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.amount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
								const value = parseFloat(dis.deductionAmount)
								if(!isNaN(value) && dis.type === 'Refund'){
									return sum + value;
								}
								return sum;
							}, 0);

							unallocatedFee = category.totalFee - cat?.ots?.amount + totalRefund + totalDeduction;
						}
						// else {
						// 	unallocatedFee = category.totalFee;
						// }

						if(!cat?.feeSplit?.length && !cat?.ots?.amount){
							
							const otsData = {
								amount: cat.totalFee,
								received: false,
								paidDate: null,
								dueDate: null,
								paymentMethod: '',
							}

							category.ots = otsData;
						}

						category.unallocatedFee = unallocatedFee;
						
						return category;
					})
				}))
			)
			
			setAdmission(admissionData);

			setFormData(response?.data);
			setFormData({...formData, ...response?.data});
		} catch (error) {
			console.error(error);
		}
	}
  
	useEffect(() => {
		getData();
		getCourses();
		fetchStatusTypeData();
	}, []);

	const fetchStatusTypeData = async () => {
		const statusTypeData = await getStatusType();
		setStatusType(statusTypeData);
	}

	const getCategories = async (courseId, statusType, year) => {
		try {
			const response = await apiService.post(`/level/list/categoryByCourse`, { courseId: courseId, statusType: statusType, year: year });
			return response?.data?.categories;
		} catch (error) {
			console.error(error);
		}
	};
  
	const getData = async () => {
		try {
			const response = await apiService.get('/batch/list');
			setBatchs(response?.data?.batchs);
		} catch (error) {
			console.error(error);
		}
	};

	const getCourses = async () => {
		try {
			const response = await apiService.get('/course/list');
			setCourses(response?.data?.course);
		} catch (error) {
			console.error(error);
		}
	};

	const [errors, setErrors] = useState({
		phoneNumber: '',
		parentNumber: '',
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		if(name === 'firstName' || name === 'lastName'){
			if(value && !/^[A-Za-z ]+$/.test(value)){
				alert('Accept only letters and spaces!');
			}
			else{
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		}
		else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}
		
	};

	const handlePhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			phoneNumber: value,
		}));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('student', value);
		}
	}

	const handleWhatsappNumberChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			whatsappNumber: value,
		}));

		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('whatsapp', value);
		}
	}

	const handleParentPhoneChange = (value) => {
		setFormData((prevData) => ({
			...prevData,
			parentNumber: value,
		}));
		if(validatePhoneNumber(value)){
			handlePhoneDuplicateCheck('parent', value);
		}
	}

	const handleRefundDateChange = (value) => {
		setFormData({
			...formData,
			refundDate: value,
		});
	}

	const handleChangeQualification = (e, value) => {
		setFormData((prevData) => ({
			...prevData,
			qualification: value,
		}));
	}

	const handlePhoneDuplicateCheck = async (type, number) => {
		try {
		let data = {};
		if(type === 'student'){
			data = {
				phoneNumber: number,
				id: id,
			}
		}
		else if(type === 'parent'){
			data = {
				parentNumber: number,
				id: id,
			}
		}
		else {
			data = {
				whatsappNumber: number,
				id: id,
			}
		}
		
		const response = await apiService.post(`${config.apiUrl}/student/checkStudentPhoneExist`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		});

		if(response?.data?.status === 'error' && response?.data?.studentPhone === true){
			alert(response?.data?.message);
			setFormData({
				...formData,
				phoneNumber: '+91',
			});
		}
		else if(response?.data?.status === 'error'){
			alert(response?.data?.message);
		}
		}
		catch(error) {
			console.error(error);
		}
	}

	const validatePhoneNumber = (phoneNumber) => {
		const regex = /^\+\d{1,3}\s\d{5}\s\d{5}$/;
		const regex2 = /^\+\d{1,3}\d{5}\d{5}$/;
		const regex3 = /^\+\d{1,3}\s\d{4}\s\d{4}$/;
		const regex4 = /^\+\d{1,3}\d{4}\d{4}$/;
		return regex.test(phoneNumber) || regex2.test(phoneNumber.replace(/\s/g, '')) || regex3.test(phoneNumber) || regex4.test(phoneNumber);
	};

	const validateForm = () => {
		let isValid = true;
		const newErrors = {
			phoneNumber: '',
			parentNumber: '',
		};

		if (!validatePhoneNumber(formData.phoneNumber)) {
			newErrors.phoneNumber = 'Invalid phone number';
			isValid = false;
		}

		if (!validatePhoneNumber(formData.parentNumber)) {
			newErrors.parentNumber = 'Invalid parent number';
			isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
	
		if (validateForm()) {
		// if (validateForm() && validatePaymentSpliting()) {
			let murl = id ? `${config.apiUrl}/student/update?id=${id}` : `${config.apiUrl}/student/create`;
			try {
				
				const response = await apiService.post(murl, {...formData}, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
				
				alert('student has been updated!');
				if(!id){
					navigate(`/dashboard/students/add?id=${response?.data?._id}`);
				}
				
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
		}
	};

	// const validatePaymentSpliting = () => {
	// 	let isValid = true;
	// 	if(formData?.totalFeeSplit?.length){
	// 		if(getFeeAfterSplitSum() !== 0){
	// 			isValid = false;
	// 			alert('please complete the payment spliting!');
	// 		}
	// 		let noDueDate = false;
	// 		formData.totalFeeSplit?.map((split) => {
	// 			if(split.dueDate === null){
	// 			noDueDate = true;
	// 			}
	// 		});

	// 		if(noDueDate){
	// 			isValid = false;
	// 			alert('please set due date for all fee spliting!');
	// 		}
	// 	}
	// 	return isValid;
	// }

	const handleChangeBatch = async (newValue, admissionId, categoryId) => {
		try {
			if(newValue) {
					const data = {
						batchId: newValue?._id,
						studentId: id,
						admissionId: admissionId,
						categoryId: categoryId
					}

				const response = await apiService.post(`/student/addToBatch`, data, {
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if(response?.data?.message) {
					alert(response.data.message)
				}

				fetchStudentData();
			}
		} catch (error) {
			alert(error?.response?.data?.message);
			console.error(error);
		}
	};
 
	const shiftBatch = (batchId, papers, batch) => {
		setClickBatch(batchId);
		setBatchShifitSelectedPapers(papers);
		setBatchShifitSelectedBatch(batch);
		setOpenModal(true)
	};

	const handleBatchShift = () => {
		if (selectedBatchId) {
			// Display confirmation dialog to ask user permission
			if (window.confirm('Are you sure you want to shift this student to the selected batch?')) {
				// User confirmed, proceed with batch shift
				shiftBatchServer(selectedBatchId, clickBatch);
				closeModalHandler();
			} else {
				// User canceled, do nothing
				closeModalHandler();
			}
		}
	};

	const shiftBatchServer = async (selectedBatchId, clickBatch) => {
		try {
			let formData = { studentId: id, currentBatchId: clickBatch, targetBatchId: selectedBatchId };
			const response = await apiService.post(`/student/shiftBatch`, formData, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			const updatedStudentResponse = await apiService.get(`/student/get?id=${id}`);

			closeModalHandler();
		} catch (error) {
			alert(error?.response?.data?.error);
			console.error(error);
		}
	}

	const handleinitialRegistration = (e) => {
		setFormData((prevData) => ({
			...prevData,
			initialRegistration: e.target.checked,
		}));
	}

	const handleRefundStatus = (e) => {
		setFormData((prevData) => ({
			...prevData,
			refundStatus: e.target.checked,
		}));
	}

	const printAdmissionFeeReceipt = async(index, itemIndex) => {
		const admissions = admission;
		const currentAdmission = admissions[index];
		
		const category = currentAdmission.category[itemIndex];

		const data = {
			student: id,
			paymentType: 'Admission Fee',
			paymentMethod: category.admissionFeePaymentMethod,
			admissionId: currentAdmission._id,
			categoryId: category._id,
		}

		const receiptData = await getReceipt(data);
		
		if(receiptData){

			setReceiptModal(true);
			const receiptDate = new Date(receiptData.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
			
			const Query = {
				paymentType:'Admission Fee',
				student: `${formData.firstName} ${formData.lastName}`,
				phone: formData.phoneNumber,
				method: category.admissionFeePaymentMethod,
				category: `${currentAdmission?.selectedCourse?.name} ${currentAdmission.statusType}`,
				feeAmount: category.admissionFee,
				receiptNumber: receiptData?.referenceNumber,
				date: receiptDate,
			}
			setReceiptData(Query);

			if(!currentAdmission._id || !category._id) {
				category.admissionFeePayment = receiptData._id;
				currentAdmission.category[itemIndex] = category;
				admissions[index] = currentAdmission;
				setAdmission(admissions);
			}
			
		}
		else {
			alert('Something went wrong. Please try again');
		}
	}

	const printOtsFeeReceipt = async(index, itemIndex) => {
		const admissions = admission;
		const currentAdmission = admissions[index];
		const category = currentAdmission.category[itemIndex];

		const data = {
			student: id,
			paymentType: 'Course Fee One Time Settlement',
			paymentMethod: category.ots.paymentMethod,
			admissionId: currentAdmission._id,
			categoryId: category._id,
		}

		const receiptData = await getReceipt(data);
		if(receiptData){

			setReceiptModal(true);
			const receiptDate = new Date(receiptData.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
			const paymentDueDate = new Date(category.ots.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
			
			const Query = {
				paymentType:'Course Fee One Time Settlement',
				student: `${formData.firstName} ${formData.lastName}`,
				phone: formData.phoneNumber,
				method: category.ots.paymentMethod,
				category: `${currentAdmission?.selectedCourse?.name} ${currentAdmission.statusType}`,
				feeAmount: category.ots.amount,
				paymentDueDate: paymentDueDate,
				receiptNumber: receiptData?.referenceNumber,
				date: receiptDate,
			}
			setReceiptData(Query);

			if(!currentAdmission._id || !category._id) {
				category.ots.payment = receiptData._id;
				currentAdmission.category[itemIndex] = category;
				admissions[index] = currentAdmission;
				setAdmission(admissions);
			}
		}
		else {
			alert('Something went wrong. Please try again');
		}
	}

	const printEmiFeeReceipt = async(index, itemIndex, splitIndex) => {
		const admissions = admission;
		const currentAdmission = admissions[index];
		const category = currentAdmission.category[itemIndex];
		const feeSplit = category.feeSplit[splitIndex];
		const nextFeeSplit = category.feeSplit[splitIndex + 1];

		const data = {
			student: id,
			paymentType: 'Course Fee EMI',
			paymentMethod: feeSplit.paymentMethod,
			admissionId: currentAdmission._id,
			categoryId: category._id,
			paymentId: feeSplit._id,
		}

		const receiptData = await getReceipt(data);
		// const [receiptData] = await Promise.all([getReceipt(data)]);
		
		if(receiptData){

			setReceiptModal(true);
			const receiptDate = new Date(receiptData.date).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
			const paymentDueDate = new Date(feeSplit.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
			const nextInstallment = nextFeeSplit ? nextFeeSplit.value : '';
			const installmentDueDate = nextFeeSplit ? new Date(nextFeeSplit.dueDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

			const Query = {
				paymentType:'Course Fee EMI',
				student: `${formData.firstName} ${formData.lastName}`,
				phone: formData.phoneNumber,
				method: feeSplit.paymentMethod,
				category: `${currentAdmission?.selectedCourse?.name} ${currentAdmission.statusType}`,
				feeAmount: feeSplit.value,
				paymentDueDate: paymentDueDate,
				totalPaid: parseFloat(calculatePaidFee(category)),
				balance: parseFloat(calculateBalanceFee(category) + category.unallocatedFee),
				nextInstallment: nextInstallment,
				installmentDueDate: installmentDueDate,
				receiptNumber: receiptData?.referenceNumber,
				date: receiptDate,
			}
			setReceiptData(Query);

			if(!currentAdmission._id || !category._id || !feeSplit._id) {
				feeSplit.payment = receiptData._id;
				category.feeSplit[splitIndex] = feeSplit;
				currentAdmission.category[itemIndex] = category;
				admissions[index] = currentAdmission;
				setAdmission(admissions);
			}

			// const Query = `?paymentType=Admission Fee&student=${formData.firstName} ${formData.lastName}&phone=${formData.phoneNumber}&method=${formData.admissionFeePaymentMethod}&category=${courseName} ${formData.statusType}&feeAmount=${formData.admissionFee}&receiptNumber=${receiptData?.referenceNumber}&date=${receiptDate}`;
			
			// setTimeout(() => {
			// 	window.open(`/print-receipt${Query}`, '_blank', 'rel=noopener noreferrer');
			// }, 100);
			
		}
		else {
			alert('Something went wrong. Please try again');
		}
	}

	const getReceipt = async (data) => {
		try {
			const receiptResponse = await apiService.post(`/student/print-receipt`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			if(receiptResponse?.data?.status === 'success'){
				return receiptResponse?.data?.receipt;
			}
			
		} catch(err) {
			console.error(err);
			if(err.response?.status === 400) {
				alert(err.response?.data?.message);
			}
		}
	}

	const handleAdmissionTabChange = (e, value) => {
		setAdmissionTabValue(value);
	}

	const handleCategoryTabChange = (e, value) => {
		setCategoryTabValue(value);
	}

	const handleAddAdmissionFields = () => {

		const admission = {
			year: '',
			course: '',
			selectedCourse: null,
			statusType: '',
            categoryList: [], // List category based on course
			category: [],
		}

		setAdmission((preData) => ([
			...preData, admission
		]));
	}

	const handleDropAdmission = async (admissionId, index) => {
		if(admissionId) {
			const prevData = [...admission];
			const admissions = { ...prevData[index] };
			
			const data = {
				student: id,
				course: admissions.course,
				type: 'drop',
			}

			try {
				let murl = `${config.apiUrl}/student/admission/drop/request`;
				const response = await apiService.post(murl, data, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
				
				if(response?.data?.status === 'success'){
					alert('Admission delete request has been placed.');
					
					admissions.status = 'Request';
					prevData[index] = admissions;
					setAdmission(prevData);
				}
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
		}
		else {
			const prevData = [...admission];
			prevData.splice(index, 1);

			setAdmission(prevData);
		}
	}

	const handleChangeYear = (value, index) => {
		const prevData = [...admission];
		prevData[index].year = value;
		prevData[index].yearError = '';

		prevData[index].statusType = '';
		prevData[index].categoryList = [];

		setAdmission(prevData);
		setSelectedYear(value);
	}

	const handleChangeCourse = (index, newValue) => {
		const prevData = [...admission];
		
		prevData[index].course = newValue._id;
		delete prevData[index].courseError;
		prevData[index].selectedCourse = newValue;

		setAdmission(prevData);
	}

	const handleAdmissionStatusTypeChange = async (e, index) => {
		const prevData = [...admission];
		const categoryList = await getCategories(prevData[index].course, e?.target?.value, prevData[index].year);
		
		prevData[index].statusType = e?.target?.value;
		prevData[index].statusTypeError = '';
		prevData[index].categoryList = categoryList;

		setAdmission(prevData);
		setSelectedStatusType(e?.target?.value);
	}

	const handleChangeCategory = (newValue) => {
        setSelectedCategory(newValue);
	}

	const addCategoryToAdmission = (index) => {
		const prevData = [...admission];
		const newValue = selectedCategory;

        const existingCategory = prevData[index].category.find(cat => cat._id === newValue._id);
        if(!existingCategory) {

            const selectedPapers = prevData[index].categoryList?.filter((level) => newValue._id === level._id)
            .flatMap((level) => level.papers);
            
            // const uniqueSelectedPapers = selectedPapers?.filter((paper, index, self) => index === self.findIndex((p) => p._id === paper._id));
			// const uniqueSelectedPapersId = uniqueSelectedPapers.map(paper => (paper._id));
			
            const category = {
                category: newValue,
                name: newValue.name,
				fee: newValue.fee,
				statusType: newValue.statusType,
                mode: '',
				// admissionFee: newValue.admissionFee,
				admissionFeePaymentMethod: '',
				admissionFeePaidDate: null,
				admissionFeeCollected: false,
                papers: [], //uniqueSelectedPapersId,
                totalFee: 0,
                feeSplit: [],
                discount: [],
				unallocatedFee: 0,
				ots: {
					amount: 0,
					received: false,
					paidDate: null,
					dueDate: null,
					paymentMethod: '',
				},
                accountRemark: [
					{
						remark: '',
					}
				],
                operationRemark: [
					{
						remark: '',
					}
				],
                operationStatus: false,
                accountStatus: false,
				isEdit: false,
				statusTypeError: '',
            }

			delete prevData[index].categoryError;
            prevData[index].category.push(category);
        }

        setAdmission(prevData);
	}

	const handleDeleteCategory = async (categoryId, index, itemIndex) => {

		if(categoryId) {

			const prevData = [...admission];
			const category = { ...prevData[index].category[itemIndex] };
			
			const data = {
				student: id,
				course: prevData[index].course,
				category: category?.category?._id,
				type: 'drop',
				categoryObjectId: category._id,
			}

			try {
				let murl = `${config.apiUrl}/student/category/editordrop/request`;
				const response = await apiService.post(murl, data, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
				
				if(response?.data?.status === 'success'){
					alert('Category delete request has been placed.');
					
					category.isEdit = false;
					category.status = 'Request';
					prevData[index].category[itemIndex] = category;

					setAdmission(prevData);
				}
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
		}
		else {
			const prevData = [...admission];
			prevData[index].category.splice(itemIndex, 1);

			setAdmission(prevData);
		}
	}

	// const handleRemovePaper = (paperId, index, itemIndex) => {
	// 	const removeSubject = window.confirm('Are you sure you want to remove the subject?');
		
	// 	if(removeSubject) {
	// 		const prevData = [...admission];
	// 		const category = { ...prevData[index].category[itemIndex] };

	// 		category.papers = category.papers.filter((paper) => paper._id !== paperId);
	// 		prevData[index].category[itemIndex] = category;
			
	// 		setAdmission(prevData);
	// 	}
	// };

	const getCategoryPapers = (categoryList, selectedCategory) => {
		const categoryItem = categoryList.find(category => category._id === selectedCategory);
		
		const papers = categoryItem?.papers;
		return papers;
	}

	const allSubjectSelectChange = (e, index, itemIndex, paperList) => {
		const { checked } = e.target;
		const prevData = [...admission];

		const category = { ...prevData[index].category[itemIndex] };

		const selectedPapers = paperList;
		const papers = selectedPapers?.map(paper => paper._id);
		
		if(checked) {
			category.papers = papers;
		}
		else {
			category.papers = [];
		}
		
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const allSubjectSelectedCheck = (index, itemIndex, paperList) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		const selectedPapers = paperList;
		
		const papers = selectedPapers?.map(paper => paper._id);

		return papers?.length === category?.papers?.length;
	}

	const selectedSubjectChange = (e, paperId, index, itemIndex) => {
		const { checked } = e.target;
		const prevData = [...admission];

		const category = { ...prevData[index].category[itemIndex] };
		
		if(checked) {
			category.papers.push(paperId);
		}
		else {
			const paper = category?.papers?.filter(paper => paper !== paperId);
			category.papers = paper;
		}
		
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleStatusTypeChange = (e, index, itemIndex) => {
		const { name, value } = e.target;
		const prevData = [...admission];

		const category = { ...prevData[index].category[itemIndex] };

		category[name] = value;
		delete category.statusTypeError;

		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleChangeMode = (e, index, itemIndex) => {
		const { value } = e.target;
		const prevData = [...admission];

		const category = { ...prevData[index].category[itemIndex] };

		const selectedMode = category?.fee?.find((fee) => fee.mode === value);

		let totalFee = 0;
		let unallocatedFee = category.unallocatedFee;

		if(category.mode && category.mode === 'Offline' && value === 'Online') {
			totalFee = category.totalFee;
		}
		else if(category.mode && category.mode === 'Hybrid') {
			// const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
			// totalFee = selectedMode?.value - totalDiscount;
			// unallocatedFee = selectedMode?.value - totalDiscount;
			totalFee = selectedMode?.value;
			unallocatedFee = selectedMode?.value;
		}
		else if(category.mode) {
			const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);

			totalFee = category.totalFee;
			// if(category.totalFee < (selectedMode?.value - totalDiscount)) {
			if(category.totalFee < selectedMode?.value) {
				const feeDifference = selectedMode?.value - category.totalFee;
				unallocatedFee = unallocatedFee + feeDifference;
				totalFee = selectedMode?.value
			}
		}
		else {
			totalFee = selectedMode?.value ?? 0;
			unallocatedFee = selectedMode?.value ?? 0;
		}

		let ots = {}
		
		if(category.ots) {
			if(category.ots.received) {
				ots = category.ots
			}
			else if(category.feeSplit?.length) {
				ots = {
					amount: 0,
					received: false,
					paidDate: null,
					dueDate: null,
					paymentMethod: '',
				}
			}
			else {
				ots = {
					amount: totalFee,
					received: false,
					paidDate: null,
					dueDate: null,
					paymentMethod: '',
				}
			}
		}
		else {
			if(category.feeSplit?.length) {
				ots = {
					amount: 0,
					received: false,
					paidDate: null,
					dueDate: null,
					paymentMethod: '',
				}
			}
			else {
				ots = {
					amount: totalFee,
					received: false,
					paidDate: null,
					dueDate: null,
					paymentMethod: '',
				}
			}
		}

		category.mode = value;
		delete category.modeError;

		category.admissionFee = selectedMode?.admissionFee;
				
		category.totalFee = totalFee;
		category.ots = ots;
		category.unallocatedFee = unallocatedFee;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleAccountOperationTabChange = (event, newValue) => {
		setAccountOperationTabValue(newValue);
	}

	const handleAdmissionFeeOptionChange = (e, index, itemIndex) => {
		const { name, value } = e.target;
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category[name] = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleAdmissionFeeDateChange = (date, index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.admissionFeePaidDate = date;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleAdmissionFeeStatus = (e, index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.admissionFeeCollected = e.target.checked;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleAddDiscountFields = (index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = [...category.discount, { value: '', discountType: '' }];

		category.discount = discount;
		prevData[index].category[itemIndex] = category;
		setAdmission(prevData);
	}

	const handleChangeDiscount = (event, index, itemIndex, rowIndex) => {
		let value = event.target.value;
		if(isNaN(value)){
			alert('Please enter a valid number');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = { ...category.discount[rowIndex] };

		discount.value = value;
		category.discount[rowIndex] = discount;

		if(category.ots.amount){
			const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
			
			if(!category.ots.received) {
				category.ots.amount = category.totalFee - totalDiscount;
			}
		}

		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleChangeDiscountBlur = (index, itemIndex) => {

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		
		const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
		const totalSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
		const totalPaidSplit = category.feeSplit?.reduce((sum, dis) => {
			if(!isNaN(dis.value) && dis.received) {
				return sum + dis.value;
			}
			return sum;
		}, 0);
		
		if(category.ots.amount){
			// const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
			
			if(category.ots.received) {
				const payable = category.totalFee - totalDiscount;
				
				if((category.ots.amount + totalPaidSplit) > payable) {
					const refund = category.excessOrRefund?.find(refund => refund.type === 'Refund' && refund.paid === false);
					const refundIndex = category.excessOrRefund?.findIndex(refund => refund.type === 'Refund' && refund.paid === false);
					
					if(refund) {
						const refundAmount = category.ots.amount - payable;
						refund.amount = refundAmount;
						refund.suggestedAmount = refundAmount;
						refund.deductionAmount = 0;

						category.excessOrRefund[refundIndex] = refund;
					}
					else {
						const refundAmount = (category.ots.amount + totalPaidSplit) - payable;
						const refund = {
							type: 'Refund',
							amount: refundAmount,
							suggestedAmount: refundAmount,
							deductionAmount: 0,
							paid: false,
							approvalDate: new Date(),
							paidDate: null,
							paymentMethod: null,
						}

						category.excessOrRefund.push(refund);
					}
				}
				else if(category.ots.amount < payable) {
					const excess = category.excessOrRefund?.find(ex => ex.type === 'Excess' && ex.paid === false);
					const excessIndex = category.excessOrRefund?.findIndex(ex => ex.type === 'Excess' && ex.paid === false);
					const excessAmount = payable - category.ots.amount;

					if(excess) {
						excess.amount = excessAmount;
						excess.suggestedAmount = excessAmount;
						excess.deductionAmount = 0;

						category.excessOrRefund[excessIndex] = excess;
					}
					else {
						const excess = {
							type: 'Excess',
							amount: excessAmount,
							suggestedAmount: 0,
							deductionAmount: 0,
							paid: false,
							approvalDate: new Date(),
							paidDate: null,
							paymentMethod: null,
						}

						category.excessOrRefund.push(excess);
					}
					
					category.unallocatedFee = category?.totalFee - category.ots.amount - totalSplit + excessAmount;
				}
				
			}
			else {
				category.ots.amount = category.totalFee - totalDiscount;
			}
		}

		// if((category?.ots?.amount > 0 && !category?.ots?.received) || totalSplit){
		// 	category.unallocatedFee = category.totalFee - totalDiscount - totalSplit;
		// }

		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleDiscountRequestDateChange = (date, index, itemIndex, rowIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = { ...category.discount[rowIndex] };

		discount.dueDate = date;
		category.discount[rowIndex] = discount;

		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleDiscountApprovalDateChange = (date, index, itemIndex, rowIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = { ...category.discount[rowIndex] };

		discount.paidDate = date;
		category.discount[rowIndex] = discount;

		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleChangeDiscountType = (event, index, itemIndex, rowIndex) => {
		let value = event.target.value;

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = { ...category.discount[rowIndex] };

		if(value === 'Instant Discount') {
			discount.dueDate = null;
			discount.paidDate = null;
			discount.received = false;
		}
		discount.discountType = value;
		category.discount[rowIndex] = discount;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleDiscountApprovalStatusCheckbox = (event, index, itemIndex, rowIndex) => {
		const value = event.target.checked;
	
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = { ...category.discount[rowIndex] };

		discount.received = value;
		category.discount[rowIndex] = discount;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleRemoveDiscountFields = (index, itemIndex, rowIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const discount = category.discount[rowIndex];
		category.unallocatedFee = parseFloat(category.unallocatedFee) + parseFloat(discount.value);
		category.discount.splice(rowIndex, 1);

		prevData[index].category[itemIndex] = category;
		setAdmission(prevData);
	};

	const handleConvertEMIFields = (index, itemIndex, lastFeeSplit) => {
		let dueDate = null;
		if(lastFeeSplit.length){
			const lastAllocatedDate = lastFeeSplit[lastFeeSplit?.length - 1]?.dueDate;
			if(lastAllocatedDate){
				let currentDate = new Date(lastAllocatedDate);
				currentDate.setMonth(currentDate.getMonth() + 1);
				dueDate = currentDate;
			}
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const feeSplit = [...category.feeSplit, { value: '', received: false, dueDate: dueDate, paidDate: null, paymentMethod: '' }];

		if(!category?.ots?.received && !category.feeSplit.length){
			const totalDiscount = (category?.discount === null || category?.discount?.length === 0) ? 0 : category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
			category.ots.amount = 0;
			category.unallocatedFee = category?.totalFee - totalDiscount;
		}

		category.feeSplit = feeSplit;
		prevData[index].category[itemIndex] = category;
		setAdmission(prevData);
	};

	const handleRemoveConvertEMIFields = (index, itemIndex, splitIndex) => {
		
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		const feeSplit = category.feeSplit[splitIndex];
		category.feeSplit.splice(splitIndex, 1);

		if(!category.feeSplit.length) {
			const totalDiscount = (category?.discount === null || category?.discount?.length === 0) ? 0 : category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
			
			const total = category?.totalFee - totalDiscount;

			category.ots.amount = total;
			category.unallocatedFee = 0;
			prevData[index].category[itemIndex] = category;
		}
		else {
			category.unallocatedFee = parseFloat(category.unallocatedFee) + parseFloat(feeSplit?.value);
			prevData[index].category[itemIndex] = category;
		}

		setAdmission(prevData);
	};

	const hybridFeeChange = (e, index, itemIndex) => {
		const { value } = e.target;
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		
		// let ots = category.ots;
		// if(!category.ots.received) {
			let ots = {
				amount: value,
				received: false,
				paidDate: null,
				dueDate: null,
				paymentMethod: '',
			}
		// }

		category.ots = ots;
		category.totalFee = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	// const hybridFeeChangeBlur = (e, index, itemIndex) => {
	// 	const { value } = e.target;
	// 	const prevData = [...admission];
	// 	const category = { ...prevData[index].category[itemIndex] };
		
	// 	let ots = category.ots;
	// 	if(!category.ots.received) {
	// 		ots = {
	// 			amount: value,
	// 			received: false,
	// 			paidDate: null,
	// 			dueDate: null,
	// 			paymentMethod: '',
	// 		}
	// 	}
	// 	else {
	// 		const totalDiscount = (category?.discount === null || category?.discount?.length === 0) ? 0 : category?.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
	// 		const unallocatedFee = value - category.totalFee - totalDiscount - category.ots.amount;
	// 		category.unallocatedFee = unallocatedFee;
	// 	}

	// 	category.ots = ots;
	// 	category.totalFee = value;
	// 	prevData[index].category[itemIndex] = category;

	// 	setAdmission(prevData);
	// }

	const handleChangeFeeSplitAmount = (event, index, itemIndex, splitIndex) => {
		let value = event.target.value;

		if(isNaN(value)){
			alert('Please enter a valid number');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const feeSplit = { ...category.feeSplit[splitIndex] };

		feeSplit.value = value;
		category.feeSplit[splitIndex] = feeSplit;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const handleChangeFeeSplitAmountBlur = (event, index, itemIndex, splitIndex) => {
		let value = event.target.value;

		if(isNaN(value)){
			alert('Please enter a valid number');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		
		const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
		const totalSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);

		category.unallocatedFee = category.totalFee - totalDiscount - totalSplit;

		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const handleDueDateChange = (date, index, itemIndex, splitIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const feeSplit = { ...category.feeSplit[splitIndex] };

		feeSplit.dueDate = date;
		category.feeSplit[splitIndex] = feeSplit;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handlePaidDateChange = (date, index, itemIndex, splitIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const feeSplit = { ...category.feeSplit[splitIndex] };

		feeSplit.paidDate = date;
		category.feeSplit[splitIndex] = feeSplit;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleSplitPaymentMethodChange = (event, index, itemIndex, splitIndex) => {
		const value = event.target.value;
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const feeSplit = { ...category.feeSplit[splitIndex] };

		feeSplit.paymentMethod = value;
		category.feeSplit[splitIndex] = feeSplit;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleFeeSplitChangeCheckbox = (event, index, itemIndex, splitIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const feeSplit = { ...category.feeSplit[splitIndex] };

		feeSplit.received = event.target.checked;
		feeSplit.paidDate = feeSplit.paidDate ?? new Date();
		category.feeSplit[splitIndex] = feeSplit;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const getOtsAmount = (amount, discount) => {
		const totalDiscount = (discount === null || discount?.length === 0) ? 0 : discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
		const otsAmount = amount - totalDiscount;

		return otsAmount;
	}

	const handleSettlementDueDateChange = (value, index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.ots.dueDate = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleSettlementDateChange = (value, index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.ots.paidDate = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleSettlementPaymentMethodChange = (e, index, itemIndex) => {
		const value = e.target.value;

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.ots.paymentMethod = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleSettlementStatusCheckbox = (event, index, itemIndex) => {
		const value = event.target.checked;

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.ots.received = value;
		category.ots.paidDate = category?.ots?.paidDate ?? new Date();
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleAddAdditionalChargeFields = (index, itemIndex) => {
		let dueDate = new Date();

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const additionalCharge = [...category.additionalCharge, { value: '', received: false, dueDate: dueDate, paidDate: null, paymentMethod: '' }];

		category.additionalCharge = additionalCharge;
		prevData[index].category[itemIndex] = category;
		setAdmission(prevData);
	};

	const handleRemoveAddAdditionalChargeFields = (index, itemIndex, chargeIndex) => {
		
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.additionalCharge.splice(chargeIndex, 1);

		setAdmission(prevData);
	};

	const handleChangeAdditionalChargeAmount = (event, index, itemIndex, chargeIndex) => {
		let value = event.target.value;

		if(isNaN(value)){
			alert('Please enter a valid number');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const additionalCharge = { ...category.additionalCharge[chargeIndex] };

		additionalCharge.value = value;
		category.additionalCharge[chargeIndex] = additionalCharge;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const handleAdditionalChargeDueDateChange = (date, index, itemIndex, chargeIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const additionalCharge = { ...category.additionalCharge[chargeIndex] };

		additionalCharge.dueDate = date;
		category.additionalCharge[chargeIndex] = additionalCharge;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleAdditionalChargePaidDateChange = (date, index, itemIndex, chargeIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const additionalCharge = { ...category.additionalCharge[chargeIndex] };

		additionalCharge.paidDate = date;
		category.additionalCharge[chargeIndex] = additionalCharge;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleAdditionalChargePaymentMethodChange = (event, index, itemIndex, chargeIndex) => {
		const value = event.target.value;
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const additionalCharge = { ...category.additionalCharge[chargeIndex] };

		additionalCharge.paymentMethod = value;
		category.additionalCharge[chargeIndex] = additionalCharge;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleAdditionalChargeChangeCheckbox = (event, index, itemIndex, chargeIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const additionalCharge = { ...category.additionalCharge[chargeIndex] };

		additionalCharge.received = event.target.checked;
		additionalCharge.paidDate = additionalCharge.paidDate ?? new Date();
		category.additionalCharge[chargeIndex] = additionalCharge;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const handleCategoryFieldChange = (e, index, itemIndex) => {
		const {name, value } = e.target;

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category[name] = value;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleCategoryRemarkChange = (e, index, itemIndex, remarkIndex, type) => {
		const { value } = e.target;

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		if(type === 'account') {
			const remark = { ...category.accountRemark[remarkIndex] };

			remark.remark = value;
			category.accountRemark[remarkIndex] = remark;
			prevData[index].category[itemIndex] = category;
		}
		else {
			const remark = { ...category.operationRemark[remarkIndex] };
			remark.remark = value;
			category.operationRemark[remarkIndex] = remark;
			
			prevData[index].category[itemIndex] = category;
		}

		setAdmission(prevData);
	}

	const handleAddAccountRemarkFields = (index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const remark = [...category.accountRemark, { remark: '' }];

		category.accountRemark = remark;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleRemoveAccountRemarkFields = (index, itemIndex, remarkIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.accountRemark.splice(remarkIndex, 1);

		setAdmission(prevData);
	}

	const handleAddOperationRemarkFields = (index, itemIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const remark = [...category.operationRemark, { remark: '' }];

		category.operationRemark = remark;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleRemoveOperationRemarkFields = (index, itemIndex, remarkIndex) => {
		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };

		category.operationRemark.splice(remarkIndex, 1);

		setAdmission(prevData);
	}

	const saveAdmission = async (admission, index) => {

		if(admissionValidate(admission, index)){
			delete admission.categoryList;
			delete admission.selectedCourse;
			admission.category.forEach(category => {
				const id = category.category._id;
				delete category.category;
				delete category.isEdit;
				delete category.fee;
				delete category.name;
				category.category = id;
				// category.papers = category.papers.map(paper => paper._id);
			});

			admission.student = id;

			let murl = admission?._id ? `${config.apiUrl}/student/admission/update?id=${admission?._id}` : `${config.apiUrl}/student/admission/create`;
			try {
				if(admission?._id){
					delete admission.student;
					delete admission.course;
				}

				const response = await apiService.post(murl, admission, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
				
				if(response?.data?.status === 'success'){
					alert('Admission has been saved');
					fetchStudentData();
				}
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
		}
		else {
			alert('Some fields are missing. Please complete your form!');
		}
	}

	const admissionValidate = (admissionData, index) => {
		let isValid = true;
		const prevAdmission = [...admission];

		if(!admissionData.year) {
			isValid = false;
			admissionData.yearError = 'Academic Year is required';
		}
		if(!admissionData.course) {
			isValid = false;
			admissionData.courseError = 'Course is required';
		}
		if(!admissionData.statusType) {
			isValid = false;
			admissionData.statusTypeError = 'Status Type is required';
		}
		if(!admissionData.category.length) {
			isValid = false;
			admissionData.categoryError = 'Category is required';
		}

		if(admissionData.category.length){
			const category = admissionData.category.map(category => {
				const selectedCategory = {...category};
				if(!selectedCategory.mode) {
					isValid = false;
					selectedCategory.modeError = `Mode is required for category ${selectedCategory.name}`;
				}
				if(!selectedCategory.statusType) {
					isValid = false;
					selectedCategory.statusTypeError = 'Status Type is required';
				}
				if(selectedCategory.papers.length === 0) {
					isValid = false;
					selectedCategory.papersError = 'Select at least one subject';
				}

				return selectedCategory
			});

			admissionData.category = category;
		}

		if(!isValid) {
			prevAdmission[index] = admissionData;
			setAdmission(prevAdmission);
		}

		return isValid;
	}

	const handleEditCategory = (index, itemIndex) => {
		const prevData = [...admission]
		const category = { ...prevData[index].category[itemIndex] };

		category.isEdit = !category.isEdit;

		setUpdateCategory((prevData) => ({
			...prevData,
			index,
			itemIndex,
			mode: category.mode,
			statusType: category.statusType,
		}));

		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleCancelEditCategory = (index, itemIndex) => {
		const prevData = [...admission]
		const category = { ...prevData[index].category[itemIndex] };

		category.isEdit = !category.isEdit;
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);
	}

	const handleChangeUpdateCategory = (newValue, index, itemIndex) => {
		setUpdateCategory((prevData) => ({
			...prevData,
			category: newValue,
			index,
			itemIndex
		}));
	}

	const handleStatusTypeChangeUpdate = (e, index, itemIndex) => {
		setUpdateCategory((prevData) => ({
			...prevData,
			index,
			itemIndex,
			statusType: e.target.value,
		}));
	}

	const handleChangeModeUpdate = (e, index, itemIndex) => {
		setUpdateCategory((prevData) => ({
			...prevData,
			index,
			itemIndex,
			mode: e.target.value,
		}));
	}

	const handleUpdateCategory = async (index, itemIndex) => {
		
		if(!updateCategory?.statusType || !updateCategory?.mode || !updateCategory?.category?._id) {
			alert('Please select all the fields');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		
		const data = {
			student: id,
			course: prevData[index].course,
			category: category?.category?._id,
			newCategory: updateCategory?.category?._id,
			statusType: updateCategory?.statusType,
			mode: updateCategory?.mode,
			type: 'edit',
			categoryObjectId: category._id,
		}

		category.isEdit = false;
		category.status = 'Request';
		prevData[index].category[itemIndex] = category;

		setAdmission(prevData);

		try {
			let murl = `${config.apiUrl}/student/category/editordrop/request`;
			const response = await apiService.post(murl, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			
			if(response?.data?.status === 'success'){
				alert('Category change request has been placed.');
			}
		} catch (error) {
			category.isEdit = true;
			category.status = 'Active';
			prevData[index].category[itemIndex] = category;

			setAdmission(prevData);
			alert(error?.response?.data?.error);
			console.error(error);
		}
	}

	const handleChangeRefundAmount = (event, index, itemIndex, refundIndex) => {
		
		let value = event.target.value;
		if(isNaN(value)){
			alert('Please enter a valid number');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const refund = { ...category.excessOrRefund[refundIndex] };

		refund.amount = value;
		category.excessOrRefund[refundIndex] = refund;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const handleBlurRefundAmount = (event, index, itemIndex, refundIndex) => {
		const value = event.target.value;
		if(isNaN(value)){
			alert('Please enter a valid number');
			return;
		}

		const prevData = [...admission];
		const category = { ...prevData[index].category[itemIndex] };
		const refund = { ...category.excessOrRefund[refundIndex] };

		refund.amount = value;

		if(category.status === 'Drop') {
			const paidFee = category.feeSplit?.reduce((sum, dis) => {
				if((dis.value !== '' && dis.value !== null) && !isNaN(dis.value) && dis.received) {
					return sum + parseFloat(dis.value);
				}
				return sum
			}, 0);
			refund.deductionAmount = paidFee - value;
		}
		else {
			let paidFee = category.feeSplit?.reduce((sum, dis) => {
				if((dis.value !== '' && dis.value !== null) && !isNaN(dis.value) && dis.received) {
					return sum + parseFloat(dis.value);
				}
				return sum
			}, 0);

			if(category?.ots?.amount > 0 && category?.ots?.received) {
				const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);

				paidFee += category?.ots?.amount + totalDiscount;
			}
			
			refund.deductionAmount = paidFee - category.totalFee - value;
		}

		category.excessOrRefund[refundIndex] = refund;
		
		const totalDiscount = category.discount?.reduce((sum, dis) => sum + parseFloat((dis.value === null || dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);
		const totalSplit = category.feeSplit?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);

		let otsAmount = 0;

		if(category?.ots?.amount > 0 && category?.ots?.received) {
			otsAmount = category?.ots?.amount; // ots amount = total fee - total discount already applied
		}

		const totalRefund = category.excessOrRefund?.reduce((sum, dis) => {
			const value = parseFloat(dis.amount)
			if(!isNaN(value) && dis.type === 'Refund'){
				return sum + value;
			}
			return sum;
		}, 0);

		const totalDeduction = category.excessOrRefund?.reduce((sum, dis) => {
			const value = parseFloat(dis.deductionAmount)
			if(!isNaN(value) && dis.type === 'Refund'){
				return sum + value;
			}
			return sum;
		}, 0);
		
		category.unallocatedFee = category.totalFee - totalDiscount - totalSplit - otsAmount + totalRefund + totalDeduction;

		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	};

	const handleRefundPaidDateChange = (date, index, itemIndex, refundIndex) => {
		const prevData = [...admission]
		const category = { ...prevData[index].category[itemIndex] };
		const refund = { ...category.excessOrRefund[refundIndex] };

		refund.paidDate = date;
		category.excessOrRefund[refundIndex] = refund;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleRefundPaymentMethodChange = (event, index, itemIndex, refundIndex) => {
		const value = event.target.value;
		const prevData = [...admission]
		const category = { ...prevData[index].category[itemIndex] };
		const refund = { ...category.excessOrRefund[refundIndex] };

		refund.paymentMethod = value;
		category.excessOrRefund[refundIndex] = refund;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const handleRefundChangeCheckbox = (event, index, itemIndex, refundIndex) => {
		const prevData = [...admission]
		const category = { ...prevData[index].category[itemIndex] };
		const refund = { ...category.excessOrRefund[refundIndex] };

		refund.paid = event.target.checked;
		refund.paidDate = refund.paidDate ?? new Date();
		category.excessOrRefund[refundIndex] = refund;
		prevData[index].category[itemIndex] = category;
		
		setAdmission(prevData);
	}

	const calculatePaidFee = (item) => {

		const ots = item.ots.received && item.ots.amount > 0 ? item.ots.amount : 0;
		const totalSum = item.feeSplit?.reduce((sum, payment) => {
			const value = parseFloat(payment.value)
			if(!isNaN(value) && payment.received){
				return sum + value;
			} 
			return sum;
		}, 0);

		return totalSum + ots;
	}

	const calculateBalanceFee = (item) => {

		const ots = !item.ots.received && item.ots.amount > 0 ? item.ots.amount : 0;
		const totalSum = item.feeSplit?.reduce((sum, payment) => {
			const value = parseFloat(payment.value)
			if(!isNaN(value) && !payment.received){
				return sum + value;
			} 
			return sum;
		}, 0);

		return totalSum + ots;
	}

	const addDocumentRow = () => {
		setFormData((prevData) => ({
			...prevData,
			documents: [
				...prevData.documents,
				{
					name: '',
					file: '',
				},
			]
		}));
	}

	const handleFileChange = async (index, file) => {
	  
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('studentId', id);

	  
			const response = await apiService.post('/student/documents/upload', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
	  
			if (response.status === 200) {
				fetchStudentData();
			} else {
				console.error('Failed to upload file on the server.');
			}
		} catch (error) {
		  console.error('Error uploading file:', error);
		}
	};

	const removeDocumentRow = async (index) => {
		const documents = formData.documents;
		const document = documents[index];

		if(document._id) {
			try {
				const data = {
					studentId: id,
					file: document.file,
					_id: document._id,
				}

				const response = await apiService.post('/student/documents/remove', data, {
					headers: {
						'Content-Type': 'application/json',
					},
				});
		  
				if (response.status === 200) {
					fetchStudentData();
				} else {
					console.error('Failed to upload file on the server.');
				}
			} catch (error) {
			  console.error('Error uploading file:', error);
			}
		}
		else {
			documents.splice(index, 1);

			setFormData((prevData) => ({
				...prevData,
				documents: documents
			}));
		}
	};
	
	const handleDocumentChange = (index, field, value) => {
		const documents = formData.documents;
		documents[index][field] = value;

		setFormData((prevData) => ({
			...prevData,
			documents: documents,
		}));
	}

  	return (
		<div>
			<h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
				<BackButton />
				{`Students`}
			</h1>
			<form onSubmit={handleSubmit}>
				{id ?
					<ViewAttendance studentId={id} />
				: ''}
				<Typography variant="h4" gutterBottom>
					{id ? 'Edit' : 'Create'} Student
				</Typography>
				<Accordion defaultExpanded ={!id} sx={{ marginBottom: 2 }}>
					<AccordionSummary
						expandIcon={<ArrowDownwardIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						<Typography>User Details</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TextField
							label="First Name"
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							fullWidth
							required
							margin="normal"
							disabled={false}
							size='small'
						/>
						<TextField
							label="Last Name"
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							fullWidth
							required
							margin="normal"
							size='small'
						/>
						<MuiTelInput 
							label="Phone Number"
							name="phoneNumber"
							value={formData.phoneNumber}
							onChange={handlePhoneChange}
							fullWidth
							required
							error={Boolean(errors.phoneNumber)}
							helperText={errors.phoneNumber}
							margin="normal"
							size='small'
						/>
						<MuiTelInput 
							label="Whatsapp Number"
							name="whatsappNumber"
							value={formData.whatsappNumber}
							onChange={handleWhatsappNumberChange}
							fullWidth
							required
							error={Boolean(errors.whatsappNumber)}
							helperText={errors.whatsappNumber}
							margin="normal"
							size='small'
						/>
						<MuiTelInput 
							label="Parent Number"
							name="parentNumber"
							value={formData.parentNumber}
							onChange={handleParentPhoneChange}
							fullWidth
							required
							error={Boolean(errors.parentNumber)}
							helperText={errors.parentNumber}
							margin="normal"
							size='small'
						/>
						<TextField
							label="Email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							fullWidth
							required
							type="email"
							margin="normal"
							size='small'
						/>
						<FormControl fullWidth style={{ marginTop: '20px'}} size='small'>
							<InputLabel id="Gender">Gender</InputLabel>
							<Select
								label="Gender"
								name="gender"
								value={formData.gender}
								onChange={handleChange}
								fullWidth
								margin="normal"
								size='small'
							>
								<MenuItem value="male">Male</MenuItem>
								<MenuItem value="female">Female</MenuItem>
								<MenuItem value="other">Other</MenuItem>
							</Select>
						</FormControl>
						<TextField
							label="Address"
							name="address"
							value={formData.address}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="Country"
							name="country"
							value={formData.country}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="State"
							name="state"
							value={formData.state}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="City"
							name="city"
							value={formData.city}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<TextField
							label="Pin code"
							name="pinCode"
							value={formData.pinCode}
							onChange={handleChange}
							fullWidth
							margin="normal"
							size='small'
						/>
						<FormControl fullWidth style={{ marginTop: '20px'}} size='small'>
							<InputLabel id="Status">Status</InputLabel>
							<Select
								label="Status"
								name="status"
								value={formData.status}
								onChange={handleChange}
								fullWidth
								margin="normal"
								size='small'
							>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
								<MenuItem value="Drop">Drop</MenuItem>
							</Select>
						</FormControl>

						<Autocomplete
							disablePortal
							id="combo-box-qualification"
							name="qualification"
							size='small'
							options={qualifications}
							style={{ marginTop: 20 }}
							getOptionLabel={(option) => option}
							value={qualifications.find((option) => option === formData.qualification) || null}
							onChange={(e, value) => handleChangeQualification(e, value)}
							renderInput={(params) => <TextField {...params} size='small' label="Qualification" />}
						/>

						{id &&
							<>
								{/* <Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
									Batch Enrolled
								</Typography>
								<TableContainer component={Paper} style={{ marginTop: '10px' }}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Name</TableCell>
												<TableCell>Branch</TableCell>
												<TableCell>Action</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{selectedBatchs.map((batch) => (
												<TableRow key={batch._id}>
													<TableCell>{batch.name}</TableCell>
													<TableCell>{batch?.branch?.name}</TableCell>
													<TableCell>

														<Button
															variant="outlined"
															style={{marginLeft:5}}
															onClick={() => shiftBatch(batch._id)}
														>
															Shift
														</Button>

													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer> */}

								<div style={{ marginTop: '20px', flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
									<Typography variant="h6" gutterBottom>
										Documents [{formData.documents?.length}]
									</Typography>
									<Typography gutterBottom style={{ fontSize: 13, color: '#1876d2', cursor: 'pointer' }} onClick={addDocumentRow}>
										+ Add Documents
									</Typography>
								</div>

								<TableContainer component={Paper} style={{ marginBottom: 20 }}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Document Name</TableCell>
												<TableCell>File</TableCell>
												<TableCell>Action</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{formData.documents.map((document, index) => (
												<TableRow key={index}>
													<TableCell>
														<TextField
															value={document.name}
															onChange={(e) => handleDocumentChange(index, 'name', e.target.value)}
															inputProps={{
																style: { padding: 5 },
															}}
														/>
													</TableCell>
										
													<TableCell>
														{/* Use a unique key for each FileUploadButton instance */}
														<FileUploadButton
															key={`file-upload-${index}`}
															onFileChange={(file, mindex) => handleFileChange(mindex, file)}
															selectedFile={document.file}
															mindex={index}
														/>
													</TableCell>
													<TableCell>
														<Button onClick={() => removeDocumentRow(index)} variant="outlined">
															Remove
														</Button>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</>
						}

						<Grid container spacing={2} alignItems="center">
							<Grid item xs={4}>
								<FormGroup sx={{ width: 'fit-content'}}>
									<FormControlLabel control={<Checkbox checked={formData.initialRegistration} onChange={(e) => handleinitialRegistration(e)} />} label="Initial Registration Done" />
								</FormGroup>
							</Grid>
							<Grid item xs={4}>
								<TextField
									label="Registration Number"
									name="registrationNumber"
									value={formData.registrationNumber}
									onChange={handleChange}
									fullWidth
									type='text'
									error={Boolean(errors.registrationNumber)}
									helperText={errors.registrationNumber}
									margin="normal"
									size='small'
								/>
							</Grid>
						</Grid>
						{/* <TextField
							label="Total Fee"
							name="totalFee"
							value={formData.totalFee}
							onChange={handleChange}
							fullWidth
							required
							type='number'
							error={Boolean(errors.totalFee)}
							helperText={errors.totalFee}
							margin="normal"
						/> */}

						{/* {!id && 
							<Autocomplete
								id="batch-autocomplete"
								multiple
								options={batchs}
								getOptionLabel={(option) => option.name}
								value={selectedBatchs}
								onChange={(event, newValue) => handleChangeBatch(newValue)}
								renderInput={(params) => <TextField {...params} label={'Batch *'} />}
								sx={{ marginTop: '15px' }}
								required
							/>
						} */}

						<Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
							{id ? 'Update Student' : 'Create Student'}
						</Button>
					</AccordionDetails>
				</Accordion>
			</form>
			{id ? 
				<>
					<Button
						sx={{ float: 'right', marginTop: '10px' }}
						variant="contained"
						color="primary"
						onClick={handleAddAdmissionFields}
						>
						Add New Admission
					</Button>

					<TabContext value={admissionTabValue}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '50px' }}>
							<TabList onChange={handleAdmissionTabChange} aria-label="admissions tab" variant="scrollable">
								{admission.map((row, index) => (
									<Tab label={`Admission ${index+1}`} sx={row?.status === 'Drop' ? {textDecoration: 'line-through'} : {}} value={`admission${index}`} key={index} />
								))}
							</TabList>
						</Box>
						{admission.map((row, index) => (
							<React.Fragment key={index}>
								<TabPanel value={`admission${index}`}>
									<Grid container spacing={2} alignItems="center">
										<Grid item xs={4}>
											Created At: {row.createdAt ? new Date(row.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-'}
										</Grid>
										<Grid item xs={8}>
											{row.status === 'Request' ? 
												<Button
													variant="outlined"
													type="button"
													color='error'
													disabled
													sx={{ float: 'right' }}
												>
													Pending
												</Button>
											: row.status === 'Drop' ? '' :
												<Button
													variant="outlined"
													type="button"
													color='error'
													onClick={() => handleDropAdmission(row?._id, index)}
													sx={{ float: 'right' }}
												>
													Drop
												</Button>
											}
										</Grid>
									</Grid>
									<Autocomplete
										disablePortal
										id="academicYear-box"
										name="year"
										size='small'
										required
										options={years}
										style={{ marginTop: 15 }}
										getOptionLabel={(option) => option}  
										value={selectedYear}
										onChange={(e, value) => handleChangeYear(value, index)} 
										renderInput={(params) => <TextField {...params} error={Boolean(row.yearError)} helperText={row.yearError} size='small' label="Academic Year" />}
									/>
									<Autocomplete
										id="Course-autocomplete"
										options={courses}
										getOptionLabel={(option) => option.name}
										value={row.selectedCourse}
										onChange={(event, newValue) => handleChangeCourse(index, newValue)}
										renderInput={(params) => <TextField {...params} error={Boolean(row.courseError)} helperText={row.courseError} size='small' label={'Course *'} />}
										sx={{ marginTop: '15px' }}
										required
										size='small'
									/>
									<FormControl fullWidth style={{ marginTop: '10px'}}>
										<TextField
											label="Status Type"
											select
											value={selectedStatusType}
											onChange={(e) => handleAdmissionStatusTypeChange(e, index)}
											fullWidth
											name="statusType"
											margin="normal"
											required
											size='small'
											error={Boolean(row.statusTypeError)}
											helperText={row.statusTypeError}
										>
											{statusType?.map((type, statusTypeIndex) => (
												<MenuItem key={statusTypeIndex} value={type}>{type}</MenuItem>
											))}
										</TextField>
									</FormControl>
									<Grid container spacing={2}>
										<Grid item xs={11}>
											<Autocomplete
												id="Category-autocomplete"
												options={row.categoryList}
												value={null}
												getOptionLabel={(option) => option.name}
												onChange={(event, newValue) => handleChangeCategory(newValue)}
												renderInput={(params) => <TextField {...params} error={Boolean(row.categoryError)} helperText={row.categoryError} size='small' label={'Category *'} />}
												sx={{ marginTop: '15px' }}
												size='small'
											/>
										</Grid>
										<Grid item xs={1}>
											<Button type="button" variant="contained" onClick={() => addCategoryToAdmission(index)} sx={{ marginTop: '15px' }}>Add</Button>
										</Grid>
									</Grid>
									{row?.category?.length ?
										<>
											<TabContext value={categoryTabValue}>
												<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
													<TabList onChange={handleCategoryTabChange} aria-label="Category tab" variant="scrollable">
														{row?.category?.map((item, itemIndex) => (
															<Tab label={item.name} sx={item?.status === 'Drop' ? {textDecoration: 'line-through'} : {}} value={`category${index}${itemIndex}`} key={`${index}${itemIndex}`} />
														))}
													</TabList>
												</Box>
												{row?.category?.map((item, itemIndex) => (
													<TabPanel value={`category${index}${itemIndex}`} key={`${index}${itemIndex}`}>
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={12}>
																Created At: {item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) : '-'}
															</Grid>
														</Grid>
														{item.status === 'Request' ? 
															<Grid container spacing={2} alignItems="center">
																<Grid item xs={12}>
																	<Button
																		variant="outlined"
																		type="button"
																		color='error'
																		disabled
																		sx={{ float: 'right' }}
																	>
																		Pending
																	</Button>
																</Grid>
															</Grid>
														: item?.status !== 'Drop' ?
															!item.isEdit ?
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={12}>
																		<Button
																			variant="outlined"
																			type="button"
																			color='error'
																			// onClick={() => handleRemoveCategory(item._id)}
																			onClick={() => handleDeleteCategory(item?._id, index, itemIndex)}
																			sx={{ float: 'right' }}
																		>
																			Delete
																		</Button>
																		<Button
																			variant="outlined"
																			type="button"
																			onClick={() => handleEditCategory(index, itemIndex)}
																			sx={{ float: 'right', marginRight: '10px' }}
																		>
																			Edit
																		</Button>
																	</Grid>
																</Grid>
															:
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={3}>
																		<Autocomplete
																			id="Category-autocomplete"
																			options={row.categoryList}
																			value={updateCategory.category}
																			getOptionLabel={(option) => option.name}
																			onChange={(event, newValue) => handleChangeUpdateCategory(newValue, index, itemIndex)}
																			renderInput={(params) => <TextField {...params} size='small' label={'Category *'} />}
																			sx={{ marginTop: '15px' }}
																			size='small'
																		/>
																	</Grid>
																	<Grid item xs={3}>
																		<FormControl fullWidth style={{ marginTop: '10px'}}>
																			<TextField
																				label="Status Type"
																				select
																				value={(updateCategory.statusType && updateCategory.index === index &&  updateCategory.itemIndex === itemIndex) ? updateCategory.statusType : ''}
																				onChange={(e) => handleStatusTypeChangeUpdate(e, index, itemIndex)}
																				fullWidth
																				name="statusType"
																				margin="normal"
																				required
																				size='small'
																			>
																				{statusType?.map((type, statusTypeIndex) => (
																					<MenuItem key={statusTypeIndex} value={type}>{type}</MenuItem>
																				))}
																			</TextField>
																		</FormControl>
																	</Grid>
																	<Grid item xs={3}>
																		<FormControl fullWidth style={{ marginTop: '10px'}}>
																			<TextField
																				label="Mode"
																				select
																				value={(updateCategory.mode && updateCategory.index === index &&  updateCategory.itemIndex === itemIndex) ? updateCategory.mode : ''}
																				onChange={(e) => handleChangeModeUpdate(e, index, itemIndex)}
																				fullWidth
																				name="mode"
																				margin="normal"
																				required
																				size='small'
																			>
																				<MenuItem value="Offline">Offline</MenuItem>
																				<MenuItem value="Online">Online</MenuItem>
																				<MenuItem value="Hybrid">Hybrid</MenuItem>
																				<MenuItem value="Recordings">Recordings</MenuItem>
																				<MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
																			</TextField>
																		</FormControl>
																	</Grid>
																	<Grid item xs={3}>
																		<Button
																			variant="outlined"
																			color='error'
																			type="button"
																			onClick={() => handleCancelEditCategory(index, itemIndex)}
																			sx={{ float: 'right' }}
																		>
																			Cancel
																		</Button>
																		<Button
																			variant="outlined"
																			type="button"
																			onClick={() => handleUpdateCategory(index, itemIndex)}
																			sx={{ float: 'right', marginRight: '10px' }}
																		>
																			Request
																		</Button>
																	</Grid>
																</Grid>
														: ''}
														
														<Accordion defaultExpanded ={false} sx={{ marginBottom: 2, marginTop: 2 }}>
															<AccordionSummary
																expandIcon={<ArrowDownwardIcon />}
																aria-controls="panel1-content"
																id="panel1-header"
															>
																<Typography>Selected Subjects</Typography>
															</AccordionSummary>
															<AccordionDetails>
																<TableContainer component={Paper} style={{ marginTop: '10px', marginBottom: '10px' }}>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<TableCell>
																				<FormControlLabel control={<Checkbox checked={allSubjectSelectedCheck(index, itemIndex, item?.category?.papers)} onChange={(e) => allSubjectSelectChange(e, index, itemIndex, item?.category?.papers)} />} />
																					Action
																				</TableCell>
																				<TableCell>Name</TableCell>
																				{/* <TableCell>Action</TableCell> */}
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{item?.category?.papers?.map((paper) => (
																				<TableRow key={paper._id}>
																					<TableCell>
																						<FormGroup sx={{ width: 'fit-content'}}>
																							<FormControlLabel control={<Checkbox checked={item?.papers?.includes(paper?._id)} onChange={(e) => selectedSubjectChange(e, paper._id, index, itemIndex)} />} />
																						</FormGroup>
																					</TableCell>
																					<TableCell>{paper.name}</TableCell>
																					{/* <TableCell>
																						<Button
																							variant="outlined"
																							onClick={() => handleRemovePaper(paper._id, index, itemIndex)}
																						>
																							Remove
																						</Button>
																					</TableCell> */}
																				</TableRow>
																			))}
																		</TableBody>
																	</Table>
																</TableContainer>
															</AccordionDetails>
														</Accordion>

														{item.papersError ?
															<FormHelperText error={Boolean(item.papersError)}>{item.papersError}</FormHelperText>
														: ''}
														
														<Autocomplete
															id="batch-autocomplete"
															options={batchs.filter((batch) => item?.papers?.includes(batch.paper))}
															getOptionLabel={(option) => option.name}
															onChange={(event, newBatch) => handleChangeBatch(newBatch, row?._id, item?._id)}
															renderInput={(params) => <TextField {...params} label={'Batch'} size='small' />}
															sx={{ marginTop: '15px' }}
															size='small'
														/>
														
														<Accordion defaultExpanded ={false} sx={{ marginBottom: 2, marginTop: 2 }}>
															<AccordionSummary
																expandIcon={<ArrowDownwardIcon />}
																aria-controls="panel1-content"
																id="panel1-header"
															>
																<Typography>Batch Enrolled</Typography>
															</AccordionSummary>
															<AccordionDetails>
																<TableContainer component={Paper} style={{ marginTop: '10px' }}>
																	<Table>
																		<TableHead>
																			<TableRow>
																				<TableCell>Name</TableCell>
																				<TableCell>Branch</TableCell>
																				<TableCell>Action</TableCell>
																			</TableRow>
																		</TableHead>
																		<TableBody>
																			{item?.batch?.map((batch) => (
																				<TableRow key={batch?._id}>
																					<TableCell>{batch?.name}</TableCell>
																					<TableCell>{batch?.branch?.name}</TableCell>
																					<TableCell>
																						{/* <Button
																						variant="outlined"
																						// onClick={() => handleBatchRemove(batch._id)}
																						>
																						Remove
																						</Button> */}

																						<Button
																							variant="outlined"
																							style={{marginLeft:5}}
																							onClick={() => shiftBatch(batch?._id, item?.papers, item?.batch)}
																						>
																							Shift
																						</Button>

																					</TableCell>
																				</TableRow>
																			))}
																		</TableBody>
																	</Table>
																</TableContainer>
															</AccordionDetails>
														</Accordion>

														<Autocomplete
															disablePortal
															id="academicYear-box"
															name="year"
															size='small'
															required
															options={years}
															style={{ marginTop: 15 }}
															getOptionLabel={(option) => option}  
															value={selectedYear || item?.category?.year}
															onChange={(e, value) => handleChangeYear(value, index)} 
															renderInput={(params) => <TextField {...params} size='small' label="Academic Year" />}
														/>
														<FormControl fullWidth>
															<TextField
																label="Status Type"
																select
																value={item.statusType}
																onChange={(e) => handleStatusTypeChange(e, index, itemIndex)}
																fullWidth
																name="statusType"
																margin="normal"
																error={Boolean(item.statusTypeError)}
																helperText={item.statusTypeError}
																required
																size='small'
															>
																<MenuItem value="">Select Status Type</MenuItem>
																{statusType?.map((type, index) => (
																	<MenuItem key={index} value={type}>{type}</MenuItem>
																))}
															</TextField>
														</FormControl>

														<FormControl fullWidth>
															{/* <InputLabel id="Mode">Mode *</InputLabel> */}
															<TextField
																label="Mode"
																select
																value={item.mode}
																onChange={(e) => handleChangeMode(e, index, itemIndex)}
																fullWidth
																name="mode"
																margin="normal"
																required
																size='small'
																error={Boolean(item.modeError)}
																helperText={item.modeError}
															>
																<MenuItem value="">Select Mode</MenuItem>
																<MenuItem value="Offline">Offline</MenuItem>
																<MenuItem value="Online">Online</MenuItem>
																<MenuItem value="Hybrid">Hybrid</MenuItem>
																<MenuItem value="Recordings">Recordings</MenuItem>
																<MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
															</TextField>
														</FormControl>
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={12}>
																<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
																	Total Fee: {item.status === 'Drop' ? 0 : item.totalFee}
																</Typography>
															</Grid>
														</Grid>
														<Button
															sx={{ float: 'right' }}
															variant="contained"
															color="primary"
															onClick={() => handleAddDiscountFields(index, itemIndex)}
														>
															Add Discount
														</Button>
														{item?.discount?.map((row, rowIndex) => (
															<Grid container spacing={2} alignItems="center" key={itemIndex}>
																{row?.discountType === 'Instant Discount' ?
																	<></>
																:
																	<>
																		<Grid item xs={6}>
																			<TextField
																				label="Discount"
																				type='number'
																				margin="normal"
																				name="value"
																				size='small'
																				value={row.value}
																				onChange={(e) => handleChangeDiscount(e, index, itemIndex, rowIndex)}
																				onBlur={(e) => handleChangeDiscountBlur(index, itemIndex)}
																				fullWidth
																			/>
																		</Grid>
																		<Grid item xs={5} sx={{ marginTop: '6px' }}>
																			<FormControl fullWidth>
																				<InputLabel id="Select Type">Select Type</InputLabel>
																				<Select
																					label="Select Type"
																					value={row.discountType}
																					onChange={(e) => handleChangeDiscountType(e, index, itemIndex, rowIndex)}
																					fullWidth
																					name='discountType'
																					size='small'
																				>
																				<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																				<MenuItem value="Scholarship">Scholarship</MenuItem>
																				<MenuItem value="Settlement">Settlement</MenuItem>
																				<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																				<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																				<MenuItem value="Instant Discount">Instant Discount</MenuItem>  {/* For Black amount added as discount in student calculated as paid */}
																				<MenuItem value="Other">Other</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>
																	</>
																}
																{row?.discountType === 'Instant Discount' ? '' :
																	<Grid item xs={1} sx={{ marginTop: '6px' }}>
																		<Button
																			variant="outlined"
																			color="secondary"
																			onClick={() => handleRemoveDiscountFields(index, itemIndex, rowIndex)}
																		>
																			<RemoveIcon />
																		</Button>
																	</Grid>
																}
															</Grid>
														))}
														<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
															Accounts and Operations
														</Typography>
														<TabContext value={accountOperationTabValue}>
															<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
																<TabList onChange={handleAccountOperationTabChange} aria-label="Accounts and Operations tab">
																	<Tab label="Operations" value={`operations${index}${itemIndex}`} disabled={permissions.includes('manageOperations') ? false : true} />
																	<Tab label="Accounts" value={`accounts${index}${itemIndex}`} disabled={permissions.includes('manageAccounts') ? false : true} />
																</TabList>
															</Box>
															<TabPanel value={`accounts${index}${itemIndex}`}>
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={3} sx={{ marginTop: '20px' }}>
																		<TextField
																			label="Admission Fee"
																			margin="normal"
																			name="admissionFee"
																			value={item.admissionFee}
																			onChange={(e) => handleAdmissionFeeOptionChange(e, index, itemIndex)}
																			fullWidth
																			size='small'
																		/>
																	</Grid>
																	<Grid item xs={3} sx={{ marginTop: '26px' }}>
																		<FormControl fullWidth size='small'>
																			<InputLabel id="AdmissionFeePaymentMethod">Payment Method</InputLabel>
																			<Select
																				label="Payment Method"
																				value={item.admissionFeePaymentMethod}
																				onChange={(e) => handleAdmissionFeeOptionChange(e, index, itemIndex)}
																				fullWidth
																				name='admissionFeePaymentMethod'
																				size='small'
																			>
																				{paymentMethods?.map((method, index) => (
																					<MenuItem key={index} value={method}>{method}</MenuItem>
																				))}
																			</Select>
																		</FormControl>
																	</Grid>
																	<Grid item xs={3} sx={{ marginTop: '26px'}}>
																		<LocalizationProvider dateAdapter={AdapterDayjs} locale="en" >
																			<FormControl className='gap-2 mb-8' fullWidth size='small'>
																				<MobileDatePicker
																					value={dayjs(item?.admissionFeePaidDate)}
																					onChange={(date) => handleAdmissionFeeDateChange(date, index, itemIndex)}
																					format="DD/MM/YYYY"
																					label="Payment Date"
																					slotProps={{
																						textField: { size: 'small' }
																					}}
																				/>
																			</FormControl>
																		</LocalizationProvider>
																	</Grid>
																	<Grid item xs={2} sx={{ marginTop: '26px' }}>
																		<FormGroup sx={{ width: 'fit-content'}}>
																			<FormControlLabel control={<Checkbox checked={item.admissionFeeCollected} onChange={(e) => handleAdmissionFeeStatus(e, index, itemIndex)} />} label="Admission fee collected" />
																		</FormGroup>
																	</Grid>
																	{(item.admissionFeeCollected ) &&
																		<Grid item xs={1} sx={{ marginTop: '20px' }}>
																			<Button variant="outlined" title="Print Receipt" onClick={() => printAdmissionFeeReceipt(index, itemIndex)}><LocalPrintshopIcon /></Button>
																		</Grid>
																	}
																</Grid>
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={3}>
																		<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
																			Total Fee: {item.status === 'Drop' ? 0 : item.totalFee}
																		</Typography>
																	</Grid>
																	<Grid item xs={3}>
																		<Typography variant="h6" gutterBottom>
																			Paid Fee: {parseFloat(calculatePaidFee(item))}
																		</Typography>
																	</Grid>
																	<Grid item xs={3}>
																		<Typography variant="h6" gutterBottom>
																			Balance Fee: {parseFloat(calculateBalanceFee(item))}
																		</Typography>
																	</Grid>
																	<Grid item xs={3}>
																		<Typography variant="h6" gutterBottom>
																			Unallocated Fee: {parseFloat(item.unallocatedFee)}
																		</Typography>
																	</Grid>
																</Grid>
																<Button
																	sx={{ float: 'right' }}
																	variant="contained"
																	color="primary"
																	onClick={() => handleAddDiscountFields(index, itemIndex)}
																>
																	Add Discount
																</Button>
																{item?.discount?.map((row, rowIndex) => (
																	<Grid container spacing={2} alignItems="center" key={itemIndex}>
																		{row?.discountType === 'Instant Discount' ?
																			<>
																				<Grid item xs={3}>
																					<TextField
																						label="Discount"
																						type='number'
																						margin="normal"
																						name="value"
																						value={row.value}
																						onChange={(e) => handleChangeDiscount(e, index, itemIndex, rowIndex)}
																						onBlur={(e) => handleChangeDiscountBlur(index, itemIndex)}
																						fullWidth
																						size='small'
																					/>
																				</Grid>
																				<Grid item xs={2} style={{ paddingTop: '0px' }}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth>
																							<MobileDatePicker
																								label="Request Date"
																								value={dayjs(row?.dueDate)}
																								onChange={(date) => handleDiscountRequestDateChange(date, index, itemIndex, rowIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: '24px' }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2} style={{ paddingTop: '0px' }}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth>
																							<MobileDatePicker
																								label="Approval Date"
																								value={dayjs(row?.dueDate)}
																								onChange={(date) => handleDiscountApprovalDateChange(date, index, itemIndex, rowIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: '24px' }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2} sx={{ marginTop: '6px' }}>
																					<FormControl fullWidth size='small'>
																						<InputLabel id="Select Type">Select Type</InputLabel>
																						<Select
																							label="Select Type"
																							value={row.discountType}
																							onChange={(e) => handleChangeDiscountType(e, index, itemIndex, rowIndex)}
																							fullWidth
																							name='discountType'
																							size='small'
																						>
																							<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																							<MenuItem value="Scholarship">Scholarship</MenuItem>
																							<MenuItem value="Settlement">Settlement</MenuItem>
																							<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																							<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																							<MenuItem value="Instant Discount">Instant Discount</MenuItem>  {/* For Black amount added as discount in student calculated as paid */}
																							<MenuItem value="Other">Other</MenuItem>
																						</Select>
																					</FormControl>
																				</Grid>
																				<Grid item xs={2}>
																					<Checkbox checked={row?.received} onChange={(e) => handleDiscountApprovalStatusCheckbox(e, index, itemIndex, rowIndex)} />
																				</Grid>
																			</>
																		:
																			<>
																				<Grid item xs={6}>
																					<TextField
																						label="Discount"
																						type='number'
																						margin="normal"
																						name="value"
																						value={row.value}
																						onChange={(e) => handleChangeDiscount(e, index, itemIndex, rowIndex)}
																						onBlur={(e) => handleChangeDiscountBlur(index, itemIndex)}
																						fullWidth
																						size='small'
																					/>
																				</Grid>
																				<Grid item xs={5} sx={{ marginTop: '6px' }}>
																					<FormControl fullWidth size='small'>
																						<InputLabel id="Select Type">Select Type</InputLabel>
																						<Select
																							label="Select Type"
																							value={row.discountType}
																							onChange={(e) => handleChangeDiscountType(e, index, itemIndex, rowIndex)}
																							fullWidth
																							name='discountType'
																							size='small'
																						>
																							<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																							<MenuItem value="Scholarship">Scholarship</MenuItem>
																							<MenuItem value="Settlement">Settlement</MenuItem>
																							<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																							<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																							<MenuItem value="Instant Discount">Instant Discount</MenuItem>  {/* For Black amount added as discount in student calculated as paid */}
																							<MenuItem value="Other">Other</MenuItem>
																						</Select>
																					</FormControl>
																				</Grid>
																			</>
																		}
																		<Grid item xs={1} sx={{ marginTop: '6px' }}>
																			<Button
																				variant="outlined"
																				color="secondary"
																				onClick={() => handleRemoveDiscountFields(index, itemIndex, rowIndex)}
																			>
																				<RemoveIcon />
																			</Button>
																		</Grid>
																	</Grid>
																))}
																<div>
																	<Grid container spacing={2} alignItems="center">
																		<Grid item xs={12}>
																			{ item?.unallocatedFee === 0 && item?.ots?.received ? 
																				<Button
																					variant="contained"
																					color="primary"
																					disabled
																					sx={{ fontSize: '13px', float: 'right' }}
																				>
																					Add EMI
																				</Button> :
																				<Button
																					variant="contained"
																					color="primary"
																					sx={{ fontSize: '13px', float: 'right' }}
																					onClick={() => handleConvertEMIFields(index, itemIndex, item?.feeSplit)}
																				>
																					Add EMI
																				</Button>
																			}
																		</Grid>
																	</Grid>
																	{item?.mode === 'Hybrid' ? 
																		<Grid container spacing={2} alignItems="center">
																			<Grid item xs={5}>
																				<TextField
																					label="Fee For Hybrid Mode"
																					type='number'
																					margin="normal"
																					value={item?.totalFee}
																					onChange={(e) => hybridFeeChange(e, index, itemIndex)}
																					// onBlur={(e) => hybridFeeChangeBlur(e, index, itemIndex)}
																					fullWidth
																					size='small'
																				/>
																			</Grid>
																		</Grid>
																	: ''}
																	{item?.ots?.amount ?
																		<div>
																			<InputLabel id="Settlement"><b>One Time Settlement</b></InputLabel>
																			<Grid container spacing={2} alignItems="center">
																				<Grid item xs={3}>
																					<TextField
																						label="Amount"
																						margin="normal"
																						value={item?.ots?.amount}
																						fullWidth
																						size='small'
																					/>
																				</Grid>
																				<Grid item xs={2}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth size='small'>
																							<MobileDatePicker
																								label="Due Date"
																								value={dayjs(item?.ots?.dueDate)}
																								onChange={(date) => handleSettlementDueDateChange(date, index, itemIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: 1 }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth size='small'>
																							<MobileDatePicker
																								label="Payment Date"
																								value={dayjs(item?.ots?.paidDate)}
																								onChange={(date) => handleSettlementDateChange(date, index, itemIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: 1 }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={3}>
																					<div style={{ marginTop: '10px' }}>
																						<FormControl fullWidth size='small'>
																							<InputLabel id="SettlementPaymentMethod">Payment Method</InputLabel>
																							<Select
																								label="Payment Method"
																								value={item?.ots?.paymentMethod}
																								onChange={(e) => handleSettlementPaymentMethodChange(e, index, itemIndex)}
																								fullWidth
																								name='settlementPaymentMethod'
																								size='small'
																							>
																								{paymentMethods?.map((method, index) => (
																									<MenuItem key={index} value={method}>{method}</MenuItem>
																								))}
																							</Select>
																						</FormControl>
																					</div>
																				</Grid>
																				<Grid item xs={1}>
																					<Checkbox checked={item?.ots?.received} onChange={(e) => handleSettlementStatusCheckbox(e, index, itemIndex)} />
																				</Grid>
																				{item?.ots?.received &&
																					<Grid item xs={1}>
																						<Button variant="outlined" title="Print Receipt" onClick={() => printOtsFeeReceipt(index, itemIndex)}><LocalPrintshopIcon /></Button>
																					</Grid>
																				}
																			</Grid>
																		</div>
																	: ''}
																	{item?.feeSplit && item?.feeSplit?.map((field, splitIndex) => (
																		<div key={splitIndex}>
																			<Grid container spacing={2} alignItems="center">
																				<Grid item xs={3}>
																					<TextField
																						label="Amount"
																						margin="normal"
																						value={field.value}
																						onChange={(e) => handleChangeFeeSplitAmount(e, index, itemIndex, splitIndex)}
																						onBlur={(e) => handleChangeFeeSplitAmountBlur(e, index, itemIndex, splitIndex)}
																						fullWidth
																						size='small'
																					/>
																				</Grid>
																				<Grid item xs={2}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth size='small'>
																							<MobileDatePicker
																								label="Due Date"
																								value={dayjs(field.dueDate)}
																								onChange={(date) => handleDueDateChange(date, index, itemIndex, splitIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: 1 }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth size='small'>
																							<MobileDatePicker
																								label="Payment Date"
																								value={dayjs(field.paidDate)}
																								onChange={(date) => handlePaidDateChange(date, index, itemIndex, splitIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: 1 }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2}>
																					<div style={{ marginTop: '10px' }}>
																						<FormControl fullWidth size='small'>
																							<InputLabel id={`PaymentMethod${index}`}>Payment Method</InputLabel>
																							<Select
																								label="Payment Method"
																								value={field.paymentMethod}
																								onChange={(e) => handleSplitPaymentMethodChange(e, index, itemIndex, splitIndex)}
																								fullWidth
																								name='paymentMethod'
																								size='small'
																							>
																								{paymentMethods?.map((method, index) => (
																									<MenuItem key={index} value={method}>{method}</MenuItem>
																								))}
																							</Select>
																						</FormControl>
																					</div>
																				</Grid>
																				<Grid item xs={1}>
																					<Checkbox checked={field.received} onChange={(e) => handleFeeSplitChangeCheckbox(e, index, itemIndex, splitIndex)} />
																				</Grid>
																				<Grid item xs={1}>
																					{field.received &&
																						<Button variant="outlined" title="Print Receipt" onClick={() => printEmiFeeReceipt(index, itemIndex, splitIndex)}><LocalPrintshopIcon /></Button>
																					}
																				</Grid>
																				<Grid item xs={1}>
																					<Button
																						variant="outlined"
																						color="secondary"
																						onClick={() => handleRemoveConvertEMIFields(index, itemIndex, splitIndex)}
																					>
																						<RemoveIcon />
																					</Button>
																				</Grid>
																			</Grid>
																		</div>
																	))}

																	<Grid container spacing={2} alignItems="center" sx={{ marginBottom: '10px' }}>
																		<Grid item xs={12}>
																			<Button
																				variant="contained"
																				color="primary"
																				sx={{ fontSize: '13px', float: 'right' }}
																				onClick={() => handleAddAdditionalChargeFields(index, itemIndex)}
																			>
																				Add Additional Charge
																			</Button>
																		</Grid>
																	</Grid>
																	{item?.additionalCharge?.length ?
																		<InputLabel id="Settlement"><b>Additional Charges</b></InputLabel>
																	:''}
																	{item?.additionalCharge?.length > 0 && item?.additionalCharge?.map((field, chargeIndex) => (
																		<div key={chargeIndex}>
																			<Grid container spacing={2} alignItems="center">
																				<Grid item xs={3}>
																					<TextField
																						label="Amount"
																						margin="normal"
																						value={field.value}
																						onChange={(e) => handleChangeAdditionalChargeAmount(e, index, itemIndex, chargeIndex)}
																						fullWidth
																						size='small'
																					/>
																				</Grid>
																				<Grid item xs={2}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth size='small'>
																							<MobileDatePicker
																								label="Due Date"
																								value={dayjs(field.dueDate)}
																								onChange={(date) => handleAdditionalChargeDueDateChange(date, index, itemIndex, chargeIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: 1 }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2}>
																					<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																						<FormControl className='gap-2 mb-8' fullWidth size='small'>
																							<MobileDatePicker
																								label="Payment Date"
																								value={dayjs(field.paidDate)}
																								onChange={(date) => handleAdditionalChargePaidDateChange(date, index, itemIndex, chargeIndex)}
																								format="DD/MM/YYYY"
																								sx={{ marginTop: 1 }}
																								slotProps={{
																									textField: { size: 'small' }
																								}}
																							/>
																						</FormControl>
																					</LocalizationProvider>
																				</Grid>
																				<Grid item xs={2}>
																					<div style={{ marginTop: '10px' }}>
																						<FormControl fullWidth size='small'>
																							<InputLabel id={`PaymentMethod${index}`}>Payment Method</InputLabel>
																							<Select
																								label="Payment Method"
																								value={field.paymentMethod}
																								onChange={(e) => handleAdditionalChargePaymentMethodChange(e, index, itemIndex, chargeIndex)}
																								fullWidth
																								name='paymentMethod'
																								size='small'
																							>
																								{paymentMethods?.map((method, index) => (
																									<MenuItem key={index} value={method}>{method}</MenuItem>
																								))}
																							</Select>
																						</FormControl>
																					</div>
																				</Grid>
																				<Grid item xs={1}>
																					<Checkbox checked={field.received} onChange={(e) => handleAdditionalChargeChangeCheckbox(e, index, itemIndex, chargeIndex)} />
																				</Grid>
																				<Grid item xs={1}>
																					{field.received &&
																						<Button variant="outlined" title="Print Receipt" onClick={() => printEmiFeeReceipt(index, itemIndex, chargeIndex)}><LocalPrintshopIcon /></Button>
																					}
																				</Grid>
																				<Grid item xs={1}>
																					<Button
																						variant="outlined"
																						color="secondary"
																						onClick={() => handleRemoveAddAdditionalChargeFields(index, itemIndex, chargeIndex)}
																					>
																						<RemoveIcon />
																					</Button>
																				</Grid>
																			</Grid>
																		</div>
																	))}

																	{item?.excessOrRefund?.length && item?.excessOrRefund.includes(item => item.type === 'Refund') ?
																		<InputLabel id="RefundApproval" sx={{ marginTop: '10px', bottomTop: '10px'}}><b>Refund Initiated By Approval</b></InputLabel>
																	: ''}
																	{item?.excessOrRefund?.length !== 0 ? item?.excessOrRefund?.map((field, refundIndex) => (
																		<div key={refundIndex}>
																			{field?.type === 'Refund' ?
																				<Grid container spacing={2} alignItems="center">
																					<Grid item xs={3}>
																						<InputLabel id={`RefundAmount${index}`}>Refund Amount Suggested: {field.suggestedAmount}</InputLabel>
																						<TextField
																							margin="normal"
																							label="Refund Amount"
																							value={field.amount}
																							onChange={(e) => handleChangeRefundAmount(e, index, itemIndex, refundIndex)}
																							onBlur={(e) => handleBlurRefundAmount(e, index, itemIndex, refundIndex)}
																							fullWidth
																							size='small'
																						/>
																					</Grid>
																					<Grid item xs={2}>
																						<InputLabel sx={{marginBottom: 2, marginTop: -1}} id={`DeductionMade${index}`}>Deduction made: {field.deductionAmount}</InputLabel>
																						<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																							<FormControl className='gap-2 mb-8' fullWidth size='small'>
																								<MobileDatePicker
																									label="Due Date"
																									value={dayjs(field.createdAt)}
																									format="DD/MM/YYYY"
																									sx={{ marginTop: 0 }}
																									disabled
																									slotProps={{
																										textField: { size: 'small' }
																									}}
																								/>
																							</FormControl>
																						</LocalizationProvider>
																					</Grid>
																					<Grid item xs={2}>
																						<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																							<FormControl className='gap-2 mb-8' fullWidth size='small'>
																								<MobileDatePicker
																									label="Paid Date"
																									value={dayjs(field.paidDate)}
																									onChange={(date) => handleRefundPaidDateChange(date, index, itemIndex, refundIndex)}
																									format="DD/MM/YYYY"
																									sx={{ marginTop: 4 }}
																									slotProps={{
																										textField: { size: 'small' }
																									}}
																								/>
																							</FormControl>
																						</LocalizationProvider>
																					</Grid>
																					<Grid item xs={3}>
																						<div style={{ marginTop: '30px' }}>
																							<FormControl fullWidth size='small'>
																								<InputLabel id={`PaymentMethod${index}`}>Payment Method</InputLabel>
																								<Select
																									label="Payment Method"
																									value={field.paymentMethod}
																									onChange={(e) => handleRefundPaymentMethodChange(e, index, itemIndex, refundIndex)}
																									fullWidth
																									name='paymentMethod'
																									size='small'
																								>
																									{paymentMethods?.map((method, index) => (
																										<MenuItem key={index} value={method}>{method}</MenuItem>
																									))}
																								</Select>
																							</FormControl>
																						</div>
																					</Grid>
																					<Grid item xs={2}>
																						<Checkbox checked={field.paid} onChange={(e) => handleRefundChangeCheckbox(e, index, itemIndex, refundIndex)} />
																					</Grid>
																				</Grid>
																			: ''}
																		</div>
																	)):''}
																	
																	<div>
																		<Grid container spacing={2} alignItems="center">
																			<Grid item xs={12}>
																				<Button
																					variant="contained"
																					color="primary"
																					sx={{ fontSize: '13px', float: 'right' }}
																					onClick={() => handleAddAccountRemarkFields(index, itemIndex)}
																				>
																					Add Remark
																				</Button>
																			</Grid>
																		</Grid>
																		{item?.accountRemark?.length ?
																			item?.accountRemark?.map((remark, remarkIndex) => (
																				<Grid container spacing={2} alignItems="center" key={remarkIndex}>
																					{remark?._id ? 
																						<Grid item xs={12}>
																							<p>{remark.remark}</p>
																						</Grid>
																					:
																						<>
																							<Grid item xs={11}>
																								<TextField
																									label="Remark"
																									name="remark"
																									value={remark.remark}
																									onChange={(e) => handleCategoryRemarkChange(e, index, itemIndex, remarkIndex, 'account')}
																									fullWidth
																									type="text"
																									margin="normal"
																									size='small'
																								/>
																							</Grid>
																							<Grid item xs={1}>
																								{remarkIndex !== 0 ?
																									<Button
																										variant="outlined"
																										color="secondary"
																										onClick={() => handleRemoveAccountRemarkFields(index, itemIndex, remarkIndex)}
																									>
																										<RemoveIcon />
																									</Button> : ''}
																							</Grid>
																						</>
																					}
																				</Grid>
																			))
																		: ''}
																		<Grid container spacing={2} alignItems="center">
																			<Grid item xs={12}>
																				<FormControl fullWidth sx={{ marginTop: '40px' }} size='small'>
																					<InputLabel id="Select Status">Account Status</InputLabel>
																					<Select
																						label="Account Status"
																						value={item.accountStatus}
																						onChange={(e) => handleCategoryFieldChange(e, index, itemIndex)}
																						fullWidth
																						name="accountStatus"
																						size='small'
																					>
																						<MenuItem value={true}>Active</MenuItem>
																						<MenuItem value={false}>Inactive</MenuItem>
																					</Select>
																				</FormControl>
																			</Grid>
																		</Grid>
																	</div>
																</div>
															</TabPanel>
															<TabPanel value={`operations${index}${itemIndex}`}>
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={12}>
																		<Button
																			variant="contained"
																			color="primary"
																			sx={{ fontSize: '13px', float: 'right' }}
																			onClick={() => handleAddOperationRemarkFields(index, itemIndex)}
																		>
																			Add Remark
																		</Button>
																	</Grid>
																</Grid>
																{item?.operationRemark?.length ?
																	item?.operationRemark?.map((remark, remarkIndex) => (
																		<Grid container spacing={2} alignItems="center" key={remarkIndex}>
																			{remark?._id ? 
																				<Grid item xs={12}>
																					<p>{remark.remark}</p>
																				</Grid>
																			:
																				<>
																					<Grid item xs={11}>
																						<TextField
																							label="Remark"
																							name="operationRemark"
																							value={remark.remark}
																							onChange={(e) => handleCategoryRemarkChange(e, index, itemIndex, remarkIndex, 'operation')}
																							fullWidth
																							type="text"
																							margin="normal"
																							size='small'
																						/>
																					</Grid>
																					<Grid item xs={1}>
																						{remarkIndex !== 0 ?
																							<Button
																								variant="outlined"
																								color="secondary"
																								onClick={() => handleRemoveOperationRemarkFields(index, itemIndex, remarkIndex)}
																							>
																								<RemoveIcon />
																							</Button> : ''}
																					</Grid>
																				</>
																			}
																		</Grid>
																	))
																: ''}
																<FormGroup>
																	<FormControl fullWidth sx={{ marginTop: '40px' }}>
																		<InputLabel id="Select Status">Operation Status</InputLabel>
																		<Select
																			label="Operation Status"
																			value={item.operationStatus}
																			onChange={(e) => handleCategoryFieldChange(e, index, itemIndex)}
																			fullWidth
																			name="operationStatus"
																			size='small'
																		>
																			<MenuItem value={true}>Active</MenuItem>
																			<MenuItem value={false}>Inactive</MenuItem>
																		</Select>
																	</FormControl>
																</FormGroup>
															</TabPanel>
														</TabContext>
													</TabPanel>
												))}
											</TabContext>
										</>
									: ''}
									<Button type="button" onClick={() => saveAdmission(row, index)} variant="contained" color="primary" style={{ marginTop: '20px' }}>
										Save Admission {index + 1}
									</Button>
								</TabPanel>
							</React.Fragment>
						))}
					</TabContext>
					{(id && permissions.includes('readStudentActivityLogs')) && 
						<div style={{ marginTop: '2rem' }}>
							<Accordion defaultExpanded ={!id}>
								<AccordionSummary
									expandIcon={<ArrowDownwardIcon />}
									aria-controls="panel1-content"
									id="panel1-header"
								>
									<Typography>Activity Logs</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<ActivityLogs id={id} />
								</AccordionDetails>
							</Accordion>
						</div>
					}
				</>
			: ''
			}
			<Dialog
				open={openModal}
				onClose={closeModalHandler}
			>
				<DialogTitle>Select Batch</DialogTitle>
				<DialogContent  style={{ minWidth: '300px', minHeight: '100px' }}>
					<InputLabel id="Batch">Batch</InputLabel>
					<Select
						label="Batch"
						value={selectedBatchId}
						onChange={(e) => setSelectedBatchId(e.target.value)}
						fullWidth
					>
						{batchs
							.filter((batch) => !batchShifitSelectedBatch.includes(batch._id) && batchShifitSelectedPapers.includes(batch.paper))
							.map((batch) => (
							<MenuItem key={batch._id} value={batch._id}>
								{batch.name}
							</MenuItem>
						))}
					</Select>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeModalHandler} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => handleBatchShift()}
						color="primary"
					>
						Shift
					</Button>
				</DialogActions>
			</Dialog>

			<Modal
                open={receiptModal}
                onClose={() => setReceiptModal(false)}
                aria-labelledby="modal-title"
            >
                <Box sx={{ ...style }}>
                    <Receipt {...receiptData} />
                    <Button onClick={() => { setReceiptModal(false)}} sx={{ marginTop: 2}} variant="outlined">Close</Button>
                </Box> 
            </Modal>
		</div>
	);
};

const FileUploadButton = React.memo(({ onFileChange, selectedFile, mindex }) => {
    const handleFileChange = useCallback((e) => {
		const newFile = e.target.files[0];
		onFileChange(newFile, mindex);
    }, [onFileChange, mindex]);

    return (
		<div style={{ flexDirection: 'row', display: 'flex' }}>
			<Input
				type="file"
				onChange={handleFileChange}
				style={{ display: 'none' }}
				id={`file-input-${mindex}`}
			/>
	
			{selectedFile ? (
				<Button
					variant="outlined"
					onClick={() => {
						window.open(`${selectedFile}`, '_blank');
					}}
					style={{ marginLeft: 10 }}
				>
					View
				</Button>
			) : 
				<label htmlFor={`file-input-${mindex}`}>
					<Button
						component="span"
						variant="outlined"
						startIcon={<CloudUploadIcon />}
					>
						Select
					</Button>
				</label>
			}
		</div>
    );
});

export default CreateStudentForm;
