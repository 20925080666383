import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [searchTerm, page, pageSize]);

    const navigate = useNavigate();

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const getData = async () => {
        setLoading(true); // Start loading
        try {
            const response = await apiService.get('/course/list', {
                params: {
                    searchTerm: searchTerm,
                    page: page,
                    pageSize: pageSize,
                },
            });
            setData(response?.data?.course);
            setTotalResults(response?.data?.totalResults);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Stop loading, whether success or error
        }
    };

    const deleteItem = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this item?');
        if (shouldDelete) {
            try {
                setLoading(true); // Start loading
                await apiService.post('/course/delete', null, {
                    params: {
                        id: id,
                    },
                });
                getData();
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Stop loading, whether success or error
            }
        }
    };

    const createPress = () => {
        clearLocalStorageKeysWithSubstring("FormData");
        navigate('/dashboard/course/add');
    };

    function clearLocalStorageKeysWithSubstring(substring) {
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes(substring)) {
                localStorage.removeItem(key);
            }
        }
    }

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>COURSES</Typography>
                {permissions.includes('createCourse') && (
                    <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
                        + Create
                    </Button>
                )}
            </div>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                margin="normal"
                style={{ marginBottom: 10 }}
            />
            {loading ? ( // Show loader if loading
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                            { field: 'name', headerName: 'Name', width: 200 },
                            {
                                field: 'actions',
                                headerName: 'Action',
                                width: 150,
                                renderCell: (params) => (
                                    <>
                                        {permissions.includes('updateCourse') && (
                                            <Button variant="outlined" onClick={() => navigate(`/dashboard/course/add?id=${params.row._id}`)}>Edit</Button>
                                        )}
                                        {permissions.includes('deleteCourse') && (
                                            <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                        // pagination
                        pageSize={pageSize}
                        rowCount={totalResults}
                        paginationMode="server"
                        // onPageChange={(newPage) => handlePageChange(newPage)}
                        components={{ Toolbar: GridToolbar }} // Add GridToolbar for filtering
                    />
                </div>
            )}
            <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                    Previous
                </Button>
                {page}
                <Button onClick={() => handlePageChange(page + 1)} disabled={page * pageSize >= totalResults}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default MyTable;
