import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateCourseForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
  });

  const [coursees, setCoursees] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await apiService.get('/course/list');
        setCoursees(response.data.courses);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchCourses();
  }, []);
  
  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/course/get', {
            params: {
              id: id,
            },
          });
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchCourseData();
  }, [id]);


  const [errors, setErrors] = useState({
    name: '',
    fee: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 
  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
    };

    if (!formData.name) {
      newErrors.name = 'Please select a name.';
      alert('Please select a name.');
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      let murl = id ? `${config.apiUrl}/course/update?id=${id}` : `${config.apiUrl}/course/create`;
      try {
        const response = await apiService.post(murl, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };



  return (
    <div>
      <h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
      <BackButton />
        {`courses`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Course
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
    
    
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update Course' : 'Create Course'}
        </Button>
      </form>
    </div>
  );
};

export default CreateCourseForm;
