import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

const StatusTypeList = () => {
  const { permissions } = useUser();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalResults, setTotalResults] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50,
    page: 0,
});
const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];

  useEffect(() => {
    getData();
  }, []);

  let navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await apiService.get(`/status-type/list`, {
        params: {
          searchTerm: searchTerm,
          page: paginationModel.page,
          pageSize: paginationModel.pageSize,
        }
      });
      setData(response?.data?.statusType);
      setTotalResults(response?.data?.totalResults);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is complete
    }
  };

  const deleteItem = async (id) => {
    const shouldDelete = window.confirm('Are you sure you want to delete this item?');
    if (shouldDelete) {
      try {
        await apiService.post(`/status-type/delete`, { id: id });
        getData();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const createPress = () => {
    navigate('/dashboard/status-type/add');
  };

  return (
    <div>
      <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
        <Typography variant="h4" gutterBottom>
          STATUS TYPE LIST
        </Typography>
        {permissions.includes('createStatusType') && (
          <Button variant="contained" color="primary" style={{ marginBottom: '16px' }} onClick={createPress}>
            + Create
          </Button>
        )}
      </div>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </div>
      ) : (
        <DataGridPro
          getRowId={(row) => row._id}
          rows={data}
          disableDensitySelector
          columns={[
              { field: 'name', headerName: 'Name', flex: 1 },
              {
                field: 'actions',
                headerName: 'Action',
                flex: 1,
                renderCell: (params) => (
                  <>
                    {permissions.includes('updateStatusType') && (
                        <Button variant="outlined" style={{ marginLeft: 5 }} onClick={() => navigate(`/dashboard/status-type/add?id=${params.row._id}`)}>Edit</Button>
                    )}
                    {permissions.includes('deleteStatusType') && (
                        <Button variant="outlined" color="error" style={{ marginLeft: 5 }} onClick={() => deleteItem(params.row._id)}>Delete</Button>
                    )}
                  </>
                ),
              },
          ]}
          pagination
          paginationModel={paginationModel}
          rowCount={totalResults}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={pageOption}
        />
      )}
    </div>
  );
};

export default StatusTypeList;
