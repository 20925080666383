import Logo from '../logo.png';
import { Document, Page, Text, View, Image, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import numberToWords from './numberToWords';

const Receipt = React.memo(({receiptNumber, date, paymentDueDate, paymentType, student, phone, address, method, category, feeAmount, totalPaid, balance, nextInstallment, installmentDueDate}) => {

  const styles = StyleSheet.create({
    container: {
      marginTop: '20px',
      padding: '20px',
    },
    header: {
      color: '#0C54A0',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerImageWraper: {
      width: '30%',
    },
    headerTextWraper: {
      fontSize: '12px',
      paddingLeft: '10px',
      borderLeft: '1px solid #b1b1b1',
    },
    mainSection: {
      marginTop: '30px',
      fontSize: '12px',
    },
    titleSection: {
      textAlign: 'center',
      fontSize: '16px',
      textTransform: 'uppercase',
      fontWeight: 600,
      marginBottom: '20px',
      paddingBottom: '10px',
      borderBottom: '1px solid #b1b1b1',
    },
    subSection: {
      marginRight: '20px',
      marginLeft: '20px',
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    slText: {
      color: 'red',
    },
    dateSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    detailsSection: {
      margin: '20px 0px',
      padding: '0 20px',
      letterSpacing: '2px',
      lineHeight: '1.5px',
      paddingBottom: '10px',
      borderBottom: '1px solid #b1b1b1',
    },
    row: {
      flexDirection: 'row',
      marginBottom: 5,
    },
    label: {
      width: 150,
      fontWeight: 'bold',
    },
    value: {
      flex: 1,
    },
    wordAmountWrapper: {
      textTransform: 'capitalize',
    },
    paymentSection: {
      padding: '0 20px',
      borderBottom: '1px solid #b1b1b1',
    },
    paymentSectionItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '5px',
    },
    agreeSection: {
      marginTop: '10px',
      marginRight: '20px',
      marginLeft: '20px',
    },
    bottomSection: {
      marginTop: '64px',
      marginRight: '20px',
      marginLeft: '20px',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
  });
  
  const combinedStyle = StyleSheet.create({
    value: {
      ...styles.value,
      ...styles.wordAmountWrapper,
    },
  });

  return (
    <PDFViewer style={{width: "100%", height: '100vh'}}>
      <Document>
        <Page>
          <View style={styles.container}>
            <View style={styles.header}>
              <View style={styles.headerImageWraper}><Image src={Logo} /></View>
              <View style={styles.headerTextWraper}>
                  <Text>Elance Learning Provider PVT LTD.</Text>
                  <Text>2428, 4th Floor, T2, HiLITE Business Park,</Text>
                  <Text>HiLite City, Thondayad Bypass, Kozhikode, 673 014</Text>
                  <Text>+91-7025107070 | info@elancelearning.com | https://elancelearning.com/</Text>
              </View>
            </View>
            <View style={styles.mainSection}>
              <View style={styles.titleSection}><Text>FEES RECEIPT VOUCHER</Text></View>
              <View style={styles.subSection}>
                <View><Text style={styles.slText}>Receipt No: {receiptNumber}</Text></View>
                <View style={styles.dateSection}>
                  <View><Text>Payment Date: </Text></View>
                  <View><Text>{date}</Text></View>
                </View>
              </View>
              {paymentType !== 'Admission Fee' ?
                <View style={styles.subSection}>
                  <View></View>
                  <View style={styles.dateSection}>
                    <View><Text>Due Date       : </Text></View>
                    <View><Text>{paymentDueDate}</Text></View>
                  </View>
                </View>
              : ''}
              <View style={styles.detailsSection}>
                <View style={styles.row}>
                  <Text style={styles.label}>Received from </Text>
                  <Text style={styles.value}>: {student}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>Address </Text>
                  <Text style={styles.value}>: {address}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>Amount received </Text>
                  <Text style={combinedStyle.value}>: RS. {feeAmount} ({numberToWords(feeAmount)})</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>Course </Text>
                  <Text style={styles.value}>: {category}</Text>
                </View>
                <View style={styles.row}>
                  <Text style={styles.label}>Payment Method </Text>
                  <Text style={styles.value}>: {method}</Text>
                </View>
              </View>
              {(paymentType !== 'Admission Fee') && (paymentType !== 'Course Fee One Time Settlement') ?
                <View style={styles.paymentSection}>
                  <View style={styles.paymentSectionItem}>
                    <View><Text>Total Paid: {totalPaid}</Text></View>
                    <View><Text>Balance: {balance}</Text></View>
                  </View>
                  {nextInstallment || installmentDueDate ?
                    <View style={styles.paymentSectionItem}>
                      {nextInstallment ? <View><Text>Next Installment: {nextInstallment}</Text></View> : ''}
                      {installmentDueDate ? <View><Text>Due Date: {installmentDueDate}</Text></View> : ''}
                    </View>
                  : ''}
                </View>
              : ''}
              <View style={styles.agreeSection}>
                <Text>1. This receipt is subject to realisation of cheque.</Text>
                <Text>2. This receipt should be carefully preserved and must be produced on demand.</Text>
                <Text>3. Fees once paid are not refundable/transferable in any circumstances.</Text>
              </View>
              <View style={styles.bottomSection}>
                <View><Text>Student/Parent Signature</Text></View>
                <View><Text>Authorised Signatory</Text></View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
});

export default Receipt;