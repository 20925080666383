import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TextField,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Tab,
} from '@mui/material';
import DoghNut from './dogNut';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { red, green } from '@mui/material/colors';
import config from '../../config';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const dateFormat = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const AttendanceTable = () => {
  const navigate = useNavigate();

  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);


  const [startDate, setStartDate] = useState(dateFormat(oneMonthAgo));
  const [endDate, setEndDate] = useState(dateFormat(new Date()));
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceBatch, setAttendanceBatch] = useState([]);
  const [absentCount, setAbsentCount] = useState(0);
  const [presentCount, setPresentCount] = useState(0);
  const [tabValue, setTabValue] = useState('schedule');

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    axios.get(`${config.apiUrl}/user/attendance?id=${userId}`)
      .then(response => {
        const records = response.data.attendanceRecords;
        setAttendanceData(records);
        setAttendanceBatch(response?.data?.attendanceBatch);
          // Calculate absent and present counts
          const counts = records.reduce((acc, record) => {
            record.students.forEach(student => {
              if (student.status === 'absent') {
                acc.absentCount++;
              } else if (student.status === 'present') {
                acc.presentCount++;
              }
            });
            return acc;
          }, { absentCount: 0, presentCount: 0 });
  
          setAbsentCount(counts.absentCount);
          setPresentCount(counts.presentCount);
      })
      .catch(error => {
        console.error('Error fetching attendance data:', error);
      });
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            ATTENDANCE
          </Typography>
        </Toolbar>
      </AppBar>

      <Box display="flex" justifyContent="right" mb={3} style={{ marginTop: 30 }}>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={handleChangeStartDate}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={handleChangeEndDate}
          style={{ marginLeft: 20, marginRight: 20 }}
          InputLabelProps={{ shrink: true }}
        />
      </Box>

      <Box display="flex" justifyContent="space-around" mb={3}>
        <Box style={{ textAlign: 'center' }}>
          <Typography variant="subtitle1" style={{ justifyContent: 'center', alignItems: 'center' }}>
            Total Attendance
          </Typography>
          <DoghNut data={{
            labels: ['Absent', 'Present'],
            datasets: [
                {
                label: '# of Present',
                data: [absentCount, presentCount],
                backgroundColor: [
                    red[500], 
                    green[500], 
                ],
                },
            ],
            }}/>
        </Box>
      </Box>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Acadamics tab">
            <Tab label="Schedule" value="schedule" />
            <Tab label="Batchs" value="batch" />
          </TabList>
        </Box>
        <TabPanel value="schedule">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {attendanceData.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDate(record.date)}</TableCell>
                    <TableCell>
                      {record.students.map((student, studentIndex) => (
                        <div key={studentIndex}>
                          {student.status} {student?.status == "absent" &&   `- ${student.remarks}`}
                        </div>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value="batch">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Batch</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Present</TableCell>
                  <TableCell>Absent</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {attendanceBatch.map((record, index) => (
                  <TableRow key={index}>
                    <TableCell>{record.batchName}</TableCell>
                    <TableCell>{formatDate(record.startingDate)}</TableCell>
                    <TableCell>{record.presentCount}</TableCell>
                    <TableCell>{record.absentCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </TabContext>
    </Container>
  );
};

export default AttendanceTable;
