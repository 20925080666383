import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate,useLocation } from "react-router-dom";
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateEmiSchemeForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    downPayment: '',
    tenure: '',
    additionalCharge: '',
    discount: '',
    thirdParty: false,
  });

  const [errors, setErrors] = useState({
    name: '',
    downPayment: '',
    tenure: '',
    additionalCharge: '',
    discount: '',
  });

  useEffect(() => {
    const fetchFacultyData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/emi-scheme/get', {
            params: {
              id: id,
            },
          });
          setFormData(response?.data?.emiScheme);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchFacultyData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevData) => ({
      ...prevData,
      [name]: '',
    }))
  };

  const thirdPartyOptionChange = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      thirdParty: checked,
    }));
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      downPayment: '',
      tenure: '',
      additionalCharge: '',
      discount: '',
    };

    if (!formData.name) {
      newErrors.name = 'Invalid name';
      isValid = false;
    }
    if (!formData.downPayment) {
      newErrors.downPayment = 'Invalid down payment';
      isValid = false;
    }
    else if(isNaN(formData.downPayment)) {
      newErrors.downPayment = 'Enter a valid number';
      isValid = false;
    }
    if (!formData.tenure) {
      newErrors.name = 'Invalid tenure';
      isValid = false;
    }
    else if(isNaN(formData.tenure)) {
      newErrors.tenure = 'Enter a valid number';
      isValid = false;
    }
    if(formData.additionalCharge !== '' && isNaN(formData.additionalCharge)) {
      newErrors.additionalCharge = 'Enter a valid number';
      isValid = false;
    }
    if(formData.discount !== '' && isNaN(formData.discount)) {
      newErrors.discount = 'Enter a valid number';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const apiUrl = id ? `/emi-scheme/update?id=${id}` : '/emi-scheme/create';

      try {
        const response = await apiService.post(apiUrl, formData);
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Emi Scheme`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Emi Scheme
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="dense"
          size='small'
        />
        <TextField
          label="Down Payment"
          name="downPayment"
          value={formData.downPayment}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.downPayment)}
          helperText={errors.downPayment}
          margin="dense"
          size='small'
        />
        <TextField
          label="Tenure"
          name="tenure"
          value={formData.tenure}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.tenure)}
          helperText={errors.tenure}
          margin="dense"
          size='small'
        />
        <TextField
          label="Additional Charge"
          name="additionalCharge"
          type="text"
          value={formData.additionalCharge}
          onChange={handleChange}
          fullWidth
          error={Boolean(errors.additionalCharge)}
          helperText={errors.additionalCharge}
          margin="dense"
          size='small'
        />
        <TextField
          label="Discount"
          name="discount"
          type="text"
          value={formData.discount}
          onChange={handleChange}
          error={Boolean(errors.discount)}
          helperText={errors.discount}
          fullWidth
          margin="dense"
          size='small'
        />
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox checked={formData.thirdParty} onChange={(e) => thirdPartyOptionChange(e)} />} label="Third Party Scheme" />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" size='small' style={{ marginTop: '20px' }}>
          {id ? 'Update Emi Scheme' : 'Create Emi Scheme'}
        </Button>
      </form>
    </div>
  );
};

export default CreateEmiSchemeForm;
