import { DataGridPro } from '@mui/x-data-grid-pro';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useUser } from '../../../context/userContext';
import apiService from '../../../utlis/apiService';

const ManageSchdule = (props) => {
    const { schedule, getBatch, viewMode } = props;
    const navigate = useNavigate();
    const { permissions } = useUser();

    const updateStatus = async (id, status) => {
        let murl = `/schedule/status/update`;
        try {
            const response = await apiService.post(murl, { id, status }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if(response.data.status === 'success'){
                alert('Status update!');
                getBatch();
            }
    
            console.log(JSON.stringify(response.data));
        } catch (error) {
            alert(error?.response?.data?.error);
            console.error(error);
        }
    }

    return(
        <div>
            <Typography variant="h6" gutterBottom>
                Schedule Management [{schedule?.length}]
            </Typography>
            <div style={{ height: '70vh', width: '100%' }}>
                <DataGridPro
                    rows={schedule}
                    getRowId={(row) => row._id}
                    columns={[
                        { field: 'date', headerName: 'Starting Date', width: 150, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                        { field: 'start', headerName: 'Starting Time', width: 150, valueFormatter: (params) => new Date(params.value).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) },
                        { field: 'end', headerName: 'Ending Time', width: 150, valueFormatter: (params) => new Date(params.value).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true  }) },
                        { field: 'status', headerName: 'Status', width: 150, valueGetter: (params) => params.row?.status === 'active' ? 'Active' : params.row?.status === 'completed' ? 'Completed' : 'Canceled' },
                        {
                            field: 'actions',
                            headerName: 'Action',
                            width: 600,
                            renderCell: (params) => (
                                <>
                                    {permissions.includes('updateSchedule') && !viewMode ? (
                                        <Button variant="outlined" sx={{marginRight: '10px'}} onClick={() => navigate(`/dashboard/schedule/add?id=${params.row._id}`)}>Edit</Button>
                                    ) : <Button variant="outlined" sx={{marginRight: '10px'}} disabled>Edit</Button>}
                                    {/* {permissions.includes('updateSchedule') && ( */}
                                        {!viewMode && params.row.status === 'active' ?
                                            <>
                                                <Button variant="outlined" sx={{marginRight: '10px'}} onClick={() => navigate(`/dashboard/batch/schedule/attendance?id=${params.row._id}`)}>Attendance</Button>
                                                <Button variant="outlined" sx={{marginRight: '10px'}} onClick={() => updateStatus(params.row._id, 'canceled')}>Mark As Canceled</Button>
                                                <Button variant="outlined" sx={{marginRight: '10px'}} onClick={() => updateStatus(params.row._id, 'completed')}>Mark As Completed</Button>
                                            </>
                                        : ''}
                                    {/* )} */}
                                </>
                            ),
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default ManageSchdule;