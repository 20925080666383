import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate,useLocation } from "react-router-dom";
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateFacultyForm = () => {
  let query = useQuery();
  let id = query.get('id');
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    consecutiveHours: '',
    email: '',
    password: '', // New password field
  });

  useEffect(() => {
    const fetchFacultyData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/faculty/get', {
            params: {
              id: id,
            },
          });
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchFacultyData();
  }, [id]);

  const [errors, setErrors] = useState({
    phoneNumber: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber);
  };

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      phoneNumber: '',
      email: '',
      consecutiveHours: '',
    };

    if (!validatePhoneNumber(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number';
      isValid = false;
    }

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email';
      isValid = false;
    }

    if (!formData.consecutiveHours || isNaN(formData.consecutiveHours)) {
      newErrors.email = 'Invalid Consecutive Hours';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const apiUrl = id ? `/faculty/update?id=${id}` : '/faculty/create';

      try {
        const response = await apiService.post(apiUrl, formData);
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  return (
    <div>
      <h1 style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        <BackButton />
        {`Faculties`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Faculty
        </Typography>
        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="dense"
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.phoneNumber)}
          helperText={errors.phoneNumber}
          margin="dense"
        />
        <TextField
          label="Consecutive Hours"
          name="consecutiveHours"
          value={formData.consecutiveHours}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.consecutiveHours)}
          helperText={errors.consecutiveHours}
          margin="dense"
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          required
          error={Boolean(errors.email)}
          helperText={errors.email}
          margin="dense"
        />

        <TextField
          label="Password"
          name="password"
          type="password"
          required={id ? false : true}
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="dense"
        />

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update Faculty' : 'Create Faculty'}
        </Button>
      </form>
    </div>
  );
};

export default CreateFacultyForm;
