import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate,useLocation } from "react-router-dom";
import apiService from '../../../utlis/apiService';
import {
  ArrowBackIos as BackButton,
} from '@mui/icons-material';
import { Autocomplete, FormControl, FormHelperText } from '@mui/material';
import { generateYearRange } from '../../../utlis/commonFunction';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(AdvancedFormat);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateAcademicHolidayForm = () => {
  let query = useQuery();
  let id = query.get("id")
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    academicYear: '',
    date: null,
    description: '',
  });

  const years = generateYearRange();

  const [errors, setErrors] = useState({
    academicYear: '',
    date: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors(prevError => ({
      ...prevError,
      [name]: '',
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      academicYear: '',
      date: '',
      description: '',
    };

    if(!formData.academicYear) {
      isValid = false;
      newErrors.academicYear = 'Invalid academic year';
    }
    if(!formData.date) {
      isValid = false;
      newErrors.date = 'Invalid date';
    }

    setErrors(newErrors);
    return isValid;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const apiUrl = id ? `/academic-holiday/update?id=${id}` : '/academic-holiday/create';
  
      try {
        const response = await apiService.post(apiUrl, formData);
        navigate(-1);
        console.log(JSON.stringify(response.data));
      } catch (error) {
        alert(error?.response?.data?.error);
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchaAademicHolidayData = async () => {
      try {
        if (id) {
          const response = await apiService.get('/academic-holiday/get', {
            params: {
              id: id,
            },
          });
          setFormData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchaAademicHolidayData();
  }, [id]);

  const handleChangeYear = (name, value)=>{
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors(prevError => ({
      ...prevError,
      [name]: '',
    }));
  }

  const handleDateChange = (nName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [nName]: value,
    }));

    setErrors(prevError => ({
      ...prevError,
      [nName]: '',
    }));
  }

  return (
    <div>
      <h1 style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
      <BackButton />
        {`Academic Holiday`}
      </h1>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" gutterBottom>
          {id ? 'Edit' : 'Create'} Academic Holiday
        </Typography>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          name="academicYear"
          required
          options={years}
          style={{ marginTop: 15 }}
          getOptionLabel={(option) => option}  
          value={formData.academicYear || null}
          onChange={(e, value) => handleChangeYear('academicYear', value)} 
          renderInput={(params) => <TextField {...params} helperText={errors.academicYear} error={Boolean(errors.academicYear)} label="Academic Year" />}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 15 }}>
            <MobileDatePicker
              label="Date"
              value={formData.date ? dayjs(formData.date) : null}
              onChange={(date) => handleDateChange('date', date)}
              sx={{ marginTop: 1 }}
              helperText={errors.date} 
              error={Boolean(errors.date)}
            />
          </FormControl>
          {errors.date? 
            <FormHelperText error={Boolean(errors.date)}>{errors.date}</FormHelperText>
          :''}
        </LocalizationProvider>
        <TextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          {id ? 'Update Academic Holiday' : 'Create Academic Holiday' }
        </Button>
  
      </form>
    </div>
  );
};

export default CreateAcademicHolidayForm;
