import axios from 'axios';
import { getAuthToken, setAuthToken } from './authUtils';
import config from '../config';

const apiService = axios.create({
  baseURL: config.apiUrl, 
});

apiService.interceptors.request.use(
  (config) => {
    const authToken = getAuthToken('faculty');
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('ftoken');
      window.location.href = '/faculty-login';
      console.error('Authentication error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default apiService;
