import React, { useEffect, useState } from 'react';
import { DataGridPro,GridToolbar } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';

const MyDataGridPro = () => {

  const [data,setData] = React.useState([])

    React.useEffect(()=>{
        getData()
    },[])

    const getData = async () => {
        try {
          const response = await apiService.get('/student/academic-report');
          console.log(response.data)
          setData(response?.data?.data)
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <div>
        <Typography variant="h4" gutterBottom>
            ACADEMICS REPORT
        </Typography>
      <DataGridPro
        getRowId={(row) => row._id}
        rows={data}
        disableDensitySelector
        columns={[
            { field: '_id', headerName: 'ID', width: 250 },
            { field: 'name', headerName: 'Name', width: 150 },
            { 
                field: 'branch', 
                headerName: 'Branch', 
                width: 150, 
                valueGetter: (params) => params?.row?.batch?.map(batch => batch?.branch?.name).join(', ') 
            },
            { 
              field: 'batch', 
              headerName: 'Batch', 
              width: 150, 
              valueGetter: (params) => params.row.batch.map(batch => batch?.name).join(', ') 
             },
            { 
                field: 'course', 
                headerName: 'Course', 
                width: 150, 
                valueGetter: (params) => params.row.batch.map(batch => batch?.course?.name).join(', ') 
            }, 
            { 
                field: 'paper', 
                headerName: 'Paper', 
                width: 150, 
                // valueGetter: (params) => params.row.papers.map(paper => paper?.name).join(', ') 
            },
            { 
              field: 'faculty', 
              headerName: 'Faculty', 
              width: 150, 
              valueGetter: (params) => params.row?.batch?.name
             },
        ]}
        components={{ Toolbar: GridToolbar }}
        />
    </div>
  );
};

export default MyDataGridPro;
