import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import apiService from '../../../utlis/apiService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

const CreateStudentForm = ({ id }) => {
  const [activityLogs, setActivityLogs] = useState([]);

  const fetchActivityLogs = async () => {
    try {
      const response = await apiService.get(`/student/getActivityLogsByStudentId?studentId=${id}`);
      setActivityLogs(response?.data?.activityLogs);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchActivityLogs();
  }, [id]);

  const formatDate = (myDate) => {
    const date = new Date(myDate);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };

  const getSettlementAmount = (student) => {
    let discount = 0;
    Array.isArray(student?.discount) && student?.discount?.map(dis => {
      discount += dis?.value;
    });

    const total = student?.totalFee - discount;

    return  total + '/' + (student?.settlementDueDate ?? '-') + '/' + (student?.settlementDate ?? '-') + '/' + (student?.settlementStatus === true ? 'Collected' : 'Not Collected');
  }

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h6" gutterBottom>
        Activity Logs
      </Typography>
      <List>
        {activityLogs.map((item, index) => (
          <React.Fragment key={item._id}>
            <div style={{ marginTop: '10px' }}>
              <div style={{ fontSize: '14px', fontWeight: 600 }}>{formatDate(item.timestamp)}</div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Whatsapp Number</TableCell>
                      <TableCell>Parent Number</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Gender</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Country</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>City</TableCell>
                      <TableCell>Pin Code</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Qualification</TableCell>
                      <TableCell>Course</TableCell>
                      <TableCell>Status Type</TableCell>
                      <TableCell>Mode</TableCell>
                      <TableCell>Categories</TableCell>
                      <TableCell>Papers</TableCell>
                      <TableCell>Initial Registration/Number</TableCell>
                      <TableCell>Total Fee</TableCell>
                      <TableCell>Admission Fee/Payment Method/Status</TableCell>
                      <TableCell>Discount/Type</TableCell>
                      <TableCell>Amount/Due Date/Payment Date/Method/Status</TableCell>
                      <TableCell>Accounts Remark</TableCell>
                      <TableCell>Accounts Status</TableCell>
                      <TableCell>Operations Remark</TableCell>
                      <TableCell>Operations Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{item?.userId?.email}</TableCell>
                      <TableCell>{item?.details?.action}</TableCell>
                      <TableCell>{item?.details?.details?.firstName}</TableCell>
                      <TableCell>{item?.details?.details?.lastName}</TableCell>
                      <TableCell>{item?.details?.details?.phoneNumber}</TableCell>
                      <TableCell>{item?.details?.details?.whatsappNumber}</TableCell>
                      <TableCell>{item?.details?.details?.parentNumber}</TableCell>
                      <TableCell>{item?.details?.details?.email}</TableCell>
                      <TableCell>{item?.details?.details?.gender}</TableCell>
                      <TableCell>{item?.details?.details?.address}</TableCell>
                      <TableCell>{item?.details?.details?.country}</TableCell>
                      <TableCell>{item?.details?.details?.state}</TableCell>
                      <TableCell>{item?.details?.details?.city}</TableCell>
                      <TableCell>{item?.details?.details?.pincode}</TableCell>
                      <TableCell>{item?.details?.details?.status}</TableCell>
                      <TableCell>{item?.details?.details?.qualification}</TableCell>
                      <TableCell>{item?.details?.details?.course?.name}</TableCell>
                      <TableCell>{item?.details?.details?.statusType}</TableCell>
                      <TableCell>{item?.details?.details?.mode}</TableCell>
                      <TableCell><Tooltip title={item?.details?.details?.category && item?.details?.details?.category?.map(category => category?.name).join(', ')}>{(item?.details?.details?.category && item?.details?.details?.category?.length) ? item?.details?.details?.category[0]?.name : ''}</Tooltip></TableCell>
                      <TableCell><Tooltip title={item?.details?.details?.papers && item?.details?.details?.papers?.map(papers => papers?.name).join(', ')}>{(item?.details?.details?.papers && item?.details?.details?.papers.length) ? item?.details?.details?.papers[0]?.name : ''}</Tooltip></TableCell>
                      <TableCell>{item?.details?.details?.initialRegistration === true ? 'Done' : 'Not Done'} {item?.details?.details?.registrationNumber ? ' / ' + item?.details?.details?.registrationNumber : ''}</TableCell>
                      <TableCell>{item?.details?.details?.totalFee}</TableCell>
                      <TableCell>{item?.details?.details?.admissionFee + '/' + item?.details?.details?.admissionFeePaymentMethod + item?.details?.details?.admissionFeeCollected === true ? 'Collected' : 'Not Collected'}</TableCell>
                      <TableCell>
                        <ul>
                          {(item?.details?.details?.discount && item?.details?.details?.discount?.length && Array.isArray(item?.details?.details?.discount)) && item?.details?.details?.discount?.map((discount, index) => (
                            <li key={index}>{discount?.value} / {discount?.discountType}</li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell>
                        {item?.details?.details?.totalFeeSplit?.length ? 
                          item?.details?.details?.totalFeeSplit?.map((split, index) => (
                            <li key={index}>{split?.value} / {split?.dueDate ?? '-'} / {split?.paidDate ?? '-'} / {split?.paymentMethod} / {split?.received === true ? 'Collected' : 'Not Collected'}</li>
                          ))
                        :
                          getSettlementAmount(item?.details?.details)
                        }
                      </TableCell>
                      <TableCell>{item?.details?.details?.accountRemark}</TableCell>
                      <TableCell>{item?.details?.details?.accountStatus}</TableCell>
                      <TableCell>{item?.details?.details?.operationRemark}</TableCell>
                      <TableCell>{item?.details?.details?.operationStatus}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            {index < activityLogs.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default CreateStudentForm;
