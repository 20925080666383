import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Notifications = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([
    {
        title:"test",
        message:"test message"
    },
    {
        title:"test2",
        message:"test message2"
    }
  ]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // // Replace the API URL with your actual API endpoint
    // axios.get('http://localhost:3000/user/notifications?id=65b297060ab8b5af8f63deef')
    //   .then(response => {
    //     setNotifications(response.data.notifications);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching notifications:', error);
    //   });
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Notifications
          </Typography>
        </Toolbar>
      </AppBar>

      <List>
        {notifications.map((notification) => (
          <ListItem key={notification._id}>
            <ListItemText
              primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
              primary={notification.title}
              secondary={notification.message}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Notifications;
