import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useLocation } from 'react-router-dom';
import apiService from '../../../utlis/apiService';
import { ArrowBackIos as BackButton } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { 
	FormControl,
	FormLabel,
	InputLabel
} from '@mui/material';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/en'; 

dayjs.extend(AdvancedFormat);

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateScheduleMultipleForm = () => {
	let query = useQuery();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		batch: '',
		status: '',
		topics:[],
		multipleData: [{
			classBy: '',
			date: null,
			start: null,
			end: null,
			classRoom: '',
		}],
	});

	const [batches, setBatches] = useState([]);
	const [topics,setTopics] = useState([]);
	const [selectedTopics,setSelectedTopics] = useState([]);
	const [classes,setClasses] = useState([]);
	const [subject, setSubject] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const batchesResponse = await apiService.get('/batch/list');
				const classResponse = await apiService.get('/class/list');

				const batchList = batchesResponse?.data?.batchs;
				const activeBatchList = batchList.filter(batch => batch);
				const classs = classResponse?.data?.classRoom;
				setBatches(activeBatchList);
				setClasses(classs);
			} catch (error) {
				console.error(error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if(subject)
			getTopics();
	}, [subject]);

	const getTopics = async () => {
		const topicsResponse = await apiService.get(`/topic/listBySubject?subjectId=${subject}`);
		setTopics(topicsResponse?.data?.topics);
	}

	const [errors, setErrors] = useState({
		batch: '',
	});

	const validateForm = () => {
		let isValid = true;
		const newErrors = {
			batch: '',
		};

		if (!formData.batch) {
		  newErrors.batch = 'Invalid batch';
		  isValid = false;
		}

		setErrors(newErrors);
		return isValid;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (validateForm()) {
			let murl = `/schedule/create/multiple`;

			try {
				const response = await apiService.post(murl, { ...formData, topics: selectedTopics }, {
					headers: {
						'Content-Type': 'application/json',
					},
				});

				navigate(-1);
			} catch (error) {
				alert(error?.response?.data?.error);
				console.error(error);
			}
		}
	}

	const handleChange = (name, value) => {
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleChangeSubmit = (e, value, name) => {
		setFormData((prevData) => ({
			...prevData,
			[name]: value?._id,
		}));
		if(name === 'batch'){
			setSubject(value?.paper);
		}
	}

	const handleChangeTopics=(newValue)=>{
		setSelectedTopics(newValue);
	}

	const addMultipleElements = () => {
		const newElements = {
			classBy: '',
			date: null,
			start: null,
			end: null,
			classRoom: '',
		}

		setFormData((prevData) => ({
			...prevData,
			multipleData: [...formData.multipleData, newElements],
		}));
	}

	const removeMultipleElements = (index) => {
		if(formData?.multipleData?.length > 1){
			const elements = [...formData.multipleData];
			elements.splice(index, 1);
			setFormData({ ...formData, multipleData: elements });
		}
	}

	const handleMultipleChange = (name, value, index) => {
		const values = [...formData.multipleData];
		values[index][name] = value;
		setFormData((prevData) => ({
			...prevData,
			multipleData: values 
		}));
	};

	const handleMultipleClassChange = (e, name, value, index) => {
		const values = [...formData.multipleData];
		values[index][name] = value._id;
		setFormData((prevData) => ({
			...prevData,
			multipleData: values 
		}));
	}

	return (
		<div>
			<h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
				<BackButton />
				Schedules
			</h1>

			<form onSubmit={handleSubmit}>
				<Typography variant="h4" gutterBottom>
					Create Schedule
				</Typography>

				<Autocomplete
					disablePortal
					id="combo-box-batch"
					name="batch"
					options={batches}
					style={{ marginTop: 15 }}
					getOptionLabel={(option) => option.name}
					value={batches.find((option) => option._id === formData.batch) || null}
					onChange={(e, value) => handleChangeSubmit(e, value, 'batch')}
					renderInput={(params) => <TextField {...params} label="Batch" />}
				/>

				<FormControl fullWidth sx={{ marginTop: '15px' }}>
					<InputLabel id="Select Status">Select Status</InputLabel>
					<Select
						label="Select Status"
						value={formData.status}
						onChange={(e) => handleChange('status', e.target.value)}
						fullWidth
						name="status"
						required
					>
						<MenuItem value="active">Active</MenuItem>
						<MenuItem value="completed">Completed</MenuItem>
						<MenuItem value="canceled">Canceled</MenuItem>
					</Select>
				</FormControl>

				<Autocomplete
					multiple
					id="topic-autocomplete"
					options={topics}
					getOptionLabel={(option) => `Name : ${option.name} | Hours: ${option.hours} | Paper : ${option?.paper?.name} | Course : ${option?.paper?.course?.name}` }
					value={selectedTopics}
					onChange={(event, newValue) => handleChangeTopics(newValue)}
					renderInput={(params) => <TextField {...params} label={'Topics'} />}
					sx={{ marginTop: '15px' }}
				/>
				
				<div>
					<Button type='button' onClick={addMultipleElements} variant="contained" color="primary" sx={{ float: 'right', marginTop: 3, }}> + </Button>
				</div>
				
				{formData?.multipleData?.map((row, index) => (
					<React.Fragment key={index}>
						<div>
							<Button type='button' onClick={() => removeMultipleElements(index)} variant="outlined" color="secondary" sx={{ float: 'right', marginTop: 1, clear: 'both' }}> - </Button>
						</div>
						<FormControl fullWidth sx={{ marginTop: '15px' }}>
							<InputLabel id="Class Taken By">Class Taken By</InputLabel>
							<Select
								label="Class Taken By"
								value={row.classBy}
								onChange={(e) => handleMultipleChange('classBy', e.target.value, index)}
								fullWidth
								name="classBy"
							>
								<MenuItem value="Faculty">Faculty</MenuItem>
								<MenuItem value="Mentor">Mentor</MenuItem>
							</Select>
						</FormControl>
				
						<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
							<FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
								<FormLabel htmlFor='activity-time' className='text-sm' required>
									Select Date
								</FormLabel>
								<MobileDatePicker
									value={row.date}
									onChange={(date) => handleMultipleChange('date', date, index)}
									sx={{ marginTop: 1 }}
									format="DD/MM/YYYY"
								/>
							</FormControl>
						</LocalizationProvider>
				
						<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
							<FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
								<FormLabel htmlFor='activity-time' className='text-sm' required>
									Start Time
								</FormLabel>
								<MobileTimePicker
									value={row?.start ? dayjs(row?.start) : null} 
									onChange={(time) => handleMultipleChange('start', time, index)}
									ampm={true}
									sx={{ marginTop: 1 }}
									format='HH:mm'
								/>
							</FormControl>
						</LocalizationProvider>

						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<FormControl className='gap-2 mb-8' fullWidth style={{ marginTop: 10 }}>
								<FormLabel htmlFor='activity-time' className='text-sm' required>
									End Time
								</FormLabel>
								<MobileTimePicker
									value={row?.end ? dayjs(row?.end) : null} 
									onChange={(time) => handleMultipleChange('end', time, index)}
									format='HH:mm'
									ampm={true}
									sx={{ marginTop: 1 }}
								/>
							</FormControl>
						</LocalizationProvider>

						<Autocomplete
							disablePortal
							id="combo-box-demo"
							name="classRoom"
							options={classes}
							style={{marginTop:15}}
							getOptionLabel={(option) => option.name}
							value={classes.find((option) => option._id === row.classRoom) || null}
							onChange={(e, value) => handleMultipleClassChange(e, 'classRoom', value, index)} 
							renderInput={(params) => <TextField {...params} label="Add Class Room" />}
						/>
						{formData?.multipleData?.length -1 !== index ? <hr/> : ''}
					</React.Fragment>
				))}

				<Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
					Create Schedule
				</Button>
			</form>
		</div>
	);
};

export default CreateScheduleMultipleForm;
