import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../../../config';
import apiService from '../../../utlis/apiService';
import {
	ArrowBackIos as BackButton,
} from '@mui/icons-material';
import {
	TextField,
	Typography,
	Select,
	MenuItem,
	Autocomplete,
	Box,
	Tab,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Grid,
	FormControl,
	InputLabel,
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MuiTelInput } from 'mui-tel-input';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import ActivityLogs from './log'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useUser } from '../../../context/userContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/en';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { getPaymentMethod, getStatusType } from '../../../utlis/commonFunction';

dayjs.extend(AdvancedFormat);

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}


const ViewStudentForm = () => {
	const { permissions } = useUser();
	let query = useQuery();
	let id = query.get('id');
	const navigate = useNavigate();
	const [courses, setCourses] = useState([]);
	const [accountOperationTabValue, setAccountOperationTabValue] = useState(permissions.includes('manageOperations') ? 'operations00' : permissions.includes('manageAccounts') ? 'accounts00' : false);
	const [admissionTabValue, setAdmissionTabValue] = useState('admission0');
	const [categoryTabValue, setCategoryTabValue] = useState('category00');

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		phoneNumber: '+91',
		parentNumber: '+91',
		email: '',
		whatsappNumber: '+91',
		address: '',
		country: '',
		state: '',
		city: '',
		pinCode : '',
		qualification: '',
		status: 'Active',
		gender: 'male',
		batch: [],
		mode: '',
		statusType: '',
		totalFee: 0,
		course: [],
		category: [],
		papers:[],
		admissionFeeCollected: false,
		discount: [],
		totalFeeSplit: [],
		operationStatus: false,
		accountStatus: false,
		admissionFee: '',
		admissionFeePaymentMethod: '',
		settlementDate: null,
		settlementStatus: false,
		settlementPaymentMethod: '',
		settlementDueDate: null,
		initialRegistration: false,
		accountRemark: '',
		operationRemark: '',
		registrationNumber: '',
	});

	const [admission, setAdmission] = useState([
		{
			course: '',
			selectedCourse: null,
			statusType: '',
			categoryList: [], // List category based on course
			category: [],
		}
	]);
	const [statusType, setStatusType] = useState([]);

	const paymentMethods = getPaymentMethod();

  	useEffect(() => {
		if (id) {
			fetchStudentData();
		}
  	}, [id]);

	  const fetchStudentData = async () => {
		try {
			const response = await apiService.get(`/student/get?id=${id}`);
			
			const admissionData = await Promise.all(
				response?.data?.admission?.map(async adm => ({
					...adm,
					selectedCourse: adm?.course,
					categoryList: await getCategories(adm?.course?._id),
					course: adm?.course?._id,
					category: adm?.category?.map(cat => {
						const category = {
							...cat.category,
							...cat,
						}

						if(!cat?.feeSplit?.length && !cat?.ots?.amount){
							
							const otsData = {
								amount: cat.totalFee,
								received: false,
								paidDate: null,
								dueDate: null,
								paymentMethod: '',
							}

							category.ots = otsData;
						}
						
						return category;
					})
				}))
			)
			
			setAdmission(admissionData);

			setFormData(response?.data);
			
			
			setFormData({...formData, ...response?.data});
		} catch (error) {
			console.error(error);
		}
	}
  
	useEffect(() => {
		getData();
		getCourses();
		fetchStatusTypeData();
	}, []);

	const fetchStatusTypeData = async () => {
		const statusTypeData = await getStatusType();
		setStatusType(statusTypeData);
	}

	const getCategories = async (courseId) => {
		try {
			const response = await apiService.post(`/level/list/categoryByCourse`, { courseId: courseId });
			return response?.data?.categories;
		} catch (error) {
			console.error(error);
		}
	};
  
	const getData = async () => {
		try {
			const response = await apiService.get('/batch/list');
		} catch (error) {
			console.error(error);
		}
	};

	const getCourses = async () => {
		try {
			const response = await apiService.get('/course/list');
			setCourses(response?.data?.course);
		} catch (error) {
			console.error(error);
		}
	};

	const handleAdmissionTabChange = (e, value) => {
		setAdmissionTabValue(value);
	}

	const handleCategoryTabChange = (e, value) => {
		setCategoryTabValue(value);
	}

	const handleAccountOperationTabChange = (event, newValue) => {
		setAccountOperationTabValue(newValue);
	}

	const calculatePaidFee = (item) => {

		const ots = item.ots.received && item.ots.amount > 0 ? item.ots.amount : 0;
		const totalSum = item.feeSplit?.reduce((sum, payment) => {
			const value = parseFloat(payment.value)
			if(!isNaN(value) && payment.received){
				return sum + value;
			} 
			return sum;
		}, 0);

		return totalSum + ots;
	}

	const calculateBalanceFee = (item) => {

		const ots = !item.ots.received && item.ots.amount > 0 ? item.ots.amount : 0;
		const totalSum = item.feeSplit?.reduce((sum, payment) => {
			const value = parseFloat(payment.value)
			if(!isNaN(value) && !payment.received){
				return sum + value;
			} 
			return sum;
		}, 0);

		return totalSum + ots;
	}

	const calculateUnallocatedFee = (unallocatedFee, split, discount) => {
		if(!split.length){
			return 0;
		}

		const totalSum = split?.reduce((sum, payment) => sum + parseFloat((payment.value === '' || isNaN(payment.value)) ? 0 : payment.value), 0);
		const totalDiscount = (discount === null || discount?.length === 0) ? 0 : discount?.reduce((sum, dis) => sum + parseFloat((dis.value === '' || isNaN(dis.value)) ? 0 : dis.value), 0);

		if(unallocatedFee !== 0) {
			if(totalSum > unallocatedFee){
				alert('The amount must be less than unallocated fee');
			}
			
			return unallocatedFee - totalSum - totalDiscount;
		}
		else {
			return 0;
		}
	}

	const getCategoryPapers = (categoryList, selectedCategory) => {

		const categoryItem = categoryList.find(category => category._id === selectedCategory);
		
		const papers = categoryItem?.papers;
		return papers;
	}

  	return (
		<div>
			<h1 style={{ cursor: 'pointer', width: 'fit-content' }} onClick={() => navigate(-1)}>
				<BackButton />
				{`Students`}
			</h1>
			<form>
				<Typography variant="h4" gutterBottom>
					View Student
				</Typography>
				<Accordion defaultExpanded={true} sx={{ marginBottom: 2 }}>
					<AccordionSummary
						expandIcon={<ArrowDownwardIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
					>
						<Typography>User Details</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<TextField
							label="First Name"
							name="firstName"
							value={formData.firstName}
							fullWidth
							margin="normal"
							disabled={true}
						/>
						<TextField
							label="Last Name"
							name="lastName"
							value={formData.lastName}
							fullWidth
							margin="normal"
							disabled={true}
						/>
						<MuiTelInput 
							label="Phone Number"
							name="phoneNumber"
							value={formData.phoneNumber}
							fullWidth
							margin="normal"
							disabled={true}
						/>
						<MuiTelInput 
							label="Whatsapp Number"
							name="whatsappNumber"
							value={formData.whatsappNumber}
							fullWidth
							margin="normal"
							disabled={true}
						/>
						<MuiTelInput 
							label="Parent Number"
							name="parentNumber"
							value={formData.parentNumber}
							fullWidth
							margin="normal"
							disabled={true}
						/>
						<TextField
							label="Email"
							name="email"
							value={formData.email}
							fullWidth
							type="email"
							margin="normal"
							disabled={true}
						/>
						<FormControl fullWidth style={{ marginTop: '20px'}}>
							<InputLabel id="Gender">Gender</InputLabel>
							<Select
								label="Gender"
								name="gender"
								value={formData.gender}
								fullWidth
								margin="normal"
								disabled={true}
							>
								<MenuItem value="male">Male</MenuItem>
								<MenuItem value="female">Female</MenuItem>
								<MenuItem value="other">Other</MenuItem>
							</Select>
						</FormControl>
						<TextField
							label="Address"
							name="address"
							value={formData.address}
							fullWidth
							margin="normal"
							disabled={true}
						/>
						<TextField
							label="Country"
							name="country"
							value={formData.country}
							disabled={true}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="State"
							name="state"
							value={formData.state}
							disabled={true}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="City"
							name="city"
							value={formData.city}
							disabled={true}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Pin code"
							name="pinCode"
							value={formData.pinCode}
							disabled={true}
							fullWidth
							margin="normal"
						/>
						<FormControl fullWidth style={{ marginTop: '20px'}}>
							<InputLabel id="Status">Status</InputLabel>
							<Select
								label="Status"
								name="status"
								value={formData.status}
								disabled={true}
								fullWidth
								margin="normal"
							>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
								<MenuItem value="Drop">Drop</MenuItem>
							</Select>
						</FormControl>
						<TextField
							label="Qualification"
							name="qualification"
							value={formData.qualification}
							disabled={true}
							fullWidth
							required
							margin="normal"
						/>

						<Grid container spacing={2} alignItems="center">
							<Grid item xs={4}>
								<FormGroup sx={{ width: 'fit-content'}}>
									<FormControlLabel control={<Checkbox checked={formData.initialRegistration} disabled={true} />} label="Initial Registration Done" />
								</FormGroup>
							</Grid>
							<Grid item xs={4}>
								<TextField
									label="Registration Number"
									name="registrationNumber"
									value={formData.registrationNumber}
									disabled={true}
									fullWidth
									type='text'
									margin="normal"
								/>
							</Grid>
						</Grid>
						<TextField
							label="Total Fee"
							name="totalFee"
							value={formData.totalFee}
							disabled={true}
							fullWidth
							required
							type='number'
							margin="normal"
						/>
					</AccordionDetails>
				</Accordion>
			</form>

			<TabContext value={admissionTabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleAdmissionTabChange} aria-label="admissions tab" variant="scrollable">
						{admission.map((row, index) => (
							<Tab label={`Admission ${index+1}`} value={`admission${index}`} key={index} />
						))}
					</TabList>
				</Box>
				{admission.map((row, index) => (
					<React.Fragment key={index}>
						<TabPanel value={`admission${index}`}>
							<Autocomplete
								id="Course-autocomplete"
								options={courses}
								getOptionLabel={(option) => option.name}
								value={row.selectedCourse}
								disabled={true}
								renderInput={(params) => <TextField {...params} label={'Course *'} />}
								sx={{ marginTop: '15px' }}
							/>

							<Autocomplete
								id="Category-autocomplete"
								options={row.categoryList}
								value={null}
								getOptionLabel={(option) => option.name}
								renderInput={(params) => <TextField {...params} label={'Category *'} />}
								sx={{ marginTop: '15px' }}
								disabled={true}
							/>
							{row?.category?.length ?
								<>
									<TabContext value={categoryTabValue}>
										<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
											<TabList onChange={handleCategoryTabChange} aria-label="Category tab" variant="scrollable">
												{row?.category?.map((item, itemIndex) => (
													<Tab label={item.name} sx={item?.status === 'Drop' ? {textDecoration: 'line-through'} : {}} value={`category${index}${itemIndex}`} key={`${index}${itemIndex}`} />
												))}
											</TabList>
										</Box>
										{row?.category?.map((item, itemIndex) => (
											<TabPanel value={`category${index}${itemIndex}`} key={`${index}${itemIndex}`}>
												<Typography gutterBottom style={{ marginTop: '20px'}}>
													Selected Subjects
												</Typography>
												<TableContainer component={Paper} style={{ marginTop: '10px', marginBottom: '10px' }}>
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>Action</TableCell>
																<TableCell>Name</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{getCategoryPapers(row.categoryList, item?.category?._id)?.map((paper) => (
																<TableRow key={paper._id}>
																	<TableCell>
																		<FormGroup sx={{ width: 'fit-content'}}>
																			<FormControlLabel control={<Checkbox checked={item?.papers?.includes(paper?._id)} disabled />} />
																		</FormGroup>
																	</TableCell>
																	<TableCell>{paper.name}</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</TableContainer>

												<Typography>Batch Enrolled</Typography>
												<TableContainer component={Paper} style={{ marginTop: '10px' }}>
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>Name</TableCell>
																<TableCell>Branch</TableCell>
																<TableCell>Action</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{item?.batch?.map((batch) => (
																<TableRow key={batch?._id}>
																	<TableCell>{batch?.name}</TableCell>
																	<TableCell>{batch?.branch?.name}</TableCell>
																</TableRow>
															))}
														</TableBody>
													</Table>
												</TableContainer>

												<FormControl fullWidth style={{ marginTop: '20px'}}>
													<InputLabel id="statusType">Status Type *</InputLabel>
													<Select
														label="Status Type *"
														value={item.statusType}
														fullWidth
														name="statusType"
														margin="normal"
														disabled={true}
													>
														<MenuItem value="">Select Status Type</MenuItem>
														{statusType?.map((type, index) => (
															<MenuItem key={index} value={type}>{type}</MenuItem>
														))}
													</Select>
												</FormControl>

												<FormControl fullWidth style={{ marginTop: '20px'}}>
													<InputLabel id="Mode">Mode *</InputLabel>
													<Select
														label="Mode *"
														value={item.mode}
														fullWidth
														name="mode"
														margin="normal"
														disabled={true}
													>
														<MenuItem value="">Select Mode</MenuItem>
														<MenuItem value="Offline">Offline</MenuItem>
														<MenuItem value="Online">Online</MenuItem>
														<MenuItem value="Hybrid">Hybrid</MenuItem>
														<MenuItem value="Recordings">Recordings</MenuItem>
														<MenuItem value="Online Plus Recordings">Online Plus Recordings</MenuItem>
													</Select>
												</FormControl>
												<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
													Accounts and Operations
												</Typography>
												<TabContext value={accountOperationTabValue}>
													<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
														<TabList onChange={handleAccountOperationTabChange} aria-label="Accounts and Operations tab">
															<Tab label="Operations" value={`operations${index}${itemIndex}`} />
															<Tab label="Accounts" value={`accounts${index}${itemIndex}`} />
														</TabList>
													</Box>
													<TabPanel value={`accounts${index}${itemIndex}`}>
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={3} sx={{ marginTop: '20px' }}>
																<TextField
																	label="Admission Fee"
																	margin="normal"
																	name="admissionFee"
																	value={item.admissionFee}
																	disabled={true}
																	fullWidth
																/>
															</Grid>
															<Grid item xs={3} sx={{ marginTop: '26px' }}>
																<FormControl fullWidth>
																	<InputLabel id="AdmissionFeePaymentMethod">Payment Method</InputLabel>
																	<Select
																		label="Payment Method"
																		value={item.admissionFeePaymentMethod}
																		disabled={true}
																		fullWidth
																		name='admissionFeePaymentMethod'
																	>
																		{paymentMethods?.map((method, index) => (
																			<MenuItem key={index} value={method}>{method}</MenuItem>
																		))}
																	</Select>
																</FormControl>
															</Grid>
															<Grid item xs={3}>
																<InputLabel id={`SettlementPaymentDate`}>Payment Date</InputLabel>
																<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																	<FormControl className='gap-2 mb-8' fullWidth>
																		<MobileDatePicker
																			value={dayjs(item?.admissionFeePaidDate)}
																			disabled={true}
																			format="DD/MM/YYYY"
																		/>
																	</FormControl>
																</LocalizationProvider>
															</Grid>
															<Grid item xs={2} sx={{ marginTop: '20px' }}>
																<FormGroup sx={{ width: 'fit-content'}}>
																	<FormControlLabel control={<Checkbox checked={item.admissionFeeCollected} disabled={true} />} label="Admission fee collected" />
																</FormGroup>
															</Grid>
															
															<Grid item xs={1} sx={{ marginTop: '20px' }}>
																
															</Grid>
														</Grid>
														<Grid container spacing={2} alignItems="center">
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom style={{ marginTop: '20px'}}>
																	Total Fee: {item.status === 'Drop' ? 0 : item.totalFee}
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom>
																	Paid Fee: {calculatePaidFee(item)}
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom>
																	Balance Fee: {calculateBalanceFee(item)}
																</Typography>
															</Grid>
															<Grid item xs={3}>
																<Typography variant="h6" gutterBottom>
																	Unallocated Fee: {calculateUnallocatedFee(item.unallocatedFee, item?.feeSplit, item?.discount)}
																</Typography>
															</Grid>
														</Grid>
														{item?.discount?.map((row, rowIndex) => (
															<Grid container spacing={2} alignItems="center" key={itemIndex}>
																{row?.discountType === 'Instant Discount' ?
																	<>
																		<Grid item xs={3}>
																			<TextField
																				label="Discount"
																				type='number'
																				margin="normal"
																				name="value"
																				value={row.value}
																				disabled={true}
																				fullWidth
																			/>
																		</Grid>
																		<Grid item xs={2} style={{ paddingTop: '0px' }}>
																			<InputLabel id={`RequestDate`}>Request Date</InputLabel>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						value={dayjs(row?.dueDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2} style={{ paddingTop: '0px' }}>
																			<InputLabel id={`ApprovalDate`}>Approval Date</InputLabel>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						value={dayjs(row?.dueDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2} sx={{ marginTop: '6px' }}>
																			<FormControl fullWidth>
																				<InputLabel id="Select Type">Select Type</InputLabel>
																				<Select
																					label="Select Type"
																					value={row.discountType}
																					disabled={true}
																					fullWidth
																					name='discountType'
																				>
																				<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																				<MenuItem value="Scholarship">Scholarship</MenuItem>
																				<MenuItem value="Settlement">Settlement</MenuItem>
																				<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																				<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																				<MenuItem value="Instant Discount">Instant Discount</MenuItem>  {/* For Black amount added as discount in student calculated as paid */}
																				<MenuItem value="Other">Other</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>
																		<Grid item xs={2}>
																			<Checkbox checked={row?.received} disabled={true} />
																		</Grid>
																	</>
																:
																	<>
																		<Grid item xs={6}>
																			<TextField
																				label="Discount"
																				type='number'
																				margin="normal"
																				name="value"
																				value={row.value}
																				disabled={true}
																				fullWidth
																			/>
																		</Grid>
																		<Grid item xs={5} sx={{ marginTop: '6px' }}>
																			<FormControl fullWidth>
																				<InputLabel id="Select Type">Select Type</InputLabel>
																				<Select
																					label="Select Type"
																					value={row.discountType}
																					disabled={true}
																					fullWidth
																					name='discountType'
																				>
																				<MenuItem value="Sales Offer">Sales Offer</MenuItem>
																				<MenuItem value="Scholarship">Scholarship</MenuItem>
																				<MenuItem value="Settlement">Settlement</MenuItem>
																				<MenuItem value="PY Fee Adjustment">PY Fee Adjustment</MenuItem>
																				<MenuItem value="Ref Discount">Ref Discount</MenuItem>
																				<MenuItem value="Instant Discount">Instant Discount</MenuItem>  {/* For Black amount added as discount in student calculated as paid */}
																				<MenuItem value="Other">Other</MenuItem>
																				</Select>
																			</FormControl>
																		</Grid>
																	</>
																}
																<Grid item xs={1} sx={{ marginTop: '6px' }}></Grid>
															</Grid>
														))}
														<div>
															{item?.ots?.amount ?
																<div>
																	<InputLabel id="Settlement"><b>One Time Settlement</b></InputLabel>
																	<Grid container spacing={2} alignItems="center">
																		<Grid item xs={3}>
																			<InputLabel id={`settlementAmount`}>Amount</InputLabel>
																			<TextField
																				margin="normal"
																				disabled={true}
																				value={item?.ots?.amount}
																				fullWidth
																			/>
																		</Grid>
																		<Grid item xs={2}>
																			<InputLabel id={`SettlementdueDate`}>Due Date</InputLabel>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						value={dayjs(item?.ots?.dueDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2}>
																			<InputLabel id={`SettlementPaymentDate`}>Payment Date</InputLabel>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						value={dayjs(item?.ots?.paidDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={3}>
																			<div style={{ marginTop: '30px' }}>
																				<FormControl fullWidth>
																					<InputLabel id="SettlementPaymentMethod">Payment Method</InputLabel>
																					<Select
																						label="Payment Method"
																						value={item?.ots?.paymentMethod}
																						disabled={true}
																						fullWidth
																						name='settlementPaymentMethod'
																					>
																						{paymentMethods?.map((method, index) => (
																							<MenuItem key={index} value={method}>{method}</MenuItem>
																						))}
																					</Select>
																				</FormControl>
																			</div>
																		</Grid>
																		<Grid item xs={1}>
																			<Checkbox checked={item?.ots?.received} disabled={true} />
																		</Grid>
																		<Grid item xs={1}></Grid>
																	</Grid>
																</div>
															: ''}
															{item?.feeSplit && item?.feeSplit?.map((field, splitIndex) => (
																<div key={splitIndex}>
																	<Grid container spacing={2} alignItems="center">
																		<Grid item xs={3}>
																			<InputLabel id={`Amount${index}`}>Amount</InputLabel>
																			<TextField
																				margin="normal"
																				value={field.value}
																				disabled={true}
																				fullWidth
																			/>
																		</Grid>
																		<Grid item xs={2}>
																			<InputLabel id={`dueDate${index}`}>Due Date</InputLabel>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						value={dayjs(field.dueDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2}>
																			<InputLabel id={`paymentDate${index}`}>Payment Date</InputLabel>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						value={dayjs(field.paidDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2}>
																			<div style={{ marginTop: '30px' }}>
																				<FormControl fullWidth>
																					<InputLabel id={`PaymentMethod${index}`}>Payment Method</InputLabel>
																					<Select
																						label="Payment Method"
																						value={field.paymentMethod}
																						disabled={true}
																						fullWidth
																						name='paymentMethod'
																					>
																						{paymentMethods?.map((method, index) => (
																							<MenuItem key={index} value={method}>{method}</MenuItem>
																						))}
																					</Select>
																				</FormControl>
																			</div>
																		</Grid>
																		<Grid item xs={1}>
																			<Checkbox checked={field.received} disabled={true} />
																		</Grid>
																		<Grid item xs={1}></Grid>
																		<Grid item xs={1}></Grid>
																	</Grid>
																</div>
															))}
															{item?.additionalCharge?.length ?
																<InputLabel id="Settlement"><b>Additional Charges</b></InputLabel>
															:''}
															{item?.additionalCharge?.length > 0 && item?.additionalCharge?.map((field, chargeIndex) => (
																<div key={chargeIndex}>
																	<Grid container spacing={2} alignItems="center">
																		<Grid item xs={3}>
																			<TextField
																				label="Amount"
																				margin="normal"
																				value={field.value}
																				disabled={true}
																				fullWidth
																			/>
																		</Grid>
																		<Grid item xs={2}>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						label="Due Date"
																						value={dayjs(field.dueDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2}>
																			<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																				<FormControl className='gap-2 mb-8' fullWidth>
																					<MobileDatePicker
																						label="Payment Date"
																						value={dayjs(field.paidDate)}
																						disabled={true}
																						format="DD/MM/YYYY"
																						sx={{ marginTop: 1 }}
																					/>
																				</FormControl>
																			</LocalizationProvider>
																		</Grid>
																		<Grid item xs={2}>
																			<div style={{ marginTop: '10px' }}>
																				<FormControl fullWidth>
																					<InputLabel id={`PaymentMethod${index}`}>Payment Method</InputLabel>
																					<Select
																						label="Payment Method"
																						value={field.paymentMethod}
																						disabled={true}
																						fullWidth
																						name='paymentMethod'
																					>
																						{paymentMethods?.map((method, index) => (
																							<MenuItem key={index} value={method}>{method}</MenuItem>
																						))}
																					</Select>
																				</FormControl>
																			</div>
																		</Grid>
																		<Grid item xs={1}>
																			<Checkbox checked={field.received} disabled={true} />
																		</Grid>
																		<Grid item xs={1}>
																			
																		</Grid>
																		<Grid item xs={1}>
																			
																		</Grid>
																	</Grid>
																</div>
															))}
															{item?.excessOrRefund?.length && item?.excessOrRefund.includes(item => item.type === 'Refund') ?
																<InputLabel id="RefundApproval" sx={{ marginTop: '10px', bottomTop: '10px'}}><b>Refund Initiated By Approval</b></InputLabel>
															: ''}
															{item?.excessOrRefund?.length !== 0 ? item?.excessOrRefund?.map((field, refundIndex) => (
																<div key={refundIndex}>
																	{field?.type === 'Refund' ?
																		<Grid container spacing={2} alignItems="center">
																			<Grid item xs={3}>
																				<InputLabel id={`RefundAmount${index}`}>Refund Amount Suggested: {field.suggestedAmount}</InputLabel>
																				<TextField
																					margin="normal"
																					value={field.amount}
																					disabled={true}
																					fullWidth
																				/>
																			</Grid>
																			<Grid item xs={2}>
																				<InputLabel id={`dueDate${index}`}>Due Date</InputLabel>
																				<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																					<FormControl className='gap-2 mb-8' fullWidth>
																						<MobileDatePicker
																							value={dayjs(field.createdAt)}
																							format="DD/MM/YYYY"
																							sx={{ marginTop: 1 }}
																							disabled={true}
																						/>
																					</FormControl>
																				</LocalizationProvider>
																			</Grid>
																			<Grid item xs={2}>
																				<InputLabel id={`paymentDate${index}`}>Paid Date</InputLabel>
																				<LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
																					<FormControl className='gap-2 mb-8' fullWidth>
																						<MobileDatePicker
																							value={dayjs(field.paidDate)}
																							disabled={true}
																							format="DD/MM/YYYY"
																							sx={{ marginTop: 1 }}
																						/>
																					</FormControl>
																				</LocalizationProvider>
																			</Grid>
																			<Grid item xs={3}>
																				<div style={{ marginTop: '30px' }}>
																					<FormControl fullWidth>
																						<InputLabel id={`PaymentMethod${index}`}>Payment Method</InputLabel>
																						<Select
																							label="Payment Method"
																							value={field.paymentMethod}
																							disabled={true}
																							fullWidth
																							name='paymentMethod'
																						>
																							{paymentMethods?.map((method, index) => (
																								<MenuItem key={index} value={method}>{method}</MenuItem>
																							))}
																						</Select>
																					</FormControl>
																				</div>
																			</Grid>
																			<Grid item xs={2}>
																				<Checkbox checked={field.paid} disabled={true} />
																			</Grid>
																		</Grid>
																	: ''}
																</div>
															)):''}
															
															<div>
																{item?.accountRemark?.length ?
																	item?.accountRemark?.map((remark, remarkIndex) => (
																		<Grid container spacing={2} alignItems="center" key={remarkIndex}>
																			<Grid item xs={11}>
																				<TextField
																					label="Remark"
																					name="remark"
																					value={remark.remark}
																					fullWidth
																					type="text"
																					margin="normal"
																					disabled={true}
																				/>
																			</Grid>
																			<Grid item xs={1}></Grid>
																		</Grid>
																	))
																: ''}
																<Grid container spacing={2} alignItems="center">
																	<Grid item xs={12}>
																		<FormControl fullWidth sx={{ marginTop: '40px' }}>
																			<InputLabel id="Select Status">Account Status</InputLabel>
																			<Select
																				label="Account Status"
																				value={item.accountStatus}
																				fullWidth
																				name="accountStatus"
																				disabled={true}
																			>
																				<MenuItem value={true}>Active</MenuItem>
																				<MenuItem value={false}>Inactive</MenuItem>
																			</Select>
																		</FormControl>
																	</Grid>
																</Grid>
															</div>
														</div>
													</TabPanel>
													<TabPanel value={`operations${index}${itemIndex}`}>
														{item?.operationRemark?.length ?
															item?.operationRemark?.map((remark, remarkIndex) => (
																<Grid container spacing={2} alignItems="center" key={remarkIndex}>
																	<Grid item xs={11}>
																		<TextField
																			label="Remark"
																			name="operationRemark"
																			value={remark.remark}
																			fullWidth
																			type="text"
																			margin="normal"
																			disabled={true}
																		/>
																	</Grid>
																	<Grid item xs={1}></Grid>
																</Grid>
															))
														: ''}
														<FormGroup>
															<FormControl fullWidth sx={{ marginTop: '40px' }}>
																<InputLabel id="Select Status">Operation Status</InputLabel>
																<Select
																	label="Operation Status"
																	value={item.operationStatus}
																	disabled={true}
																	fullWidth
																	name="operationStatus"
																>
																	<MenuItem value={true}>Active</MenuItem>
																	<MenuItem value={false}>Inactive</MenuItem>
																</Select>
															</FormControl>
														</FormGroup>
													</TabPanel>
												</TabContext>
											</TabPanel>
										))}
									</TabContext>
								</>
							: ''}
						</TabPanel>
					</React.Fragment>
				))}
			</TabContext>
			{(id && permissions.includes('readStudentActivityLogs')) && 
				<div style={{ marginTop: '2rem' }}>
					<Accordion defaultExpanded ={!id}>
						<AccordionSummary
							expandIcon={<ArrowDownwardIcon />}
							aria-controls="panel1-content"
							id="panel1-header"
						>
							<Typography>Activity Logs</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ActivityLogs id={id} />
						</AccordionDetails>
					</Accordion>
				</div>
			}
		</div>
	);
};

export default ViewStudentForm;
