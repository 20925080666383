import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import apiService from '../../../utlis/apiService';
import { useUser } from '../../../context/userContext';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80%',
};

const MyTable = () => {
    const { permissions } = useUser();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [additionalChargesModal, setAdditionalChargesModal] = useState(false);
    const [additionsCharge, setAdditionsCharge] = useState('');
    const [rowType, setRowType] = useState('');
    const [rejectModal, setRejectModal] = useState(false);
    const [selectdApprovalId, setSelectdApprovalId] = useState('');
    const [totalResults, setTotalResults] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 50,
        page: 0,
    });
    const [filterData, setFilterData] = useState({
        dateFrom: null,
        dateTo: null,
        type: '',
    });

    const pageOption = [{label: 10, value: 10},{label: 25, value: 25},{label: 50, value: 50},{label: 100, value: 100}];

    useEffect(() => {
        getData();
    }, [searchTerm, paginationModel, filterData]);

    const getData = async () => {
        setLoading(true);
        try {
            const response = await apiService.get(`/student/approval/list`, {
                params: {
                    ...filterData,
                    searchTerm: searchTerm,
                    page: paginationModel.page,
                    pageSize: paginationModel.pageSize,
                },
            });

            setData(response?.data?.approvals);
            setTotalResults(response?.data?.totalResults);

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleApprovalSubmit = async () => {
        if(rowType === 'categoryChange') {
            setAdditionalChargesModal(true);
        }
        else {
            await saveApprovalData();
        }
    }

    const saveApprovalData = async() => {
        try {
            const data = {
                approvalId : selectdApprovalId,
                additionalCharge: additionsCharge,
            }
            
            const response = await apiService.post(`/student/approval/submit` , data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response?.data?.status === 'success'){
                setApproveModal(false);
                alert('Approval submited!');
                getData();
                setAdditionalChargesModal(false);
                setAdditionsCharge('');
            }

        } catch (error) {
            console.error(error);
            if(error?.response?.status === 500){
                alert(error?.response?.data);
            }
        }
    }

    const handleRejectSubmit = async () => {
        try {
            const data = {
                rejectId : selectdApprovalId,
            }
            
            const response = await apiService.post(`/student/reject/submit` , data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if(response?.data?.status === 'success'){
                setRejectModal(false);
                alert('Reject submited!');
                getData();
            }

        } catch (error) {
            console.error(error);
        }
    }

    const handleFilterChange = (name, value) => {
        setFilterData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleAdditionsCharge = (e) => {
        if(isNaN(e.target.value)){
            alert('Enter a valid number');
            return;
        }

        setAdditionsCharge(e.target.value !== '' ? Number(e.target.value) : '');
    }

    return (
        <div>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography variant="h4" gutterBottom>STUDENTS APPROVAL</Typography>
            </div>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 10 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                label="Date From"
                                value={filterData.dateFrom}
                                onChange={(date) => handleFilterChange('dateFrom', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} locale="en">
                        <FormControl className='gap-2 mb-8' fullWidth>
                            <MobileDatePicker
                                label="Date To"
                                value={filterData.dateTo}
                                onChange={(date) => handleFilterChange('dateTo', date)}
                                sx={{ marginTop: 1 }}
                                format="DD/MM/YYYY"
                                slotProps={{
                                    actionBar: {
                                      actions: ['clear', 'cancel', 'accept'],
                                    },
                                }}
                            />
                        </FormControl>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth sx={{ marginTop: '10px' }}>
                        <InputLabel id="RequestType">Request Type</InputLabel>
                        <Select
                            label="Request Type"
                            value={filterData.type}
                            onChange={(e) => handleFilterChange('type', e.target.value)}
                            fullWidth
                            name="type"
                        >
                            <MenuItem value="">All</MenuItem>
                            <MenuItem value="categoryChange">Category Change</MenuItem>
                            <MenuItem value="categoryDrop">Category Drop</MenuItem>
                            <MenuItem value="admissionDrop">Admission Drop</MenuItem>
                            <MenuItem value="batchShift">Batch Shift</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {loading ? (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </div>
            ) : (
                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGridPro
                        rows={data}
                        getRowId={(row) => row._id}
                        columns={[
                        { field: 'date', headerName: 'Date', flex: 1, valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }) },
                        { field: 'name', headerName: 'Name', flex: 1, valueGetter: (params) => params.row?.student?.firstName + ' ' + params.row?.student?.lastName },
                        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1, valueGetter: (params) => params.row?.student?.phoneNumber },
                        { field: 'course', headerName: 'Course', flex: 1, valueGetter: (params) => params.row?.course?.name },
                        { field: 'request', headerName: 'Approval Request', flex: 1, renderCell: (params) => {
                            if(params.row?.type === 'categoryChange'){
                                return (<div>
                                    Change category from {params.row?.category?.name} 
                                    <br />
                                    to {params.row?.newCategory?.name}
                                </div>);
                            }
                            else if(params.row?.type === 'categoryDrop') {
                                return (<div>Drop category {params.row?.category?.name}</div>);
                            }
                            else if(params.row?.type === 'admissionDrop') {
                                return (<div>Drop course {params.row?.course?.name}</div>);
                            }
                            else if(params.row?.type === 'batchShift') {
                                return (<div>
                                    Shift batch from {params.row?.currentBatch?.name}
                                    <br />
                                    to {params.row?.targetBatch?.name}
                                </div>);
                            }
                        }},
                        { field: 'status', headerName: 'Status', flex: 1, },
                        {
                            field: 'actions',
                            headerName: 'Actions',
                            flex: 1,
                            renderCell: (params) => (
                                <div>
                                    {(permissions.includes('approveApproval') && params.row?.status === 'Pending') && <Button variant="outlined" onClick={() => { setApproveModal(true); setSelectdApprovalId(params?.row?._id); setRowType(params?.row?.type);}} >Approve</Button>}
                                    {(permissions.includes('rejectApproval') && params.row?.status === 'Pending') && <Button variant="outlined" sx={{ marginLeft: '10px' }} color='error' onClick={() => { setRejectModal(true); setSelectdApprovalId(params.row?._id); }} >Reject</Button>}
                                </div>
                            ),
                        },
                        ]}
                        pagination
                        paginationModel={paginationModel}
                        rowCount={totalResults}
                        paginationMode="server"
                        components={{ Toolbar: GridToolbar }} // Add GridToolbar for filtering
                        onPaginationModelChange={setPaginationModel}
                        rowsPerPageOptions={pageOption}
                    />
                </div>
            )}

            <Modal
                open={approveModal}
                onClose={() => setApproveModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <p>Do you really want to approve this?</p>
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => setApproveModal(false)}>Cancel</Button>
                            <Button variant="contained" onClick={handleApprovalSubmit}>Approve</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={additionalChargesModal}
                onClose={() => setAdditionalChargesModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <p style={{ color: 'red' }}>Note: Any additions charges then enter here other wise leave as blank</p>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    label="Amount"
                                    margin="normal"
                                    value={additionsCharge}
                                    onChange={handleAdditionsCharge}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => {setAdditionalChargesModal(false); setRowType('');}}>Cancel</Button>
                            <Button variant="contained" onClick={saveApprovalData}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={rejectModal}
                onClose={() => setRejectModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div>
                        <p>Do you really want to reject this?</p>
                        <div style={{ textAlign: 'center' }}>
                            <Button variant="outlined" sx={{ marginRight: '10px' }} onClick={() => { setRejectModal(false); setSelectdApprovalId(''); }}>Cancel</Button>
                            <Button variant="contained" onClick={handleRejectSubmit}>Reject</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </div>
    );
};

export default MyTable;