import React, { useEffect, useState } from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../context/userContext';
import { CircularProgress } from '@mui/material';

const ProtectedRoute = ({ element }) => {
  const isTokenExists = localStorage.getItem('token'); 
  const { permissions } = useUser();
  const location = useLocation();
  const basePath = location.pathname;
  const [isLoading, setIsLoading] = useState(true); // Step 1: Introduce loading state

  const basePathPermissionsMapping = {
    '/dashboard/faculty/list': ['readFaculty'],
    '/dashboard/students/list':['readStudent'],
    '/dashboard/mentor/list':['readMentor'],
    '/dashboard/coordinators/list':['readCoordinator'],
    '/dashboard/branch/list':['readBranch'],
    '/dashboard/class/list':['readClassroom'],
    '/dashboard/batch/list':['readBatch'],
    '/dashboard/course/list':['readCourse'],
    '/dashboard/category/list':['readCategory'],
    '/dashboard/subject/list':['readSubject'],
    '/dashboard/topic/list':['readTopic'],
    '/dashboard/schedule/list':['readSchedule'],
    '/dashboard/roles/list':['readRole'],
    '/dashboard/users/list':['readUser']

  };

  const requiredPermissions = basePathPermissionsMapping[basePath] || [];
  const hasRequiredPermissions = permissions && requiredPermissions.every(permission => permissions.includes(permission));

  useEffect(() => {
    const loadPermissions = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));

      if(hasRequiredPermissions === null) {
        setIsLoading(true);
      }
      else {
        setIsLoading(false);
      }
    };

    loadPermissions();
  }, [hasRequiredPermissions]);

  if (isLoading) {
    return <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', height: '100vh', width: '100%' }}>
      <CircularProgress style={{ margin: 'auto' }} />
    </div>
  }
  
  if (isTokenExists && !hasRequiredPermissions) {
    return <Navigate to="/dashboard" />;
  }

  if (isTokenExists && hasRequiredPermissions) {
    return element;
  }

  return <Navigate to="/admin" />;
};

export default ProtectedRoute;
