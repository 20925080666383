import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, FormGroup, Grid, Modal, TextField, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { memo, useEffect, useState } from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import apiService from "../../../utlis/apiService";
import { useUser } from "../../../context/userContext";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    width: '60vw',
    maxHeight: '90vh', 
    overflowY: 'scroll',
};


const ManageExam = memo((props) => {
    const { exams, students, batchReload, batchId, viewMode, subject } = props;

    const [markModalOpen, setMarkModalOpen] = useState(false);
    const [mainModalOpen, setMainModalOpen] = useState(false);
    const [selectedExam, setSelectedExam] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [studentMark, setStudentMark] = useState([]);
    const [mainStudentMark, setMainStudentMark] = useState([]);
    const [mainExamList, setMainExamList] = useState([]);
    const [mainExam, setMainExam] = useState([]);
    const [mockExam, setMockExam] = useState([]);
    const [selectedMainExam, setSelectedMainExam] = useState({});

    const { permissions } = useUser();
    
    const handleAddStudentToMainList = (value) => {
        if(value) {
            if(Array.isArray(mainStudentMark)) {
                if (mainStudentMark.some(student => student?.student?._id === value?._id)) {
                    alert('student already exists!');
                    return;
                }

                setMainStudentMark([...mainStudentMark, value]);
            } 
            else {
                alert('Something went wrong');
            }
        }
    }

    const enterExamMark = (id) => {
        setMarkModalOpen(true);
        setSelectedExam(id);

        const examList = exams.find(exam => exam._id === id);

        if(examList.studentMark.length){
            setStudentMark(examList.studentMark);
        } else {
            setStudentMark(students);
        }
    }

    const enterMainExamMark = (id) => {
        setMainModalOpen(true);
        setSelectedExam(id);

        const examList = exams.find(exam => exam._id === id);

        if(examList.studentMark.length){
            const updatedList = [];

            examList.studentMark.forEach(markStudent => {
                
                if(students.some(student => student?._id === markStudent?.student?._id)) {
                    updatedList.push(markStudent);
                }
            });
            setMainStudentMark(updatedList);
        }
        else {
            setMainStudentMark([]);
        }
    }

    const handleAttendedChange = (e, id) => {
        setStudentMark(prevExams => {
            return prevExams.map(marks => {
                if (marks._id === id) {
                    return { ...marks, attended: e.target.checked };
                }
                return marks;
            });
        });
    }

    const handleMarkChange = (e, id) => {
        if(isNaN(e.target.value)){
            alert('Enter a valid mark');
            return;
        }

        setStudentMark(prevExams => {
            return prevExams.map(marks => {
                if (marks._id === id) {
                    return { ...marks, mark: e.target.value, remark: '' };
                }
                return marks;
            });
        });
    }

    const handleRemarkChange = (e, id) => {
        setStudentMark(prevExams => {
            return prevExams.map(marks => {
                if (marks._id === id) {
                    return { ...marks, remark: e.target.value, mark: '' };
                }
                return marks;
            });
        });
    }

    const handleMainMarkChange = (e, id) => {
        setMainStudentMark(prevExams => {
            return prevExams.map(marks => {
                if (marks._id === id) {
                    return { ...marks, mark: e.target.value };
                }
                return marks;
            });
        });
    }

    const saveMark = async () => {
        let isExistAbsentRemark = true;
        const filteredMark = studentMark.map(data => {
            if(!data.attended && !data.remark) {
                isExistAbsentRemark = false;
            }
            return {
                student: data.student ? data.student._id : data._id,
                attended: data.attended,
                mark: data.mark,
                remark: data.remark,
            }
        });
        
        if(isExistAbsentRemark) {
            try {
                const apiUrl = '/batch/student/exam-mark-update';

                const formData = {
                    examId: selectedExam, 
                    examMark: filteredMark,
                }

                const response = await apiService.post(apiUrl, formData);

                if(response?.data?.status === 'success'){
                    batchReload();
                    setSelectedExam('');
                    setStudentMark([]);
                    setMarkModalOpen(false);
                    alert(response?.data?.message);
                    
                }
            }
            catch (error) {
                alert(error?.response?.data?.error);
                console.error(error);
            }
        }
        else {
            alert('Please enter remark for all absent students');
        }
    }

    const saveMainMark = async () => {
        const filteredMark = mainStudentMark.map(data => ({
            student: data.student ? data.student._id : data._id,
            attended: true,
            mark: data.mark,
        }));
        
        try {
            const apiUrl = '/batch/student/exam-mark-update';

            const formData = {
                examId: selectedExam, 
                examMark: filteredMark,
            }

            const response = await apiService.post(apiUrl, formData);

            if(response?.data?.status === 'success'){
                batchReload();
                setMainStudentMark([]);
                setSelectedExam('');
                setMainModalOpen(false);
                alert(response?.data?.message);
            }
        }
        catch (error) {
            alert(error?.response?.data?.error);
            console.error(error);
        }
    }

    const getMainExams = async () => {
        try {
            const response = await apiService.get(`/exam/main/list`, {
                params: {
                    subject: subject,
                }
            });

            setMainExamList(response?.data);
        } catch (error) {
            console.error(error);
        }
    }

    const handleExamOptionChange = (value) => {
        setSelectedMainExam(value);
    }

    const addMainExamToBatch = async () => {
        if(selectedMainExam){
            if (Array.isArray(mainExam)) {
                if (mainExam.some(exam => exam?._id === selectedMainExam?._id)) {
                    alert('Exam already exists!');
                    return;
                }
            }
            try {
                const apiUrl = '/batch/exam-add';

                const formData = {
                    examId: selectedMainExam._id, 
                    batchId: batchId,
                }

                const response = await apiService.post(apiUrl, formData);

                if(response?.data?.status === 'success'){
                    batchReload();
                    alert(response?.data?.message);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const filterMainMockExams = () => {
        // Filter exams based on examType 'Main'
        const mainExams = exams.filter(exam => exam.examType === 'Main');

        // Filter exams based on examType 'Mock'
        const mockExams = exams.filter(exam => exam.examType === 'Mock');

        setMainExam(mainExams);
        setMockExam(mockExams);
    }

    const deleteMainExam = async (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this exam?');
        if (shouldDelete) {
            try {
                const apiUrl = '/batch/exam-delete';

                const formData = {
                    examId: id, 
                    batchId: batchId,
                }

                const response = await apiService.post(apiUrl, formData);

                if(response?.data?.status === 'success'){
                    batchReload();
                    alert(response?.data?.message);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        if(subject) {
            getMainExams();
        }
    }, [subject]);

    useEffect(() => {
        filterMainMockExams();
    }, [exams]);

    return(
        <>
            <Accordion defaultExpanded ={false} sx={{ marginTop: 4 }}>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography variant="h6">Exams</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ height: '50vh', width: '100%' }}>
                        <Typography sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>Mock Exams</Typography>
                        <DataGridPro
                            rows={mockExam}
                            getRowId={(row) => row._id}
                            columns={[
                                { field: 'name', headerName: 'Name', width: 300 },
                                { field: 'examType', headerName: 'Type', width: 150 },
                                { field: 'totalMark', headerName: 'Total Mark', width: 150 },
                                { field: 'passMark', headerName: 'Pass Mark', width: 150 },
                                {
                                    field: 'actions',
                                    headerName: 'Action',
                                    width: 150,
                                    renderCell: (params) => (
                                        <>
                                            {permissions.includes('enterExamMark') && !viewMode && (
                                                <Button variant="outlined" onClick={() => enterExamMark(params?.row?._id)}>Enter Mark</Button>
                                            )}
                                        </>
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div style={{ marginTop: '4rem', marginBottom: '1rem' }}>
                        <Typography sx={{ fontWeight: 'bold', marginBottom: '15px' }}>Main Exams</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Autocomplete
                                    id="mainExam-autocomplete"
                                    options={mainExamList}
                                    getOptionLabel={(option) => option.name}
                                    loading={loading}
                                    onChange={(e, value) => handleExamOptionChange(value)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Search main exam" size="small" />
                                    )}
                                    size="small"
                                    sx={{ marginBottom: '15px' }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button type="button" variant="contained" onClick={addMainExamToBatch}>Add</Button>
                            </Grid>
                        </Grid>
                        <div style={{ height: '50vh', width: '100%' }}>
                            <DataGridPro
                                rows={mainExam}
                                getRowId={(row) => row._id}
                                columns={[
                                    { field: 'name', headerName: 'Name', width: 300 },
                                    { field: 'examType', headerName: 'Type', width: 150 },
                                    { field: 'totalMark', headerName: 'Total Mark', width: 150 },
                                    { field: 'passMark', headerName: 'Pass Mark', width: 150 },
                                    {
                                        field: 'actions',
                                        headerName: 'Action',
                                        width: 300,
                                        renderCell: (params) => (
                                            <>
                                                {permissions.includes('enterExamMark') && !viewMode && (
                                                    <Button variant="outlined" onClick={() => enterMainExamMark(params?.row?._id)}>Enter Mark</Button>
                                                )}
                                                {permissions.includes('enterExamMark') && !viewMode && (
                                                    <Button variant="outlined" color="error" onClick={() => deleteMainExam(params?.row?._id)} sx={{ marginLeft: '10px'}}>Delete</Button>
                                                )}
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            
            {!viewMode ? 
                <>
                    <Modal
                        open={markModalOpen}
                        onClose={() => setMarkModalOpen(false)}
                        aria-labelledby="modal-title"
                    >
                        <Box sx={{ ...style }}>
                            <h2 id="modal-title">Mock Exam Mark</h2>
                            <div style={{ marginBottom: '1rem', }}>
                                {/* <Autocomplete
                                    id="students-autocomplete"
                                    options={students}
                                    getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' / '+ option.phoneNumber}
                                    loading={loading}
                                    onInputChange={handleSearchOptionChange}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Search students" />
                                    )}
                                    sx={{ marginTop: '15px' }}
                                /> */}
                                <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }}>
                                    <Grid item xs={4}>
                                        <Typography>Name</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Attended</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Mark / Remark</Typography>
                                    </Grid>
                                </Grid>
                                {studentMark && studentMark.map((student, index) => (
                                    <Grid container spacing={2} alignItems="center" key={index}>
                                        <Grid item xs={4}>
                                            <Typography>
                                                {student.student ? student.student.firstName : student.firstName} {student.student ? student.student.lastName : student.lastName} / {student.student ? student.student.phoneNumber : student.phoneNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup sx={{ width: 'fit-content'}}>
                                                {/* <FormControlLabel control={<Checkbox checked={formData.initialRegistration} onChange={(e) => handleinitialRegistration(e)} />} label="Initial Registration Done" /> */}
                                                <Checkbox checked={student.attended} onChange={(e) => handleAttendedChange(e, student.student ? student.student._id : student._id)} />
                                            </FormGroup>
                                        </Grid> 
                                        <Grid item xs={4}>
                                            {student.attended ?
                                                <TextField
                                                    label="Mark"
                                                    name="mark"
                                                    value={student.mark}
                                                    onChange={(e) => handleMarkChange(e, student._id)}
                                                    fullWidth
                                                    type='text'
                                                    // error={Boolean(errors.registrationNumber)}
                                                    // helperText={errors.registrationNumber}
                                                    margin="normal"
                                                    size="small"
                                                />
                                            :
                                                <TextField
                                                    label="Remark"
                                                    name="remark"
                                                    value={student.remark}
                                                    onChange={(e) => handleRemarkChange(e, student._id)}
                                                    fullWidth
                                                    type='text'
                                                    // error={Boolean(errors.registrationNumber)}
                                                    // helperText={errors.registrationNumber}
                                                    margin="normal"
                                                    size="small"
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                            <Button onClick={() => setMarkModalOpen(false)} variant="outlined">Close</Button>
                            <Button onClick={saveMark} variant="contained" sx={{ marginLeft: 1 }}>Save</Button>
                        </Box>
                    </Modal>

                    <Modal
                        open={mainModalOpen}
                        onClose={() => setMainModalOpen(false)}
                        aria-labelledby="modal-title"
                    >
                        <Box sx={{ ...style, width: '60vw' }}>
                            <h2 id="modal-title">Main Exam Mark</h2>
                            <div style={{ marginBottom: '1rem', }}>
                                <Autocomplete
                                    id="students-autocomplete"
                                    options={students}
                                    getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' / '+ option.phoneNumber}
                                    loading={loading}
                                    onChange={(e, value) => handleAddStudentToMainList(value)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Search students" size="small" />
                                    )}
                                    size="small"
                                    sx={{ marginTop: '15px' }}
                                />
                                <Grid container spacing={2} alignItems="center" sx={{ marginTop: '1rem' }}>
                                    <Grid item xs={6}>
                                        <Typography>Name</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Mark</Typography>
                                    </Grid>
                                </Grid>
                                {mainStudentMark && mainStudentMark?.map((student, index) => (
                                    <Grid container spacing={2} alignItems="center" key={index}>
                                        <Grid item xs={6}>
                                            <Typography>
                                                {student.student ? student.student.firstName : student.firstName} {student.student ? student.student.lastName : student.lastName} / {student.student ? student.student.phoneNumber : student.phoneNumber}
                                            </Typography>
                                        </Grid>
                                        
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Mark"
                                                name="mark"
                                                value={student.mark}
                                                onChange={(e) => handleMainMarkChange(e, student._id)}
                                                fullWidth
                                                type='text'
                                                size="small"
                                                // error={Boolean(errors.registrationNumber)}
                                                // helperText={errors.registrationNumber}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>

                            <Button onClick={() => setMainModalOpen(false)} variant="outlined">Close</Button>
                            <Button onClick={saveMainMark} variant="contained" sx={{ marginLeft: 1 }}>Save</Button>
                        </Box>
                    </Modal>
                </>
            :''}
        </>
    );
});

export default ManageExam;