// MaterialManagement.js

import React, { useState,useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import config from '../../../config';
import apiService from '../../../utlis/apiService';

const MaterialManagement = React.memo(({ materials = [], setMaterials, viewMode }) => {
 
  const addMaterialRow = () => {
    setMaterials((prevMaterials) => [...prevMaterials, { name: '', file: null }]);
  };

  const removeMaterialRow = (index) => {
    console.log(materials)
    setMaterials((prevMaterials) => prevMaterials.filter((_, i) => i !== index));
  };

  const handleMaterialChange = (index, field, value) => {
    setMaterials((prevMaterials) =>
      prevMaterials.map((material, i) =>
        i === index ? { ...material, [field]: value } : material
      )
    );
  }


  const handleFileChange = async (index, file) => {
    console.log('index', index);
  
    try {
      const formData = new FormData();
      formData.append('file', file);
  
      const response = await apiService.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        console.log(response?.data?.fileUrl);
  
        setMaterials((prevMaterials) =>
          prevMaterials.map((material, i) =>
            i === index ? { ...material, file: response?.data?.fileUrl } : material
          )
        );
      } else {
        console.error('Failed to upload file on the server.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" gutterBottom>
          Material Management [{materials?.length}]
        </Typography>

        {!viewMode ? 
          <Typography gutterBottom style={{ fontSize: 13, color: '#1876d2', cursor: 'pointer' }} onClick={addMaterialRow}>
            + Add material
          </Typography>
        : ''}
      </div>

      <TableContainer component={Paper} style={{ marginBottom: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Material Name</TableCell>
              <TableCell>File</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
        {materials.map((material, index) => (
          <TableRow key={index}>
            <TableCell>
              <TextField
                value={material.name}
                onChange={(e) => handleMaterialChange(index, 'name', e.target.value)}
                inputProps={{
                  style: { padding: 5 },
                }}
              />
            </TableCell>
  
            <TableCell>
              {/* Use a unique key for each FileUploadButton instance */}
              <FileUploadButton
                key={`file-upload-${index}`}
                onFileChange={(file,mindex) => handleFileChange(mindex, file)}
                selectedFile={material.file}
                mindex={index}
              />
            </TableCell>
            <TableCell>
              {!viewMode ?
                <Button onClick={() => removeMaterialRow(index)} variant="outlined">
                  Remove
                </Button>
              : ''}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

function isObject(variable) {
    return typeof variable === 'object' && variable !== null;
  }
  

  const FileUploadButton = React.memo(({ onFileChange, selectedFile, mindex }) => {
    
   

    const handleFileChange = useCallback((e) => {
      const newFile = e.target.files[0];
      onFileChange(newFile, mindex);
    }, [onFileChange, mindex]);

    
  
    return (
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <Input
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={`file-input-${mindex}`}
        />
  
        <label htmlFor={`file-input-${mindex}`}>
          <Button
            component="span"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
          >
            Select
          </Button>
        </label>
  
        {selectedFile && (
        <Button
          variant="outlined"
          onClick={() => {
            window.open(`${config.apiUrl}/${selectedFile}`, '_blank');
          }}
          style={{ marginLeft: 10 }}
        >
          View
        </Button>
      )}
      </div>
    );
  });
export default MaterialManagement;
