import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
  Grid,
  AppBar,
  IconButton,
  Toolbar,
} from '@mui/material';
import { GetApp as DownloadIcon } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const Materials = () => {
  const navigate = useNavigate();
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [enrolledBatches, setEnrolledBatches] = useState([]);

  const handleToggleMaterial = (material) => {
    const currentIndex = selectedMaterials.indexOf(material);
    const newSelectedMaterials = [...selectedMaterials];

    if (currentIndex === -1) {
      newSelectedMaterials.push(material);
    } else {
      newSelectedMaterials.splice(currentIndex, 1);
    }

    setSelectedMaterials(newSelectedMaterials);
  };

  const handleDownload = () => {
    selectedMaterials.forEach((material) => {
      window.open(`${config.apiUrl}/${material.file}`, '_blank');
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    // Replace the API URL with your actual API endpoint
    const userId = localStorage.getItem('user_id');
    axios.get(`${config.apiUrl}/user/materials?id=${userId}`)
      .then(response => {
        setEnrolledBatches(response.data.batches);
      })
      .catch(error => {
        console.error('Error fetching enrolled batches:', error);
      });
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <Container maxWidth={'100%'} sx={{ paddingLeft: 0 }} style={{ padding: 0 }}>
      <AppBar position="static" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Materials
          </Typography>
        </Toolbar>
      </AppBar>

      <List style={{ marginLeft: 30 }}>
        {enrolledBatches.map((batch) => (
          <ListItem key={batch._id} disablePadding>
            <ListItemText primaryTypographyProps={{ style: {fontWeight:'bold'} }} primary={batch.name}/>
            <List>
              
              {batch.materials?.length === 0 ? <ListItem disablePadding sx={{marginRight:2}}>No Materials</ListItem> : ''}
              {batch.materials.map((material) => (
                <ListItem key={material._id} disablePadding sx={{marginRight:2}}>
                  <Checkbox
                    edge="start"
                    checked={selectedMaterials.some((selected) => selected._id === material._id)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': material.name }}
                    onChange={() => handleToggleMaterial(material)}
                  />
                  <ListItemText primary={material.name} />
                </ListItem>
              ))}
            </List>
          </ListItem>
        ))}
      </List>

      <Grid container justifyContent="center" spacing={2} sx={{ marginTop: 2 }}>
        <Grid item>
          {selectedMaterials.length ? 
            <Button variant="contained" color="primary" onClick={handleDownload} startIcon={<DownloadIcon />}>
              Download Selected
            </Button>
          : ''}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Materials;
