import * as React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import apiService from '../../../utlis/apiService';



export default function ExportDefaultToolbar() {

    const [data,setData] = React.useState([])

    React.useEffect(()=>{
        getData()
    },[])

    const getData = async () => {
        try {
          const response = await apiService.get('/report/academic');
          console.log(response?.data?.academics)
        //   setData(response?.data?.academics)
        } catch (error) {
          console.error(error);
        }
      };

  return (
    <div style={{ height: 300, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
            BATCH TRANSFER REPORT
        </Typography>
       <DataGridPro
        getRowId={(row) => row._id}
        rows={data}
        disableDensitySelector
        columns={[
            { field: '_id', headerName: 'ID', width: 90 },
            { 
                field: 'branch', 
                headerName: 'Branch', 
                width: 150, 
                valueGetter: (params) => params.row?.branch?.name
            },
            { 
                field: 'batch', 
                headerName: 'Batch', 
                width: 150, 
                valueGetter: (params) => params.row?.course?.name
            },
            { 
                field: 'course', 
                headerName: 'Course', 
                width: 150, 
                valueGetter: (params) => params.row?.course?.name
            },
            { 
                field: 'papers', 
                headerName: 'Papers', 
                width: 150, 
                valueGetter: (params) => params?.row?.course?.papers?.map(paper => paper?.name).join(', ') 
            },
        ]}
        components={{ Toolbar: GridToolbar }}
        />

    </div>
  );
}
