import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import apiService from '../../../utlis/apiService';

const PageSize = 50;

const MyAutocomplete = React.memo(({ selectedStudents = [], setSelectedStudents, paper, mode, batchId, viewMode}) => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [label, setLabel] = useState('Add Students');
  const [selectedValue, setSelectedValue] = useState(null);

  const fetchStudents = async (pageNumber, query) => {
    setLoading(true);
    try {
      const response = await apiService.get(`/student/listbypaper`, {
        params: {
          searchTerm: query,
          // page: pageNumber,
          // pageSize: PageSize,
          paper: paper,
          mode: mode,
        },
      });
      setStudents(response?.data?.students ?? []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const addStudentInBatch = async (student) => {
    try {
      const formData = {
        studentId: student, batchId: batchId
      }

      const response = await apiService.post(`/batch/student-add`, formData);
      // setSelectedStudents(response.data);
      return true;
    } catch (error) {
      alert(error?.response?.data);
      console.error(error);
      return false;
    }
  }

  const removeStudentFromBatch = async (student) => {
    try {
      const formData = {
        studentId: student, batchId: batchId
      }

      const response = await apiService.post(`/batch/student-remove`, formData);
      // setSelectedStudents(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeStudent = async (newValue) => {
    if(newValue){
      const uniqueArray = selectedStudents.length && selectedStudents?.filter((item) => item._id === newValue._id);
      if(uniqueArray?.length){
        alert('The student is already selected!');
      }
      else {
        const feeDue = findFeeDue(newValue);
        if(feeDue) {
          alert('The student who has been selected has an outstanding fee!');
        }
        const studentAdd = await addStudentInBatch(newValue._id);
        const studentData = [...selectedStudents, newValue];
        if(studentAdd) {
          setSelectedStudents(studentData);
        }
      }
    }

    setSelectedValue(null);
  };

  const handleSearchChange = (event, newQuery) => {
    setSearchQuery(newQuery);
    setPage(1);
    setStudents([]);
  };

  const handleSearchOptionChange = (event, newQuery) => {
    setSearchQuery1(newQuery);
    setPage(1);
    setStudents([]);
  }

  useEffect(() => {
    if(!viewMode) {
      fetchStudents(page, searchQuery1);
    }
  }, [page, searchQuery1, paper, mode]); // Include paper in the dependency array

  useEffect(() => {
    if (selectedStudents?.length > 0) {
      setLabel(`Selected ${selectedStudents.length} Students`);
    } else {
      setLabel('Add Students');
    }
  }, [selectedStudents]);

  // Update filteredStudents when selectedStudents or searchQuery changes
  useEffect(() => {
    const filteredRows = selectedStudents.length ? selectedStudents?.filter((row) => {
        const name = row.firstName + ' ' + row.lastName;
        return name.toLowerCase().includes(searchQuery.toLowerCase()) || row?.phoneNumber.includes(searchQuery);
      }
    ) : [];
    setFilteredStudents(filteredRows);
  }, [selectedStudents, searchQuery]);

  const removeItem = (studentId) => {
    const data = selectedStudents.filter((student) => student._id !== studentId);
    setSelectedStudents(data);
    removeStudentFromBatch(studentId);
  };

  const findFeeDue = (student) => {
    const admissions = student?.admission;

    const today = new Date();
    const overdueFees = admissions?.filter(admission => {
      const category = admission?.category?.filter(category => {
        let dueExist = false;
        if(category?.ots?.amount && new Date(category?.ots?.dueDate) <= today && category?.ots?.received === false){
          dueExist = true;
        }
        if(category?.feeSplit?.length){
          const splitOverDue = category?.feeSplit?.filter(fee => !fee.received && new Date(fee.dueDate) <= today);
          if(splitOverDue?.length) {
            dueExist = true;
          }
        }
        return dueExist;
      });
      return category.length > 0;
    });
    return overdueFees.length > 0;
  }

  const getDueDate = (student) => {
    const admissions = student?.admission;
    const today = new Date();

    let earliestDate = null;

    admissions?.forEach(admission => {
        admission?.category?.forEach(category => {
            // Check OTS (One Time settlement) fees
            if (category?.ots?.amount && new Date(category?.ots?.dueDate) < today && !category?.ots?.received) {
                const dueDate = new Date(category?.ots?.dueDate);
                
                if (!earliestDate || dueDate < earliestDate) {
                    earliestDate = dueDate;
                }
            }
            
            // Check Fee Split
            if (category?.feeSplit?.length) {
                category?.feeSplit.forEach(fee => {
                    if (!fee.received && new Date(fee.dueDate) < today) {
                        const dueDate = new Date(fee.dueDate);
                        if (!earliestDate || dueDate < earliestDate) {
                            earliestDate = dueDate;
                        }
                    }
                });
            }
        });
    });

    if (earliestDate) {
      return new Date(earliestDate).toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
    } else {
        return 'No Fee Due';
    }
  }

  return (
    <div>
      {!viewMode ?
        <Autocomplete
          id="students-autocomplete"
          value={selectedValue}
          options={students}
          getOptionLabel={(option) => option.firstName + ' ' + option.lastName + ' / '+ option.phoneNumber}
          onChange={(event, newValue) => handleChangeStudent(newValue)}
          loading={loading}
          onInputChange={handleSearchOptionChange}
          renderInput={(params) => (
            <TextField {...params} label={label} />
          )}
          sx={{ marginTop: '15px' }}
        />
        :''}
      <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
        Enrolled students
      </Typography>
      <TextField
        fullWidth
        label="Search Students"
        id="fullWidth"
        value={searchQuery}
        onChange={(e) => handleSearchChange(null, e.target.value)}
        style={{ marginBottom: 20 }}
      />
      <TableContainer component={Paper} style={{ maxHeight: 400 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Parent Number</TableCell>
              <TableCell>Fee Due On</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.length ? filteredStudents?.map((student) => (
              <TableRow key={student._id} style={{ cursor: 'pointer' }} >
                <TableCell >{student.firstName} {student.lastName}</TableCell>
                <TableCell>{student.phoneNumber}</TableCell>
                <TableCell>{student.parentNumber}</TableCell>
                <TableCell>{getDueDate(student)}</TableCell>
                <TableCell>
                    {!viewMode ?
                      <Button variant="outlined" onClick={()=>removeItem(student._id)}>
                          Remove
                      </Button>
                    : ''}
                </TableCell>
              </TableRow>
            )) : ''}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

export default MyAutocomplete;
